import React, {FC, useContext, useEffect, useState} from "react";
import '../../../scss/components/_dailyreminder.scss';
import AppContext from "../../../common/context/app-context";
import {Popup} from "../../../common/components/AuthModal";
import {setIsSubscribed} from "../../../api/apiDailyReminder";
import {meApi} from "../../../api/apiAuth";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const DailyReminderEmails: FC = () => {
  const {setShowAuthPopup, me} = useContext(AppContext);
  const [newMe, setNewMe] = useState(me);

  const onSubscribe = async () => {
    if (me) {
      await setIsSubscribed(me.id);
      const updatedMe = await meApi();
      setNewMe(updatedMe);
    } else {
      setShowAuthPopup({authStep: Popup.Login});
    }
  }
    useEffect(() => {

            useTrackGoogleAnalyticsEventPages('/daily-reminders', 'daily reminders page')

    }, []);

  useEffect(() => {
    setNewMe(me);
  }, [me, setNewMe]);

  return (
    <div className="container xs-container--sm daily-reminder">
      <div className="flex topics-media topics-media--sm">
        <img
          className="img img--full img--contain"
          src="/images/dog.png"
          alt=""
        />
      </div>
      <h4 className="fs-h-3 lh-h-3 fw-700 c-text-black xs-fs-h-6 xs-lh-h-6">
        Daily Reminders
      </h4>
      <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4 daily-reminder-text">
        Subscribe to Flipped on Dogs reminders. A daily email so you stay dogsmart and never miss a new game.
      </p>
      <div className="flex align-start justify-start w-100 mb-5">
        <button
          className="btn btn--sm sm-btn--sm btn--primary xs-btn--sm mt-auto "
          onClick={onSubscribe}
        >
          {me == null ?
            "subscribe" :
            newMe?.isSubscribed ? "unsubscribe" : "subscribe"
          }
        </button>
      </div>
    </div>
  )
}

export default DailyReminderEmails;
