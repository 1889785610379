import React, {FC, ReactNode} from 'react';

interface UserAvatarProps {
  firstName?: string,
  lastName?: string,
  imageUrl?: string,
  email: string
}

const UserAvatar: FC<UserAvatarProps> = (
  {
    firstName,
    lastName,
    imageUrl,
    email
  }
) => {
  const renderContent = (): ReactNode => {
    if (imageUrl) {
      return (
        <div className="flex justify-center align-center avatar-media br-50 mt-auto">
          <img
            className="img img--full img--square img--contain br-50"
            src={imageUrl}
            alt="user image"
          />
        </div>
      );
    } else if (firstName || lastName) {
      const first: string = firstName ? firstName.trim().charAt(0).toUpperCase() : '';
      const last: string = lastName ? lastName.trim().charAt(0).toUpperCase() : '';
      return (
        <div className="flex justify-center align-center avatar-media br-50 mt-auto no-image">
          <div className="br-50 user-name-abbr">{first}{last}</div>
        </div>
      );
    } else if (email){
      return (
        <div className="flex justify-center align-center avatar-media br-50 mt-auto no-image">
          <div className="br-50 user-name-abbr">{email.trim().charAt(0).toUpperCase()}</div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center align-center avatar-media br-50 mt-auto no-image">
          <div className="br-50 user-name-abbr">U</div>
        </div>
      )
    }
  };

  return (
    <>
      {renderContent()}
    </>

  );
};

export default UserAvatar;
