import React, {useCallback, useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Link, Typography} from "@mui/material";
import FTBButton from "../../common/components/forms/FTBButton";
import {submitRestPwRequest} from "../../api/apiService";
import ControlledPasswordField from "../../common/components/forms/ControlledPasswordField";
import {useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import AppContext from "../../common/context/app-context";
import PageContent from "../../common/components/PageContent";
import Paper from "@mui/material/Paper";
import {logException} from "../../utils/helperUtils";
import {useTrackGoogleAnalyticsEventPages} from "../../common/hooks/useTrackGoogleAnalyticsEvent";

interface Props {
}

type FormValues = {
    password: string;
};

const ResetPasswordPage: React.FC<Props> = () => {
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const [showFailureMessage, setShowFailureMessage] = useState<boolean>(false);
    const {setLoginModal} = useContext(AppContext);

    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const token = query.get("token");

    const {
        control,
        trigger,
        getValues,
        formState: {isValid},
    } = useForm<FormValues>({
        mode: "onChange",
        defaultValues: {
            password: "",
        },
    });
    useEffect(() => {
        useTrackGoogleAnalyticsEventPages('/reset-password','reset password page')

    }, []);
    const onSubmit = useCallback(async () => {
        setShowFailureMessage(false);
        if (!isValid) {
            await trigger();
            return;
        }

        const formValues = getValues();

        // @ts-ignore
        const result = await submitRestPwRequest(token, formValues.password).catch(
            logException,
        );
        if (result?.reset_status === "OK") {
            setPasswordChanged(true);
        } else {
            setShowFailureMessage(true);
        }
    }, [getValues, isValid, token, trigger]);

    if (!token) {
        return <Box>Invalid Token</Box>;
    }


    return (
        <PageContent headingText={"Reset Password"} maxContentWidth={500}>
            <Paper sx={{padding: 1, width: "100%"}}>
                <Typography variant="body1" mb={2}>
                    Enter your new password
                </Typography>

                <ControlledPasswordField
                    name={"password"}
                    label={"Password"}
                    control={control}
                    rules={{required: true}}
                />
                <FTBButton text={"Submit"} onClick={onSubmit}/>

                {passwordChanged && (
                    <Box my={4}>
                        <Typography variant="body1">
                            You password was changed successfully. Click{" "}
                            <Link
                                sx={{cursor: "pointer"}}
                                onClick={() =>
                                    setLoginModal({
                                        isOpen: true,
                                        onSuccess: () => {
                                            window.location.href = "/";
                                        },
                                    })
                                }
                            >
                                here
                            </Link>{" "}
                            to login
                        </Typography>
                    </Box>
                )}

                {showFailureMessage && (
                    <Box my={4}>
                        <Typography variant="body1" color="error">
                            There was an error resetting your password
                        </Typography>
                    </Box>
                )}
            </Paper>
        </PageContent>
    );
};

export default ResetPasswordPage;
