import {getRequest, postRequest, putRequest, toQueryString} from "./request";
import { ClientMe } from "../common/types/user";

export const GUEST_TOKEN_KEY = "guest-authorization";
export const GUEST_ID_API = "guestIdApi";
export const signupApi = async ({
  payload,
}: {
  payload: any;
}): Promise<any> => {
  return await postRequest<any>(`auth/register`, payload);
};

export const loginApi = async ({ payload }: { payload: any }): Promise<any> => {
  return await postRequest<any>(`auth/login`, payload);
};

export const meApi = async (): Promise<ClientMe> => {
  return await getRequest<ClientMe>("me").then((res) => res.data);
};

export const guestLoginApi = async (): Promise<any> => {
  return await postRequest<any>("auth/guest/login", {}).then((res) => res.data);
}

export const getProvider = async (email: string): Promise<any> => {
  return await postRequest<any>("users/provider", {email}).then((res) => res.data);
}

export const guestUserTransferApi = async (firstName?: string, lastName?: string): Promise<any> => {
  return await postRequest<any>("users/transfer", {firstName, lastName}, {baseURL: process.env.REACT_APP_SERVER_API?.replace("client", "guest")})
      .then((res) => res.data)
      .catch((err) => console.error(err));
}

export const signedUrlApi = async (params: any): Promise<string> => {
  const {contentType, fileName, config} = params;
  return await getRequest<string>(`users/signed-url?${toQueryString({ contentType, fileName })
  }`, undefined, config).then(res => res.data);
}

export const updateUserImageApi = async ({payload, userId}: {payload: any, userId: any}): Promise<any> => {
  return await putRequest<any>(`users/${userId}`, payload).then(res => res.data);
}

