import React, {useContext, useEffect} from 'react';
import Game from '../story-center/game/Game';
import useQueryParams from '../../common/hooks/useQueryParams';
import {usePupStarCardsMutation} from '../../common/hooks/useAlbums';
import {UserPupStarCards} from '../../common/types/albums';
import AppContext from '../../common/context/app-context';
import {getAlbums} from '../../api/apiGame';
import {useTrackGoogleAnalyticsEventPages} from "../../common/hooks/useTrackGoogleAnalyticsEvent";

export const ScrapBook = () => {
  const param = useQueryParams();
  const {albums: albumsResponse, setAlbums} = useContext(AppContext);
  const {setSeenPopStarCardsMutation} = usePupStarCardsMutation();
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/scrap-book', 'scrap book page')

  }, []);
  useEffect(() => {
    if (!albumsResponse?.userPupStarCards?.length) return;
    const userPupStarCards = albumsResponse.userPupStarCards.filter((c) => !c.seen);
    if (!userPupStarCards.length) return;
    const items = new Map<number, UserPupStarCards>();
    userPupStarCards.forEach((a) => items.set(a.pupStarCardId, a));
    const cards = Array.from(items, ([_, value]) => value);
    setSeenPopStarCardsMutation(cards, {
      onSuccess: async () => {
        await  new Promise((resolve) => setTimeout(resolve, 2000));
        const albums = await getAlbums();
        setAlbums(albums);
      }
    });
  }, []);

  return (
    <Game url={`?${param.tab === 'personal-picks' ? 'openPersonalPick' : 'openScrapBook'}`}/>
  );
};
