import React, {useEffect} from 'react';
import {useTrackGoogleAnalyticsEventPages} from "../common/hooks/useTrackGoogleAnalyticsEvent";

interface Props {}

const PrivacyPolicy: React.FC<Props> = () => {
  useEffect(() => {
    useTrackGoogleAnalyticsEventPages('/privacy-policy','privacy policy page')

  }, []);
  return (
    <>
      <div className="container xs-container--sm">
        <div className="flex flex-col w-100 mt-6 sm-mt-5 xs-mt-4 xs-px-6 sm-px-5 xs-mb-7 sm-mb-7 align-start fs-lg lh-lg sm-fs-md sm-lh-md xs-fs-sm xs-lh-sm fw-500 c-text-4">
          <h2 className="fs-h-3 lh-h-3 c-text-2 sm-fs-h-5 sm-lh-h-5 xs-fs-h-6 xs-lh-h-6 fw-700 mb-6 sm-mb-5 xs-mb-4">
            Talk Dog, Inc.™ Privacy Policy
          </h2>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Last Updated: December 30, 2022
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            Thank you for accessing the Talk Dog, Inc.™ websites and online and
            mobile platforms (the “Venue”), as made available on the internet
            and/or the Apps (as defined below). The Venue and Apps are provided
            by Talk Dog, Inc. (collectively, “Talk Dog™,” “we,” “our” or “us”).
            This Talk Dog, Inc.™ Privacy Policy (“Privacy Policy”) covers our
            treatment of personal information and other information that we
            collect when end-users (“users,” “you” or “your”): (a) access or use
            the Venue; (b) download, attempt to download and/or use Talk Dog™
            mobile applications (the “Apps”) and associated Software (as defined
            below), as made available on the iPhone®, iPad® and iPod Touch®,
            as well as Android® and other mobile devices (collectively, “Mobile
            Devices”), by and through the Apple® App Store, the Google Play®
            store, and other applicable venues (collectively, “Download
            Venues”); (c) access and/or download any of the: (i) academic
            material, text, audio, video, photographs, graphics, artwork and/or
            other content featured by and through the Venue (collectively,
            “Informational Content”); and/or (ii) links to third party websites,
            products and/or services featured by and through the Venue
            (“Third-Party Links,” and together with the Informational Content,
            the “Content”); and/or (d) utilize the comment functionality and
            other interactive features of the Venue (collectively, “Interactive
            Services,” and together with the Apps, Venue and Content, the “Talk
            Dog™ Offerings”). Please note, if you are a resident of a European
            Union Member State, you are not permitted to use the Venue and/or
            Talk Dog™ Offering
          </p>
          <span className=" mb-6 sm-mb-6 xs-mb-4">
            Capitalized terms not defined herein shall have the meanings set
            forth in the Talk Dog, Inc.™ Mobile Application End-User License
            Agreement and Terms and Conditions (“Terms”).
            <span className="fw-700 uppercase">
              If you do not agree to terms of this privacy policy in their
              entirety, you may not access or otherwise use the talk dog™
              offerings.
            </span>
          </span>
          <i className="mb-6 sm-mb-6 xs-mb-4">
            Apple®, iTunes®, iPhone®, iPad® and iPod Touch® are registered
            trademarks of Apple, Inc. (“Apple”). Android®, Google® and Google
            Play® are registered trademarks of Google, Inc. (“Google”). Please
            be advised that Talk Dog™ is not in any way affiliated with Apple,
            or Google, nor are the Talk Dog™ Offerings endorsed, administered
            or sponsored by any of the aforementioned entities.
          </i>
          <span className=" fw-700 mb-6 sm-mb-6 xs-mb-4">
            Your California Privacy Rights.
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4 fw-700">
            If you are a resident of the State of California and would like to
            learn how your “personal information” (as defined in the Shine the
            Light Law, Cal. Civ. Code § 1798.83) is shared with third parties,
            what categories of personal information we have shared with third
            parties in the preceding year, as well as the names and addresses of
            those third parties, please e-mail us at: tech@talkdoginc.com; call
            us at: 973-925-4377; or send us U.S. Mail to: Attn: Privacy Policy,
            Talk Dog, Inc., 24 Newark Pompton Tpke., Suite 206, Little Falls, NJ
            07424.
            <br />
            Further, if you are a resident of the State of California and would
            like to opt-out from the disclosure of your personal information to
            any third-party for marketing purposes, please e-mail us at:
            tech@talkdoginc.com; call us at: 973-925-4377; or send us U.S. Mail
            to: Attn: Privacy Policy, Talk Dog, Inc., 24 Newark Pompton Tpke.,
            Suite 206, Little Falls, NJ 07424.
          </p>
          <span className=" fw-700 mb-6 sm-mb-6 xs-mb-4">
            Your Nevada Privacy Rights.
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4 fw-700">
            If you are a resident of the State of Nevada and would like to
            opt-out from the sale of your personal information to any third
            party data broker, please e-mail us at: tech@talkdoginc.com; call us
            at: 973-925-4377; or send us U.S. Mail to: Attn: Privacy Policy,
            Talk Dog, Inc., 24 Newark Pompton Tpke., Suite 206, Little Falls, NJ
            07424.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Personal Information Collected
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            For purposes of this Privacy Policy, "personal information" shall
            mean individually identifiable information from or about an
            individual. We collect personal information when you access certain
            of the Talk Dog™ Offerings and complete the required information
            request Form(s) and/or otherwise provide such information to us. The
            information that you must supply on the Form(s) may include, without
            limitation: (a) your email address; (b) your first and last name;
            (c) user account information; and/or (d) any other information
            requested by us on the applicable registration Form.
          </p>{" "}
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Use and Sharing of Personal Information
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            By submitting your personal information by and through the Talk
            Dog™ Offerings, you agree that we may share, sell, rent, lease or
            otherwise provide that personal information to any third party for
            any purpose permitted by law, and we may work with other businesses
            to bring selected retail opportunities to our users. These
            businesses and third parties may include, but are not limited to:
            (a) providers of direct marketing services and applications,
            including lookup and reference, data enhancement, suppression and
            validation; (b) e-mail marketers; (c) telemarketers (where permitted
            by applicable law); (d) direct marketers; and (e) Canine Trainers
            Academy, LLC (alternate name: CATCH Canine Trainers Academy). We
            will also use your personal information to send you promotional
            messages regarding various Talk Dog™ products and/or services, as
            well as third party products and/or services that we think may be of
            interest to you. <br /> We may also employ third parties and
            individuals to perform certain functions on our behalf, including
            helping us operate the Talk Dog™ Offerings and/or to administer
            related activities on our behalf. Examples include sending direct
            and electronic mail, removing duplicate information from user lists,
            analyzing data and providing marketing analysis. The agents
            performing these limited functions on our behalf shall have access
            to our users’ personal information as needed to perform their
            functions for us, but we do not permit them to use user personal
            information for other purposes. We will also use your personal
            information for customer service, to provide you with information
            that you may request, to customize your experience with the Apps, on
            the Venue and/or to contact you when necessary in connection with
            transactions entered into by you through the Talk Dog™ Offerings.{" "}
            <br />
            We may also use your personal information for internal business
            purposes, such as analyzing and managing our business. We may also
            combine the information we have gathered about you with information
            from other sources.
            <br />
            By registering via the Apps and/or Venue, you agree that such act
            constitutes an inquiry and/or application for purposes of the
            Amended Telemarketing Sales Rule (16 CFR §310 et seq.), as amended
            from time to time (the “Rule”) and applicable state do-not-call
            regulations. As such, notwithstanding that your telephone number may
            be listed on the Federal Trade Commission’s Do-Not-Call List, and/or
            on applicable state do-not-call lists, we retain the right to
            contact you via telemarketing in accordance with the Rule and
            applicable state do-not-call regulations.
            <br />
            Where you provide "prior express consent" within the meaning of the
            Telephone Consumer Protection Act (47 USC § 227), and its
            implementing regulations adopted by the Federal Communications
            Commission (47 CFR § 64.1200), as amended from time-to-time
            ("TCPA"), you consent to receive telephone calls from Talk Dog™,
            including artificial voice calls, pre-recorded messages and/or calls
            (including SMS text messages) delivered via automated technology, to
            the telephone number(s) that you provided. Please note that you are
            not required to provide this consent in order to obtain access to
            the Talk Dog™ Offerings, and your consent simply allows Talk Dog™
            to contact you via these means.{" "}
            <span className="fw-700">
              Please be advised that by agreeing to this Privacy Policy, you are
              obligated to immediately inform us if and when the telephone
              number that you have previously provided to us changes. Without
              limiting the foregoing, if you: (a) have your telephone number
              reassigned to another person or entity; (b) give up your telephone
              number so that it is no longer used by you; (c) port your
              telephone number to a landline or vice versa; or (d) otherwise
              stop using that telephone number for any reason (collectively
              "Phone Number Change"), you agree that you shall promptly notify
              Talk Dog™ of the Phone Number Change via e-Mail at:
              tech@talkdoginc.com, or by using one of the methods set forth in
              the “Contact Us” section below.
            </span>
            <br />
            We also reserve the right to release current or past users’
            information in the event that we believe that the user is using, or
            has used, the Talk Dog™ Offerings: (i) in violation of the Terms,
            this Privacy Policy or any other of our operating rules, policies,
            price schedules and other supplemental terms and conditions or
            documents that may be published by us from time to time; (ii) to
            commit unlawful acts; (iii) if such information is subpoenaed;
            provided, however, that, where permitted by applicable law, we shall
            provide you with e-mail notice, and opportunity to challenge the
            subpoena, prior to disclosure of any personal information pursuant
            to a subpoena; (iv) if we are sold, merge with a third-party or are
            acquired by a third-party (collectively, "M&A Transactions")
            (including where we share your personal information in connection
            with the due diligence process associated with a potential M&A
            Transaction); (iv) if we are the subject of bankruptcy proceedings;
            provided, however, that if Talk Dog™ is involved in a bankruptcy
            proceeding and/or M&A Transaction, you will be notified via e-mail
            and/or a prominent notice in the Apps of any change in ownership or
            uses of your personal information, as well as any choices that you
            may have regarding your personal information; and/or (v) when we are
            transferring information to a company we have a partnership with,
            such as Canine Trainers Academy, LLC (alternate name: CATCH Canine
            Trainers Academy); and/or (vi) when we deem it necessary or
            appropriate including, without limitation, sharing your e-mail
            address with other third parties for suppression purposes in
            compliance with the CAN-SPAM Act of 2003, as amended from time to
            time, and Federal Trade Commission implementing regulations. Without
            limiting the generality of the foregoing, we reserve the right to
            share, sell, rent, lease and/or otherwise distribute any and all
            personal information with/to any third party for any and all uses
            permitted by this Privacy Policy and applicable law.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Non-Personal Information Collection and Use
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            For purposes of this Privacy Policy, "personal information" shall
            mean individually identifiable information from or about an
            individual. We collect personal information when you access certain
            of the Talk Dog™ Offerings and complete the required information
            request Form(s) and/or otherwise provide such information to us. The
            information that you must supply on the Form(s) may include, without
            limitation: (a) your email address; (b) your first and last name;
            (c) user account information; and/or (d) any other information
            requested by us on the applicable registration Form.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            <i className="underline">Computer IP Addresses/Browser Type</i>
            <br />
            We may collect certain non-personally identifiable information about
            you and your desktop computer when you visit many of the pages of
            the Venue. This non-personally identifiable information includes,
            without limitation, the type of browser that you use (e.g., Safari,
            Chrome, Internet Explorer), your IP address, the type of operating
            system that you use (e.g., Windows or iOS) and the domain name of
            your Internet service provider (e.g., Verizon, AT&T). We use the
            non-personally identifiable information that we collect to improve
            the design and content of the Talk Dog™ Offerings and to enable us
            to personalize your Internet experience. We also may use this
            information in the aggregate to analyze usage of the Talk Dog™
            Offerings.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            <i className="underline">
              Mobile Device and Apps Usage Information
            </i>
            <br />
            We may collect certain non-personally identifiable information about
            you and your Mobile Device when you access the Apps and/or visit
            certain Apps pages. This non-personally identifiable information
            includes, without limitation, the following information pertaining
            to your Mobile Device and your use of the Apps: (a) Mobile Device
            platform type; (b) Mobile Device identifier; (c) SDK version; (d)
            API key (identifier for the Apps); (e) Apps version; (f) the model
            type, manufacture and version of the Mobile Device; (g) your Apps
            session start/stop time and date; and (h) the actions taken while
            utilizing the Apps. We use the non-personally identifiable
            information identified in this paragraph to improve the design and
            content of the Talk Dog™ Offerings and to enable us to personalize
            your Apps experience. We also may use this information in the
            aggregate to analyze Talk Dog™ Offerings usage, alter existing Talk
            Dog™ Offerings and/or develop new or different Talk Dog™
            Offerings.{" "}
            <span className="fw-700">
              You understand and agree that we may send push notifications about
              the Talk Dog™ Offerings to you by and through your Mobile Device.
            </span>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            <i className="underline">Geolocation Tracking</i>
            <br />
            Talk Dog™ does not use geolocation technology to track App users’
            locations.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            <i className="underline">Cookies</i>
            <br />
            When a user visits the Venue, we send one (1) or more cookies and/or
            gif files (collectively,{" "}
            <span className="underline">"Cookies"</span>) to assign an
            anonymous, unique identifier to the applicable user’s computer
            and/or mobile device, as applicable. A Cookie is a piece of data
            stored on your hard drive containing non-personally identifiable
            information about you. Cookies have many benefits to enhance your
            experience on the Venue, as applicable. To find out more about
            Cookies, please visit www.cookiecentral.com. We use Cookies to
            improve the quality of the Talk Dog™ Offerings, including for
            storing user preferences and tracking Venue usage (such as pages
            opened and length of stay at the Venue, as applicable).
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            Most Internet browsers are initially set up to accept Cookies, but
            you can reset your browser to refuse all Cookies or to indicate when
            a Cookie is being sent. To disable and reject certain Cookies,
            follow the instructions associated with your Internet browser. To
            manage Flash Cookies, please click{" "}
            <a href="#" className="c-primary underline">
              here.
            </a>{" "}
            Even in the case where a user rejects a Cookie, he or she may still
            use the Talk Dog™ Offerings; provided, however, that certain
            functions of the Talk Dog™ Offerings may be impaired or rendered
            inoperable if the use of Cookies is disabled. We reserve the right
            to retain Cookie data indefinitely.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            <i className="underline">Behavioral Tracking</i>
            <br />
            Talk Dog™ and its third-party partners use Cookies, pixels and
            other tracking technology (collectively, "Tracking Technology") to
            analyze trends, administer the Venue, track users’ movements around
            the Venue and to gather demographic information about our user base
            as a whole. In addition, we partner with third-parties to use
            Tracking Technology in connection with the display of advertising on
            the Venue and to manage the serving of our advertising on other
            websites. Our third-party partners may use Tracking Technology to
            gather information about your activities on the Venue and other
            websites in order to provide you with advertising based upon your
            browsing activities and interests.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            If you wish to opt-out of having this information used for the
            purpose of serving you interest-based ads, you may opt-out by
            emailing your request to: tech@talkdoginc.com. In addition, users
            may be able to disable some, but not all, of this tracking activity
            by utilizing the “Do Not Track” setting or similar options within
            most major Internet browsers. Further, users may be able to opt-out
            of this form of tracking utilizing the options made available by the
            <span className="underline">
              Network Advertising Initiative
            </span> or{" "}
            <span className="underline">Digital Advertising Alliance.</span>
            Please note that opting out of this tracking activity does not opt
            you out of being served advertising. Even where you opt out of this
            tracking activity, you will continue to receive generic ads.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            <i className="underline">Cross Device Tracking</i>
            <br />
            Talk Dog™ tracks users’ use of the Talk Dog™ Offerings across
            various devices, including your personal computer and Mobile Device,
            in order to optimize and personalize your Talk Dog™ Offerings
            experience. Talk Dog™ may collect certain of your personal
            information across various devices. If you would like to opt-out of
            having your use of the Talk Dog™ Offerings tracked across multiple
            devices, please e-mail us at: tech@talkdoginc.com. Please be advised
            that where you opt-out of having your use of the Talk Dog™
            Offerings tracked across devices, you may need to upload certain
            information multiple times and/or input your log-in information
            multiple times.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            <i className="underline">Aggregate Data</i>
            <br />
            Talk Dog™ reserves the right to transfer and/or sell aggregate or
            group data about users of the Talk Dog™ Offerings for lawful
            purposes. Aggregate or group data is data that describes the
            demographics, usage and other characteristics of users as a group,
            without disclosing personally identifiable information.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Interactive Services
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            If you engage in any interaction with Talk Dog™, other users or any
            third-party via the Interactive Services, you should be aware that:
            (a) the personal information that you submit by and through such
            Interactive Services can be read, collected and/or used by other
            users of the Apps and/or Venue, and could be used to send you
            unsolicited messages or otherwise to contact you without your
            consent or desire; and (b) where Talk Dog™ responds to any
            interaction in the Apps and/or on the Venue, your account
            name/handle may be viewable by any and all members/users of the Apps
            and/or Venue, as applicable. We are not responsible for the personal
            information that you choose to submit via Interactive Services
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Third-Party Websites
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            The Apps and/or Venue may contain links to third-party owned and/or
            operated websites including, without limitation, Third-Party Links.
            Talk Dog™ is not responsible for the privacy practices or the
            content of such websites. In some cases, you may be able to make a
            purchase through one of these third-party websites. In these
            instances, you may be required to provide certain information, such
            as a credit card number, to register or complete a transaction at
            such website. These third-party websites have separate privacy and
            data collection practices and Talk Dog™ has no responsibility or
            liability relating to them.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Security of Your Personal Information
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            We endeavor to safeguard and protect our users’ personal
            information. When users make personal information available to us,
            their personal information is protected both online and offline (to
            the extent that we maintain any personal information offline). Where
            our registration/application process prompts users to enter
            sensitive information, and when we store and transmit such sensitive
            information, that information is encrypted with advanced TLS
            (Transport Layer Security).
            <br /> Access to your personal information is strictly limited, and
            we take reasonable security measures to ensure that your personal
            information is not accessible to the public. All of our users’
            personal information is restricted in our offices, as well as the
            offices of our third-party service providers. Only employees or
            third-party agents who need user personal information to perform a
            specific job are granted access to user personal information. Our
            employees are dedicated to ensuring the security and privacy of all
            user personal information. Employees not adhering to our firm
            policies are subject to disciplinary action. The servers that we
            store user personal information on are kept in a secure physical
            environment. We also have security measures in place to protect the
            loss, misuse and alteration of personal information under our
            control.
            <br /> Please be advised, however, that while we take every
            reasonable precaution available to protect your data, no storage
            facility, technology, software, security protocols or data
            transmission over the Internet or via wireless networks can be
            guaranteed to be 100% secure. Computer hackers that circumvent our
            security measures may gain access to certain portions of your
            personal information, and technological bugs, errors and glitches
            may cause inadvertent disclosures of your personal information;
            provided, however, that any attempt to breach the security of the
            network, our servers, databases or other hardware or software may
            constitute a crime punishable by law. For the reasons mentioned
            above, we cannot warrant that your personal information will be
            absolutely secure. Any transmission of data by and/or through the
            Apps and/or Venue, other Talk Dog™ Offerings or otherwise via the
            Internet or wireless networks, is done at your own risk. <br /> In
            compliance with applicable federal and state laws, we shall notify
            you and any applicable regulatory agencies in the event that we
            learn of an information security breach with respect to your
            personal information. You will be notified via e-mail in the event
            of such a breach. Please be advised that notice may be delayed in
            order to address the needs of law enforcement, determine the scope
            of network damage, and to engage in remedial measures.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Children's Privacy
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            The Apps and Venue are not intended for use by individuals under
            eighteen (18) years of age. No one under age eighteen (18) years of
            age may provide any information to, on and/or through the Apps
            and/or Venue. We do not knowingly collect personal information from
            individuals under eighteen (18) years of age. If we learn that we
            have collected or received personal information from an individual
            under eighteen (18) years of age without verification of parental
            consent, we will delete that information. If you believe we might
            have any information from or about an individual under eighteen (18)
            years of age, please contact us at the email address listed at the
            bottom of this Privacy Policy.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Opt-Out/Unsubscribe
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-6">
            To opt-out of receiving e-mail and other forms of communication from
            us, you can: (a) follow the instructions included in the applicable
            e-mail message or other communication; or (b) e-mail us at:
            tech@talkdoginc.com. <br />
          </p>
          <span className="mb-6 sm-mb-6 xs-mb-4">
            Notwithstanding the foregoing, we may continue to contact you for
            the purpose of communicating information relating to your use of
            Talk Dog™ Offerings, as well as to respond to any inquiry or
            request made by you. To opt-out of receiving Talk Dog™
            Offerings-related and/or inquiry response-related messages from Talk
            Dog™, you must cease requesting and/or utilizing the Talk Dog™
            Offerings and/or cease submitting inquiries to Talk Dog™, as
            applicable.
          </span>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-6">
            Deleting, Modifying and Updating Your Information
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            At your request, we will: (a) inform you of what personal
            information we have on file for you; (b) amend the personal
            information that we have on file for you; and/or (c) remove personal
            information that you have provided to us, or that we have otherwise
            collected. You may do so by e-mailing us at: tech@talkdoginc.com. We
            ask individual users to identify themselves and the information
            requested to be accessed, corrected or removed before processing
            such requests, and, to the extent permitted by applicable law, we
            may decline to process requests that are unreasonably repetitive or
            systematic, require disproportionate technical effort, jeopardize
            the privacy of others or would be extremely impractical (for
            instance, requests concerning information residing on backup tapes).
          </p>
          <span className="mb-6 sm-mb-6 xs-mb-6">
            Please be advised that deleting your personal information may
            terminate your access to certain of the Talk Dog™ Offerings. If you
            wish to continue using the full complement of Talk Dog™ Offerings,
            you may not be able to delete all of the personal information that
            we have on file for you.
          </span>
          <span className="mb-6 sm-mb-6 xs-mb-6">
            Please be further advised that, after you delete your personal
            information, residual copies may take a period of time before they
            are deleted from our active servers and may remain in our backup
            systems.
          </span>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-6">
            Transfer of Personal Information Internationally
          </span>
          <p className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            If you are accessing the Apps and/or visiting the Venue from a
            country other than the country in which our servers are located,
            your communications with us may result in the transfer of
            information across international boundaries. By accessing the Apps,
            visiting the Venue and/or otherwise communicating electronically
            with us, as applicable, you consent to such transfers. Even if your
            jurisdiction does not have the same privacy laws as the jurisdiction
            where our servers are located, we will treat your information as
            subject to the protections described in this Privacy Policy.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Changes to this Privacy Policy
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            Talk Dog™ reserves the right to change or update this Privacy
            Policy at any time by posting a notice in the Apps and/or on the
            Venue that we are changing our Privacy Policy or by changing the
            “Last Updated” date at the top of this Privacy Policy. If the manner
            in which we use personal information changes, Talk Dog™ will notify
            users by: (a) sending the modified Privacy Policy to our users via
            e-mail; and/or (b) any other reasonable means acceptable under
            applicable state and federal law. You will have a choice as to
            whether or not we use your information in this different manner, and
            we will only use your information in this different manner where you
            opt-in to such use.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">Contact Us</span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            If you have any questions about this Privacy Policy or our privacy
            practices in general, you may: e-mail us at: tech@talkdoginc.com;
            call us at: 973-925-4377; or send us U.S. Mail to: Attn: Privacy
            Policy, Talk Dog, Inc., 24 Newark Pompton Tpke., Suite 206, Little
            Falls, NJ 07424.
          </p>
          <span className="fw-700 mb-6 sm-mb-6 xs-mb-4">
            Filing a Complaint with the Federal Trade Commission
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            To file a complaint regarding our privacy practices, please{" "}
            <a href="#" className="c-primary underline">
              Click Here.
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
