import api from './api';
import {getRequest} from './request';

export const getAllBreeds = async (): Promise<DogBreed[]> => {
  const breeds = await getRequest<any>(`breeds`).then((res) => res.data);
  return breeds ?? [];
};

export const submitRestPwRequest = async (
  token: string,
  password: string,
): Promise<ForgotPasswordResponse | undefined> => {
  const result = await api.post<ForgotPasswordResponse>(
    "/auth/reset-password",
    {
      body: {
        token,
        password,
      },
    },
  );
  if (!result.response.ok) {
    return undefined;
  }

  return result.data as ForgotPasswordResponse;
};

export const getTokens = async (
  refresh_token: string,
): Promise<IRefreshTokenResponse | undefined> => {
  const result = await api.post<IRefreshTokenResponse>("/auth/refresh", {
    body: { refresh_token },
  });
  if (!result.response.ok) {
    return undefined;
  }

  return result.data as IRefreshTokenResponse;
};
