import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTopics} from '../../../common/hooks/useTopics';
import {SubTopic, Topic} from '../../../common/types/topics';
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

interface TopicsProps {
}

const Topics: React.FC<TopicsProps> = () => {
  const {data: topics} = useTopics();

  useEffect(() => {
    useTrackGoogleAnalyticsEventPages('/topics', 'topics page');
  }, []);

  return (
    <div className="container xs-container--sm">
      <div className="flex flex-row justify-center text-center align-center mb-12 xs-flex-col-reverse xs-mb-6">
        <h2 className="text-uppercase fs-h-2 lh-h-2 sm-fs-h-4 sm-lh-h-4 xs-fs-h-3 xs-lh-h-4 fw-700 c-text-black">
          topics
        </h2>
        <div className="flex topics-media topics-media--sm">
          <img
            className="img img--full img--contain"
            src="/images/dog.png"
            alt=""
          />
        </div>
      </div>
      <div className="topics-wrapper w-100 col-gap-20 sm-col-gap-15 row-gap-6 xs-px-6 xs-row-gap-6 sm-px-6 mb-12">
        {topics?.map((topic: Topic, i: number) => (
          <div
            className="flex flex-col row-gap-6 xs-row-gap-4 childes "
            key={topic.id}
          >
            <div
              className={`flex justify-center text-center py-4 px-4 sm-py-3 sm-px-3 xs-px-2 xs-py-2 bg-topics-${(i % 6) + 1}`}
            >
                <span className="fs-h-7 lh-7 sm-fs-h-8 sm-lh-md xs-fs-xl xs-lh-lg fw-700 c-text-black uppercase">
                  {topic?.name}
                </span>
            </div>
            <div
              className="flex flex-wrap justify-start row-gap-2 px-6 sm-px-3 xs-col-gap-0 xs-row-gap-0 xs-flex-col xs-text-center xs-align-center">
              {topic?.subTopics?.map((sub: SubTopic) => (
                <Link
                  to={`/topics/${sub.slug}`}
                  key={sub.id}
                  className="fs-xl lh-lg xs-fs-sm xs-lh-sm sm-fs-md sm-lh-md fw-500 c-text-1 px-3 py-3 sm-px-2 sm-py-2 xs-px-2 xs-py-2 grow-0 shrink-0 w-50"
                >
                  {sub.name}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Topics;
