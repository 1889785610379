export enum ErrorCode {
  EMAIL_EXISTS = "auth/email-already-in-use",
  INVALID_EMAIL = "auth/invalid-email",
  WEAK_PASSWORD = "auth/weak-password",
  WRONG_PASSWORD = "auth/wrong-password",
}

export const getErrorMessageByCode = (code: ErrorCode, provider: string = ""): string => {
  switch (code) {
    case ErrorCode.EMAIL_EXISTS:
      return `An account with that email is already registered. Please try to log in ${provider &&  `via ${provider}`} or recover your password by resetting it.`;
    case ErrorCode.INVALID_EMAIL:
      return "Invalid email format";
    case ErrorCode.WEAK_PASSWORD:
      return "Password does not meet security requirements";
    case ErrorCode.WRONG_PASSWORD:
      return "Wrong password";
    default:
      return "Something went wrong";
  }
};
