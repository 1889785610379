import {useMutation, useQuery} from 'react-query';
import {getUserDraftedSubmission, userSubmissionCreateUpdateApi,} from '../../api/apiUserSubmission';

export const useUserDraftedSubmission = (userId: string | number | null | undefined, config: any) => {
   return useQuery({
    queryKey: ['useSubmissionDraft', userId],
    queryFn: () => getUserDraftedSubmission(userId, config),
    enabled: !!userId
  });
};

export const useCreateUpdateUserSubmission = () => {
  const {
    mutate: submissionCreateUpdate,
    isLoading: submissionCreateUpdateLoading,
  } = useMutation("userSubmissionCreateUpdate", userSubmissionCreateUpdateApi);

  return {
    submissionCreateUpdate,
    submissionCreateUpdateLoading,
  };
};
