import {useParams} from "react-router-dom";
import Game from "../game/Game";
import {FC, useEffect} from "react";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";


const QuickCourses: FC = () => {
  const param = useParams();
  useEffect(() => {
    if (param.slug){
      useTrackGoogleAnalyticsEventPages('/course'+param.slug, ' course page for'+param.slug)
    }

  }, []);
  return (
    <Game
      url={`?openCourseMode`}
      courseId={param.slug}
      isCourse={true}
    />
  )
}


export default QuickCourses;
