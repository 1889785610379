import {useMutation} from 'react-query';
import {setPupStarCardsSeen} from '../../api/apiGame';


export const usePupStarCardsMutation = () => {
  const {mutate: setSeenPopStarCardsMutation, isLoading} = useMutation(
    'albums',
    setPupStarCardsSeen,
  );

  return {
    setSeenPopStarCardsMutation,
    isLoading,
  };
};
