import React from 'react';
import AppContext from './common/context/app-context';
import useSetAppState from './common/hooks/useSetAppState';
import {RouterProvider} from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import useCreateBrowserRouter from './common/hooks/useCreateBrowserRouter';
import Modal from 'react-modal';
import {QueryClient, QueryClientProvider} from 'react-query';
import {initializeApp} from 'firebase/app';
import {firebaseConfig} from './firebase';
import {Toaster} from 'react-hot-toast';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';

initializeApp(firebaseConfig);
ReactGA4.initialize(`${process.env.REACT_APP_GOOGLE_GA}`);

try {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    debug: true,
    track_pageview: 'full-url',
    persistence: 'localStorage'
  });
} catch {
}

if(process.env.REACT_APP_ENVIRONMENT != "production"){
  document.title = `${process.env.REACT_APP_ENVIRONMENT} Flipped on Dogs - Daily Learning Game | Learn to Speak Dog with Quick, Fun Lessons from the Pros`;
}

moment().utcOffset(new Date().getTimezoneOffset());

const rootElement = document.querySelector("#root");

Modal.setAppElement(rootElement as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

function App() {
  const appState = useSetAppState();
  const router = useCreateBrowserRouter();
  return  (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={appState}>
        <RouterProvider router={router} />
        <Toaster/>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
