import {FC, useContext, useEffect} from 'react';
import {useSubTopics} from '../../../common/hooks/useTopics';
import {Link, useParams} from 'react-router-dom';
import {Article} from '../../../common/types/articles';
import AppContext from '../../../common/context/app-context';
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

interface SubTopics {
}

const SubTopics: FC<SubTopics> = () => {
  const param = useParams();
  const {setBreadcrumbTitle, setSubTopicsId} = useContext(AppContext);
  const {data: subTopics} = useSubTopics(
    param?.slug || '',
  );

  useEffect(() => {
    if (param.slug) {
      useTrackGoogleAnalyticsEventPages('/topics' + param.slug, 'topics page for' + param.slug)
    }
  }, []);

  useEffect(() => {
    if (subTopics) {
      setBreadcrumbTitle(subTopics.name);
      setSubTopicsId(subTopics.slug);
    }
    return () => {
      setBreadcrumbTitle('');
    };
  }, [subTopics]);


  return (
    <div
      className="container sm-container--sm flex flex-col w-100 justify-center align-center pt-6 xs-pt-0 xs-px-6 sm-px-6">
      <div className="flex flex-col w-100">
        <div className="flex flex-col justify-start align-start">
            <span className="fs-h-3 lh-h-3 sm-fs-h-6 sm-lh-h-6 fw-700 c-text-black">
              {subTopics?.name}
            </span>
          <span className="fs-lg lh-xl sm-fs-sm sm-lh-sm fw-400 c-text-4">
              {subTopics?.description}
          </span>
        </div>
        <div className="flex flex-col w-100 justify-center align-center pt-6 xs-pt-0">
          {subTopics?.articles.map((article: Article) => (
            <Link
              to={`/article/${article.slug}`}
              className="flex sm-flex-col xs-flex-col sm-flex-row justify-start w-100 border-b--primary py-6 xs-py-4"
              key={article.id}
            >
              <div className="flex topics-media--lg mr-12 sm-mr-8 xs-mr-0">
                <img
                  className="img img--fill img--cover img--cover-center"
                  src={article.thumbnailUrl}
                  alt=""
                />
              </div>
              <div className="flex flex-col align-start grow-1 topics-sidebar justify-start xs-mt-2 ">
                <div className="flex flex-col w-100 align-start pb-6 sm-pb-3 xs-mb-4 xs-pb-4">
                  <div className="flex flex-col w-100 justify-start align-start align-start">
                      <span className="fs-xl lh-xl sm-fs-md sm-lh-md fw-700 c-primary sm-mr-1">
                        {article?.subTopicSelections
                          ?.map((e) => e.subTopic.name)
                          .join(", ")}
                      </span>
                    <span className="fs-h-4 lh-h-4 sm-fs-xl sm-lh-h-7 fw-700 c-text-black mb-2 sm-mb-1 xs-mb-1">
                        {article.title}
                      </span>
                  </div>
                  <div className="flex flex-row w-100  mb-6 sm-mb-3">
                      <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-700 c-text-black ">
                        By{" "}
                        <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-700 c-primary">
                          {article?.articleAuthor?.displayName}&nbsp;
                        </span>
                      </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="flex justify-center align-center mt-15 mb-12 xs-mt-6  xs-w-100 xs-px-10">
          {/*    <button className="btn btn--primary xs-btn--lg xs-w-100">*/}
          {/*      more stories*/}
          {/*    </button>*/}
        </div>
      </div>
    </div>
  );
};

export default SubTopics;
