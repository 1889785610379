import {UserPersonalCard} from '../common/types/user';
import {deleteRequest, getRequest, postRequest, putRequest} from './request';

export const getUserPersonalCards = async (): Promise<UserPersonalCard[]> => {
    return await getRequest<UserPersonalCard[]>(`personal-pick-cards`).then(
        (res) => res.data,
    );
};

export const getUserPersonalCard = async (id: number): Promise<any> => {
    return await getRequest<UserPersonalCard>(`personal-pick-cards/${id}`).then(
        (res) => res.data,
    );
};

export const userPersonalCardCreateUpdateApi = async ({
                                                        payload,
                                                        type,
                                                        id,
                                                    }: {
    payload: any;
    type: "collect" | "create" | "update";
    id: number;
}): Promise<any> => {
    switch (type) {
        case "collect":
            return await postRequest<any>(`personal-pick-cards`, payload);
        case "create":
            return await postRequest<any>(`personal-pick-cards`, payload);
        case "update":
            return await putRequest<any>(`personal-pick-cards/${id}`, payload);
    }
};
export const deleteUserPersonalCard = async (id: number): Promise<UserPersonalCard> => {
    return await deleteRequest<UserPersonalCard>(`personal-pick-cards/${id}`).then(
        (res) => res.data,
    );
};

export const collectPersonalPick = async (id: number): Promise<any> => {
    return await postRequest<any>(`personal-pick-cards/${id}`, {}).then(
        (res) => res.data,
    );
};

export const collectDailyBonus = async (id: number): Promise<any> => {
    return await postRequest<any>(`pup-star-cards/${id}`, {}).then(
        (res) => res.data,
    );
};
