import React, {FC, SetStateAction, useContext, useEffect, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import UserAvatar from './header/UserAvatar';
import {useSidebarOutsideClick} from '../hooks/useSidebarOutsideClick';
import {ModalData, Popup} from './AuthModal';
import AppContext from '../context/app-context';
import {ClientMe} from '../types/user';
import {MixpanelEvent} from '../enums/mixpanel-event';
import {mixpanelTrack} from '../../utils/mixpanel-track';
import {useCourse, useCourseConfig} from "../hooks/useCourse";
import {QuickCoursesInterface} from "../types/sidebar";

interface SidebarProps {
  sidebarState: boolean;
  currentUser: ClientMe | null | undefined;
  setSidebarState: React.Dispatch<SetStateAction<any>>;
  showAuthPopup: ModalData | null;
  setShowAuthPopup: React.Dispatch<SetStateAction<any>>;
  isGameMode: boolean;
}

const Sidebar: FC<SidebarProps> = ({
  sidebarState,
  setSidebarState,
  setShowAuthPopup,
  currentUser,
  isGameMode,
}) => {
  const wrapperRef = useRef(null);
  const {data: courseData} = useCourse();
  const {data: courseConfigData} = useCourseConfig();

  useSidebarOutsideClick(wrapperRef);
  const closeSideBar = () => {
    setSidebarState(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (sidebarState) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [sidebarState]);

  async function handleLogout() {
    setShowAuthPopup(() => ({ authStep: Popup.Logout }));
    closeSideBar();
  }
  const scrapBookHandler = (e: any) => {
    e.preventDefault();
    if (currentUser) {
      mixpanelTrack(MixpanelEvent.scrapbook_viewed);
      navigate("/scrap-book");
    } else {
      setShowAuthPopup({ authStep: Popup.Login });
    }
    closeSideBar();
  };
  return (
    <section className={"sidebar " + (sidebarState ? "sidebar--open" : "")}>
      <div
        className="sidebar-in h-100 flex flex-col justify-start align-stretch"
        ref={wrapperRef}
      >
        <div className="sidebar-header sidebar-mb flex justify-between align-stretch">
          <div className="sidebar-logo grow-0 shrink-0">
            <img
              className="img img--contain"
              src="/images/sidebar-logo.png"
              alt=""
            />
          </div>
          <div className="flex justify-start align-stretch grow-0 shrink-0">
            {!!currentUser ? (
              <UserSidebar />
            ) : (
              !isGameMode && (<button
                        className="btn btn--sm sm-btn--sm btn--primary xs-btn--sm mt-auto"

                  onClick={() => {
                    closeSideBar();
                    setShowAuthPopup({ authStep: Popup.Login });
                  }}
                >
                  Log in
                </button>
              )
            )}

            <div className="flex flex-col justify-start align-end">
              <button
                className="sidebar-close-btn btn-reset c-text-1"
                onClick={closeSideBar}
              >
                <span className="flipicon flipicon-close"></span>
              </button>
            </div>
          </div>
        </div>
        {/*<div className="sidebar-search sidebar-mb">*/}
        {/*  <div className="input-wrap w-100 input-wrap--dark input-wrap--sm sm-input-wrap-sm xs-input-wrap--sm">*/}
        {/*    <button className="btn-reset input-wrap-btn input-wrap-btn--right c-primary">*/}
        {/*      <span className="flipicon flipicon-search"></span>*/}
        {/*    </button>*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="input input-hasicon--right w-100"*/}
        {/*      placeholder="Search"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="sidebar-menu sidebar-mb flex flex-col justify-start align-start">
          <Link
            to="/game"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start text-uppercase"
          >
            <span className="block sidebar-menu-item fw-700 c-primary">
              Play
            </span>
          </Link>
          <div className="flex flex-col justify-start align-start">
            <button
              onClick={scrapBookHandler}
              type="button"
              className="btn-reset sidebar-menu-item fw-700 c-primary  text-left text-uppercase"
            >
              Your
              <br />
              Scrapbook
            </button>
            {/*  {currentUser && (
              <ScrapBookProgress
                class1="progress-wrap progress-wrap--dark"
                class2="block fs-xs lh-1 fw-700 c-text-1 ml-2"
              ></ScrapBookProgress>
            )}*/}
          </div>
          <Link
            state={{ showAuthPopup: true }}
            to="/submit-dog-step-1"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start text-uppercase"
          >
            <span className="block sidebar-menu-item fw-700 c-primary">
              Submit my dog
            </span>
          </Link>
          <Link
            state={{ showAuthPopup: true }}
            to="/topics"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start text-uppercase"
          >
            <span className="block sidebar-menu-item text-left fw-700 c-primary">
              Explore topics
            </span>
          </Link>

          {!courseConfigData?.isCoursesDisabled &&
              <div>
                <div className="block sidebar-menu-item text-left fw-700 c-primary sidebar-mb--sm">
                  <span className="text-uppercase">Quick courses </span>
                  <span className="fw-500">(beta)</span>
                </div>
                <ul className="sidebar-quick-courses">
                  {courseData?.map((item: QuickCoursesInterface) =>
                    <Link
                      to={`/course/${item.slug}`}
                      onClick={closeSideBar}
                      key={item.id}
                    >
                      <li className="text-left fw-500 c-primary">{item.name}</li>
                    </Link>
                  )}
                </ul>
              </div>
          }

        </div>

        <div className="sidebar-submenu mt-auto sidebar-mb--sm flex flex-col justify-start align-start">
          <Link
            to="/daily-reminders"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start"
          >
            <span className="block sidebar-submenu-item fw-700 c-primary">
              Daily Reminder Emails
            </span>
          </Link>
          <Link
            to="/about-us"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start"
          >
            <span className="block sidebar-submenu-item fw-700 c-primary">
              About Us
            </span>
          </Link>
          <Link
            to="/community-guidelines"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start"
          >
            <span className="block sidebar-submenu-item fw-700 c-primary">
              Community Guidelines
            </span>
          </Link>
          <Link
            to="how-to-play"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start"
          >
            <span className="block sidebar-submenu-item fw-700 c-primary">
              How To Play
            </span>
          </Link>
          <Link
            to="/feedback"
            onClick={closeSideBar}
            className="btn-reset flex flex-col justify-start align-start"
          >
            <span className="block sidebar-submenu-item fw-700 c-primary">
              Give Feedback
            </span>
          </Link>
        </div>
        <div className="sidebar-footer flex flex-col justify-start align-start">
          {!!currentUser && (
            <button
              className="btn-reset flex flex-col justify-start align-start"
              onClick={handleLogout}
            >
              <span className="block sidebar-logout fw-700 c-text-1 text-uppercase">
                Log out
              </span>
            </button>
          )}

          <div className="flex justify-start align-center col-gap-4">
            <span className="block fs-md lh-md fw-700 c-text-1">
              Follow us:
            </span>
            <div className="flex justify-start align-center col-gap-4">
              <a className="btn-reset c-text-1"
                 href="https://www.tiktok.com/@flippedondogs"
                 target="_blank"
              >
                <span className="flipicon flipicon-tiktok"></span>
              </a>
              <a className="btn-reset c-text-1"
                 href="https://www.instagram.com/flippedondogs"
                 target="_blank"
              >
                <span className="flipicon flipicon-instagram"></span>
              </a>
              <a className="btn-reset c-text-1"
                 href="https://www.facebook.com/FlippedOnDogs"
                 target="_blank"
              >
                <span className="flipicon flipicon-facebook"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const UserSidebar = () => {
  const { me } = useContext(AppContext);

  return (
    <>
      {!!me && (
        <div className="sidebar-user flex flex-col justify-start align-start">
          <div className="flex flex-col justify-start align-center mr-6">
            <UserAvatar
              firstName={me.firstName}
              lastName={me.lastName}
              imageUrl={me.imageUrl}
              email={me.email}
            ></UserAvatar>

            <span className="fs-sm lh-sm fw-700 c-text-1 text-center">
              {me.firstName} {me.lastName}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
