import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const AboutUs = () => {
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/about-us', 'about us page')

  }, []);
  return (
    <div className="container xs-container--sm">
      <div className="flex flex-col w-100 justify-start align-start xs-px-6 sm-px-6 mb-7">
        <h2 className="fs-h-3 lh-h-3 fw-700 c-text-black xs-fs-h-6 xs-lh-h-6 mb-6 xs-mb-4">
          About Us
        </h2>
        <div className="flex flex-col w-100 justify-start align-start">
          <h3>Learn to Speak Dog</h3><br/>

          <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4">
            With quick, fun lessons designed by expert trainers for everyday dog owners, you’ll discover what your dog
            has been saying to you all along. Get in the know and connect with your dog on a deeper level. Your dog
            will know the difference and love you for it!
          </p>

          <ul className="flex flex-col list fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-6 pl-6">
            <li className="list-item"><b>Fun, effortless learning</b> - with fast lessons in our game format, we will
              make
              you dogsmart like
              never
              before. Earn points and Pup Star cards that showcase your knowledge level. Enjoy gaining skills that
              will enhance your communication and help you achieve your training goals.
            </li>
            <li className="list-item"><b>Crafted by experts with you in mind</b> - Flipped on Dogs is the sister company
              of
              the renowned
              CATCH Canine
              Trainers Academy, a national school that has been educating and certifying professional dog trainers for
              over a decade. We know our stuff and we’re excited to teach you! Everything you learn is trustworthy,
              effective, and dog-friendly.
            </li>
            <li className="list-item"><b>Lessons feature your dogs and your questions</b> - Ready to show off your dog
              and
              have them appear
              in the
              game? So are we! Have a question about your dog’s behaviors? Let’s hear it! You can easily Submit Your
              Dog and your questions to our team. We take the best photos and videos and feature them in our lessons
              so you can learn about dogs in the real world, and apply what you learn in everyday life.
            </li>
          </ul>
          <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm">
            You’ll flip when you see yourself getting smarter and happier just by spending a little time with us each
            day.{' '}
            <Link
              to="/game"
              state={{showAuthPopup: true}}
              className="fs-lg lh-xl fw-500 c-primary xs-fs-sm xs-lh-sm underline"
            >
              Play today’s game!
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
