import {useMutation} from 'react-query';
import {addCommentApi, likeCommentApi,} from '../../api/apiComments';

export const useSubmitComment = () => {
  const {mutate: submitComment, isLoading} = useMutation(
    'submitComment',
    addCommentApi,
  );

  return {
    submitComment,
    isLoading,
  };
};

export const useLikeComment = () => {
  const { mutate: likeComment, isLoading: likeLoading } = useMutation(
    "likeComment",
    likeCommentApi,
  );

  return {
    likeComment,
    likeLoading,
  };
};
