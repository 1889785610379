import React, {useEffect} from "react";
import ImageVideoUpload from "./image-video-upload";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const SubmitDogStepThree = () => {
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/submit-dog-step-3', 'submit dog step 3 page')

  }, []);
  return (
      <ImageVideoUpload type="Img" currentStep={3} />
  );
};

export default SubmitDogStepThree;
