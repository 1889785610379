import {AxiosResponse} from 'axios';
import axiosInstance from '../axiosInstance/axiosInstance';

export function getRequest<T>(
  url: string,
  headers?: Headers,
  config: any = {}
): Promise<AxiosResponse<T>> {
  if (headers) {
    config.headers = headers;
  }
  return axiosInstance.get<T>(`/${url}`, config);
}

export function postRequest<T>(
  url: string,
  payload: any,
  config: any = {}
): Promise<AxiosResponse<T>> {
  return axiosInstance.post<T>(`/${url}`, payload, config);
}

export function deleteRequest<T>(
    url: string,
    headers?: Headers,
    config: any = {}
): Promise<AxiosResponse<T>> {
  if (headers) {
    config.headers = headers;
  }
  return axiosInstance.delete<T>(`/${url}`, config);
}

export function putRequest<T>(
  url: string,
  payload: any,
  config: any = {}
): Promise<AxiosResponse<T>> {
  return axiosInstance.put<T>(`/${url}`, payload, config);
}

export function toQueryString(obj: { [key: string]: any }): string {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] != null) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
}
