import {useMutation} from 'react-query';
import {loginApi, signedUrlApi, signupApi, updateUserImageApi,} from '../../api/apiAuth';

export const useAuthMutation = () => {
  const {mutate: signup, isLoading: isLoadingSignup} = useMutation(signupApi);
  const {mutate: login, isLoading: isLoadingLogin} = useMutation(loginApi);

  return {
    signup,
    login,
    isLoadingMutation: isLoadingSignup || isLoadingLogin,
  };
};

export const useSignedUrl = () => {
  const { mutate: signedUrl, isLoading } = useMutation(
    "signedUrlApi",
    signedUrlApi,
  );

  return {
    signedUrl,
    isLoading,
  };
};

export const useUpdateUserImage = () => {
  const { mutate: updateUserImage, isLoading } =
    useMutation(updateUserImageApi);

  return {
    updateUserImage,
    isLoading,
  };
};
