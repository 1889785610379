import React, {FC, useState} from 'react';
import {useSignedUrl, useUpdateUserImage} from '../../hooks/useAuth';
import axios from 'axios';
import {ClientMe} from '../../types/user';
import useSetAppState from '../../hooks/useSetAppState';
import {meApi} from '../../../api/apiAuth';

interface AddPhotoProps {
  handleClose: () => void;
  me?: ClientMe;
}

const AddPhoto: FC<AddPhotoProps> = ({handleClose, me}) => {
  const {setMe} = useSetAppState();
  const [imageData, setImageData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {signedUrl} = useSignedUrl();
  const {updateUserImage} = useUpdateUserImage();

  const handleChange = (ev: any): void => {
    setImageData(() => ev?.target?.files[0]);
  };

  const updateUser = (): void => {
    meApi()
      .then((u: ClientMe) => setMe(u))
      .catch((err) => console.error(err));
  };

  const handleSubmit = async (): Promise<void> => {
    const userId = me?.id;
    if (!imageData || !userId) {
      return;
    }
    setIsLoading(true);
    signedUrl(
      {
        contentType: imageData.type,
        fileName: imageData.name.slice(0, imageData.name.lastIndexOf(".")),
      },
      {
        onSuccess: (s3Url) => {
          axios
            .put(s3Url, imageData, {
              headers: {
                "x-amz-acl": "public-read",
                contentType: imageData.type,
              },
            })
            .then(() => {
              const image_url = s3Url.split("?")[0];
              updateUserImage(
                {
                  payload: { image_url },
                  userId,
                },
                {
                  onSuccess: updateUser,
                  onError: () => setIsLoading(false),
                },
              );
            })
            .finally(() => setIsLoading(false));
        },
        onSettled: () => setIsLoading(false),
      },
    );
  };

  return (
    <>
      <button className="btn-reset c-text-1 ml-auto" onClick={handleClose}>
        <i className="flipicon  flipicon-close"></i>
      </button>
      {!imageData ? (
        <>
          <div className="flex flex-col w-100 justify-center align-center mt-auto mb-auto">
            <input type="file" id="fileupload" hidden onChange={handleChange} />
            <div className="flex flipicon flipicon--popup justify-center align-center text-center br-50 border mb-6">
              <label htmlFor="fileupload" className="flex justify-center">
                <i className="flipicon flipicon--xxl flipicon-photo c-primary"></i>
              </label>
            </div>
            <div className="flex flex-col justify-center text-center align-centers mb-6">
              <label
                htmlFor="fileupload"
                className="fs-md xs-fs-sm lh-md xs-lh-sm fw-700 c-primary mb-2"
              >
                Add Photo
              </label>
              <span className="fs-sm xs-fs-sm lh-sm xs-lh-sm fw-500 c-text-4">
                A photo adds joy to our community and livens up your profile!
              </span>
            </div>
          </div>
          <button
            className="btn btn--sm btn--primary mt-6 mb-4 xs-w-100 xs-btn--full"
            disabled
            style={{ opacity: 0 }}
          ></button>
          <div className="flex justify-center align-center">
            <button className="btn btn--sm btn--text" onClick={handleClose}>
              skip
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col mx-auto justify-center align-center grow-1">
            <div className="flex justify-center align-center avatar-media br-50 mb-6 mt-auto">
              <img
                className="img img--full img--square br-50"
                src={URL.createObjectURL(imageData)}
                alt=""
              />
            </div>
            <span className="fs-md lh-md fw-700 c-primary text-center mb-2">
              Change Photo
            </span>
            <span className="fs-sm lh-sm fw-500 c-text-4 text-center mb-auto">
              A photo adds joy to our community and livens up your profile!
            </span>
            <button
              disabled={isLoading}
              className="btn btn--sm btn--primary mt-6 mb-4 xs-w-100 xs-btn--full"
              onClick={handleSubmit}
            >
              submit
            </button>
            <button className="btn btn--sm btn--text" onClick={handleClose}>
              skip
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AddPhoto;
