import { convertToFormData } from "../utils/helperUtils";
import { getTokens } from "./apiService";
import { getAuth } from "firebase/auth";

interface GetOptions {
  isPrivate?: boolean;
}

interface PostOptions {
  body: any;
  isFormData?: boolean | undefined;
  isPrivate?: boolean | undefined;
}

export const updateOrCreate = async <T>(
  uri: string,
  method: string,
  options: PostOptions,
  isRetry = false,
): Promise<{ response: Response; data: T | IErrorResponse }> => {
  const headers: any = {};
  const idToken = await getAuth().currentUser?.getIdToken();

  if (options.isPrivate) {
    headers.Authorization = idToken as string;
  }
  if (!options.isFormData) {
    headers["Content-Type"] = "application/json";
  }

  const fetchResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_API}${uri}`,
    {
      method: method,
      headers: headers,
      body: options.isFormData
        ? convertToFormData(options.body)
        : JSON.stringify(options.body),
    },
  );

  // if 401 refresh token and retry
  if (fetchResponse.status === 401 && !isRetry) {
    // retry
    const lsRefreshToken = localStorage.getItem("refresh_token");
    if (lsRefreshToken) {
      const tokens = await getTokens(lsRefreshToken);
      if (tokens) {
        return updateOrCreate(uri, method, options, true);
      }
    }

    return {
      response: fetchResponse,
      data: { code: 500, msg: "Unexpected error" },
    };
  } else {
    return !fetchResponse.ok
      ? {
          response: fetchResponse,
          data: (await fetchResponse.json()) as IErrorResponse,
        }
      : { response: fetchResponse, data: (await fetchResponse.json()) as T };
  }
};

const api = {
  get: async <T>(
    uri: string,
    options?: GetOptions,
    isRetry = false,
  ): Promise<T | undefined> => {
    try {
      const fetchOptions: any = {};
      const idToken = await getAuth().currentUser?.getIdToken();
      if (options?.isPrivate) {
        fetchOptions.headers = {
          Authorization: idToken,
        };
      }
      const getResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API}${uri}`,
        fetchOptions,
      );

      if (getResponse.status === 401 && !isRetry) {
        // retry
        const lsRefreshToken = localStorage.getItem("refresh_token");
        if (lsRefreshToken) {
          const tokens = await getTokens(lsRefreshToken);
          if (tokens) {
            return await api.get(uri, options, true);
          }
        }

        return undefined;
      } else {
        return getResponse.status === 200
          ? ((await getResponse.json()) as T)
          : undefined;
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },
  getBlob: async (
    uri: string,
    options?: GetOptions,
    isRetry = false,
  ): Promise<Blob | undefined> => {
    try {
      const fetchOptions: any = {};
      const idToken = await getAuth().currentUser?.getIdToken();
      if (options?.isPrivate) {
        fetchOptions.headers = {
          Authorization: idToken,
        };
      }
      const getResult = await fetch(
        `${process.env.REACT_APP_BACKEND_API}${uri}`,
        fetchOptions,
      );

      if (getResult.status === 401 && !isRetry) {
        // retry
        const lsRefreshToken = localStorage.getItem("refresh_token");
        if (lsRefreshToken) {
          const tokens = await getTokens(lsRefreshToken);
          if (tokens) {
            return await api.getBlob(uri, options, true);
          }
        }

        return undefined;
      } else {
        return await getResult.blob();
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },
  post: async <T>(
    uri: string,
    options: PostOptions,
  ): Promise<{ response: Response; data: T | IErrorResponse }> => {
    return updateOrCreate(uri, "POST", options);
  },
  put: async <T>(
    uri: string,
    options: PostOptions,
  ): Promise<{ response: Response; data: T | IErrorResponse }> => {
    return updateOrCreate(uri, "PUT", options);
  },
  updateOrCreate,
  delete: async <T>(
    uri: string,
    options: PostOptions,
  ): Promise<{ response: Response; data: T | IErrorResponse }> => {
    return updateOrCreate(uri, "DELETE", options);
  },
};

export default api;
