import React, {FC, useContext} from 'react';
import UserAvatar from './UserAvatar';
import AppContext from '../../context/app-context';

const HeaderAuth: FC = () => {
  const {me} = useContext(AppContext);

  return (
    <>
      {!!me && <UserAvatar firstName={me.firstName}
                           lastName={me.lastName}
                           imageUrl={me.imageUrl}
                           email={me.email}
      />}
    </>
  );
};

export default HeaderAuth;
