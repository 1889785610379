import React, { FC, useContext } from "react";
import { Field, Formik } from "formik";
import { flagComment } from "../../../api/apiArticles";
import AppContext from "../../context/app-context";

export const FlagReason: FC<{ handleClose: any }> = ({ handleClose }) => {
  const { showAuthPopup } = useContext(AppContext);
  const onSubmit = async ({ reason }: { reason: string }) => {
    const id = showAuthPopup?.options?.id;
    if (id && showAuthPopup?.options?.closeHandler) {
      await flagComment(id, reason);
      showAuthPopup?.options?.closeHandler();
    }
    handleClose();
  };
  return (
    <Formik initialValues={{ reason: "" }} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="flex flex-col w-100" onSubmit={handleSubmit}>
          <div className="popup popup--sm xs-popup ">
            <div className="popup-card popup-card--primary justify-start xs-px-4 xs-py-4 xs-justify-start">
              <div className="flex flex-col w-100">
                <div className="flex flex-col w-100 justify-center">
                  <div className="flex justify-between mb-12 xs-mb-6">
                    <h2 className="fs-h-7 lh-h-7 fw-700 c-primary ">
                      Please let us know the reason you are flagging this
                      comment
                    </h2>
                    <button
                      className="btn-reset c-text-1 text-left"
                      onClick={handleClose}
                    >
                      <i className="flipicon  flipicon-close"></i>
                    </button>
                  </div>
                  <div className="input-wrap input-wrap--md xs-input-wrap w-100 xs-mb-6 mb-10">
                    <Field
                      as="textarea"
                      rows={8}
                      cols={0}
                      className="input border-primary"
                      id="feedback-input"
                      placeholder="Enter text..."
                      name="reason"
                    ></Field>
                  </div>
                  <div className="ml-auto  xs-w-100 ">
                    <button
                      className="btn btn--sm xs-btn--full xs-w-100 btn--primary"
                      type="submit"
                    >
                      send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
