import React, {useContext, useEffect, useState} from 'react';
import {Popup} from '../../../common/components/AuthModal';
import AppContext from '../../../common/context/app-context';
import toast from 'react-hot-toast';
import {sendFeedbackApi} from '../../../api/apiFeedback';
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const Feedback = () => {
  const [feedbackInputState, setFeedbackInputState] = useState<string>('');
  const {me} = useContext(AppContext);
  const {setShowAuthPopup} = useContext(AppContext);
  const handleSendFeedback = () => {
    if (!me) {
      setShowAuthPopup(() => ({authStep: Popup.Login}));
      return;
    }
    sendFeedbackApi(feedbackInputState)
      .then(() => {
        setFeedbackInputState('');
        toast('Thanks for submitting your feedback.');
      })
      .catch((err) => console.error(err))
    ;
  };
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/feedback', 'feedback page')

  }, []);
  return (
    <div className="container container--sm xs-container--sm">
      <div className="flex flex-col w-100 xs-px-6 sm-px-5 text-start c-text-4">
        <h2 className="fs-h-3 lh-h-3 xs-fs-h-6 xs-lh-h-6 sm-fs-h-4 sm-lh-h-4 mb-6 sm-mb-5 xs-mb-4">
          Feedback - Email Us
        </h2>
        <p className="fs-lg lh-lg xs-fs-sm xs-lh-sm sm-fs-md sm-lh-md mb-6 xs-mb-6">
          Tell us about your experience at Flipped on Dogs or share your
          ideas. We'd love to hear from you.
        </p>
        <div className="input-wrap input-wrap--md xs-input-wrap mt-6 mb-6">
            <textarea
              rows={10}
              cols={0}
              value={feedbackInputState}
              onChange={(e) => setFeedbackInputState(e.target.value)}
              className="input"
              id="feedback-input"
              placeholder="Please enter your feedback..."
            ></textarea>
        </div>
        <div className="flex justify-end align-center mt-5 mb-12 xs-mb-6 xs-mt-0 xs-w-100 ">
          <button
            disabled={feedbackInputState.trim() === ""}
            className="btn btn--md btn--primary xs-btn--lg xs-w-100"
            onClick={handleSendFeedback}
          >
            send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
