import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormErrorSpan from "./FormErrorSpan";

interface Props {
  name: string;
  label: string;
  control: any;
  disabled?: boolean | undefined | null;
  rules?: any;
}

const ControlledPasswordField: React.FC<Props> = ({
  control,
  name,
  label,
  rules,
  disabled,
}) => {
  const getPasswordRequirements = () => (
    <FormErrorSpan>
      Password must contain at least
      <br />
      1 uppercase letter
      <br />
      1 lowercase letter
      <br />
      1 number
      <br />
      1 special character (@$!%*?&)
      <br />
      and be at least 8 characters long
    </FormErrorSpan>
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, formState: { errors } }) => (
        <Box mb={2} sx={{ width: "100%" }}>
          <TextField
            label={label}
            inputRef={field.ref}
            fullWidth
            type="password"
            disabled={!!disabled}
            {...field}
          />
          {errors[name]?.type === "required" && (
            <FormErrorSpan>{label} is Required</FormErrorSpan>
          )}
          {errors[name]?.type === "pattern" && <>{getPasswordRequirements()}</>}
        </Box>
      )}
    />
  );
};

export default ControlledPasswordField;
