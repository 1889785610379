import {postRequest} from './request';

export const addCommentApi = async ({
  payload,
}: {
  payload: any;
}): Promise<any> => {
  return await postRequest<any>(`comments`, payload);
};

export const likeCommentApi = async ({
  payload,
  likedById,
}: {
  payload: any;
  likedById: any;
}): Promise<any> => {
  return await postRequest<any>(`comments/${likedById}/like`, payload);
};
