import { FC } from "react";

interface SuccessProps {
  handleContinue: () => void;
}

const Success: FC<SuccessProps> = ({ handleContinue }) => {
  return (
    <>
      <div className="flex flex-col justify-center align-center mb-auto mt-auto">
        <div className="flex popup-card-media mb-6">
          <img
            className="img img--full img--contain"
            src="/images/dog.png"
            alt=""
          />
        </div>
        <div className="flex flex-col justify-center text-center align-centers mb-6">
          <span className="fs-h-7 lh-h-7 fw-700 c-primary mb-2">
            Congratulations!
          </span>
          <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
            You have successfully signed up.
          </span>
        </div>
        <div className="justify-center">
          <button className="btn btn--sm btn--primary" onClick={handleContinue}>continue</button>
        </div>
      </div>
    </>
  );
};

export default Success;
