import React, { FC, useEffect, useState } from "react";
import isMobile from "is-mobile";

interface DropdownProps {
  options: any[];
  valueField?: string;
  textField?: string;
  handleSelect: (value: any) => void;
  label: string;
  name: string;
  id: string;
  size?: string;
  val?: any;
}

const Dropdown: FC<DropdownProps> = ({
  options,
  textField = "label",
  valueField = "value",
  handleSelect,
  label,
  name,
  id,
  size = "",
  val = "",
}) => {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    setSelectedValue(() => val);
  }, [val]);

  const handleOptionSelect = (value: any): void => {
    setSelectedValue(() => value);
    handleSelect(value);
  };

  return (
    <div
      className={`select-wrap input-wrap${
        isMobile() ? " select-wrap--mobile" : ""
      } ${size}`}
    >
      <select
        name={name}
        id={id}
        className={`input select input-hasicon--right${
          selectedValue || selectedValue === 0 ? " select--selected" : ""
        }`}
        value={selectedValue}
        onChange={(ev) => handleOptionSelect(ev.target.value)}
      >
        {options.map((opt) => (
          <option key={opt[valueField]} value={opt[valueField]}>
            {opt[textField]}
          </option>
        ))}
      </select>
      <label className="input-label" htmlFor="dogage">
        {label}
        <span className="c-text-3" style={{fontFamily: 'flipicons'}}>*</span>
      </label>
      <button className="btn-reset input-wrap-btn input-wrap-btn--right c-text-black pointer-none">
        <span className="flipicon flipicon-chevron-down"></span>
      </button>
      <div className="select-dropdown-wrap">
        {options.map((opt) => (
          <div
            key={opt[valueField]}
            className={`select-dropdown-item${
              selectedValue === opt[valueField]
                ? " select-dropdown-item--selected"
                : ""
            }`}
            onClick={() => handleOptionSelect(opt[valueField])}
          >
            {opt[textField]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
