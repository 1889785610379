import {useState} from 'react';
import {newModal,} from '../../utils/factoryUtils';
import {ModalData} from '../components/AuthModal';
import {ClientMe} from '../types/user';
import {AlbumsResponse} from '../types/albums';

const useSetAppState = () => {
  const [me, setMe] = useState<ClientMe | undefined | null>(undefined);
  const [albums, setAlbums] = useState<AlbumsResponse | undefined>(undefined);
  const [loginModal, setLoginModal] = useState<IAuthModal>(newModal<IAuthModal>());
  const [breadcrumbTitle, setBreadcrumbTitle] = useState<string>('');
  const [sidebarState, setSidebarState] = useState<boolean>(false);
  const [showAuthPopup, setShowAuthPopup] = useState<ModalData | null>(null);
  const [isGameMode, setIsGameMode] = useState<boolean>(false);
  const [isFirstStepPassed, setIsFirstStepPassed] = useState<boolean>(false);
  const [subTopicsId, setSubTopicsId] = useState<number | string | undefined>("");

  return {
    me,
    setMe,
    albums,
    setAlbums,
    loginModal,
    setLoginModal,
    sidebarState,
    setSidebarState,
    showAuthPopup,
    setShowAuthPopup,
    breadcrumbTitle,
    setBreadcrumbTitle,
    isGameMode,
    setIsGameMode,
    isFirstStepPassed,
    setIsFirstStepPassed,
    subTopicsId,
    setSubTopicsId
  };
};

export default useSetAppState;
