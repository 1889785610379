import {useContext, useEffect} from 'react';
import {getAuth} from 'firebase/auth';
import {meApi} from '../../api/apiAuth';
import AppContext from '../context/app-context';
import {getAlbums} from '../../api/apiGame';
import {ClientMe} from '../types/user';
import {MixpanelEvent} from '../enums/mixpanel-event';
import {mixpanelSetPeople} from "../../utils/mixpanel-set-people";

export const useFirebaseLogin = () => {
  const {setMe, setAlbums} = useContext(AppContext);
  useEffect(() => {
    const auth = getAuth();
    let timeoutRef: any = null;
    const unsubscribeState = auth.onAuthStateChanged((u) => {
      timeoutRef = setTimeout(() => {
        if (u) {
          meApi()
            .then((user: ClientMe) => {
              setMe(user);
              // Set this to a unique identifier for the user performing the event.
              mixpanelSetPeople(MixpanelEvent.login_to_account, {id:user.uid,email:user.email,name:`${user.firstName} ${user.lastName}`});
            })
            .catch((err) => console.error(err));
          getAlbums()
            .then((albums) => setAlbums(albums))
            .catch((err) => console.error(err));
        } else {
          setMe(null);
        }
      }, 400);
    });
    const unsubscribeId = auth.onIdTokenChanged(async (u) => {
      timeoutRef = setTimeout(() => {
        if (u) {
          meApi()
            .then((user) => setMe(user))
            .catch((err) => console.error(err));
          getAlbums()
            .then((albums) => setAlbums(albums))
            .catch((err) => console.error(err));
        } else {
          setMe(null);
        }
      }, 400);
    });
    return () => {
      !!timeoutRef && clearTimeout(timeoutRef);
      unsubscribeState();
      unsubscribeId();
    };
  }, [setMe]);
};
