import {getRequest} from "./request";
import {QuickCourseConfigInterface, QuickCoursesInterface} from "../common/types/sidebar";

export const getCourseData = async (): Promise<QuickCoursesInterface[]> => {
    return await getRequest<QuickCoursesInterface[]>(`games/courses`).then((res) => res.data);
};

export const getCourseConfigData = async (): Promise<QuickCourseConfigInterface> => {
    return await getRequest<QuickCourseConfigInterface>(`games/courses/config`).then((res) => res.data);
};

export const getCourseItemData = async (slug: number | string, config?: any): Promise<any> => {
    return await getRequest<any>(`games/courses/${slug}`, undefined, config).then((res) => res.data);
};