import { getRequest, toQueryString } from "./request";
import { Topic } from "../common/types/topics";
import { ArticleBySubTopic } from "../common/types/articles";

export const getTopics = async (params?: any): Promise<Topic[]> => {
  return await getRequest<Topic[]>(
    `topics${
      params
        ? "?" +
          toQueryString(params.search ? { search: params.search } : params)
        : ""
    }`,
  ).then((res) => res.data);
};

export const getSubTopics = async (slug: string): Promise<ArticleBySubTopic> => {
  return await getRequest<ArticleBySubTopic>(`sub-topics/${slug}`).then(
    (res) => res.data,
  );
};
