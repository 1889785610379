import React from "react";

interface Props {
  children: any;
}

const FormErrorSpan: React.FC<Props> = ({ children }) => {
  return <small style={{ color: "red" }}>{children}</small>;
};

export default FormErrorSpan;
