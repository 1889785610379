import { useLocation } from 'react-router-dom';

function useQueryParams(): any {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const queryParams = {};

  // @ts-ignore
  for (const [key, value] of searchParams) {
    // @ts-ignore
    queryParams[key] = value;
  }

  return queryParams;
}

export default useQueryParams;