import axios from "axios";
import { getAuth } from "firebase/auth";
import {GUEST_TOKEN_KEY} from '../api/apiAuth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
});

instance.interceptors.request.use(
  async (config) => {
    const idToken = await getAuth().currentUser?.getIdToken();
    if (config.headers.Authorization !== "NoToken") {
      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
    }
    const guestToken = localStorage.getItem(GUEST_TOKEN_KEY);
    if (guestToken) {
        config.headers[GUEST_TOKEN_KEY] = guestToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
