import React, {createRef, FC, memo, RefObject} from 'react';
import {Cropper, ReactCropperElement} from 'react-cropper';
import {upload} from './DogCard';

interface CropperModalProps {
  imgSrc: string;
  setOpenModal: (v: boolean) => void;
  onCropComplete: (url: string) => void;
}

export const CropperModal: FC<CropperModalProps> = memo(({setOpenModal, imgSrc, onCropComplete}) => {
  const cropperRef: RefObject<ReactCropperElement> = createRef<ReactCropperElement>();

  const onCrop = async (): Promise<void> => {
    const cropper: Cropper | undefined = cropperRef.current?.cropper;
    if (!cropper) return;
    const dataUrl: string = cropper.getCroppedCanvas().toDataURL();
    onCropComplete(dataUrl);
  };

  return (
    <div className="dog-card-popup">
      <div className="dog-card-popup-bg"/>
      <div
        className="dog-card-popup-in flex flex-col justify-start align-stretch pt-6 pb-12 px-6 xs-pt-4 xs-px-0 xs-pb-4">
        <div className="dog-card-popup-header flex justify-between align-start mb-12 xs-mb-6 xs-px-4">
          <button
            onClick={() => setOpenModal(false)}
            className="btn-reset dog-card-icon grow-0 shrink-0 mr-4"
          >
            <img
              className="img img--square"
              src="images/dog-card-back.webp"
              alt=""
            />
          </button>
          <h2
            className="dog-card-title text-uppercase text-center mx-auto"
            data-text="Crop photo"
          >
            Crop photo
          </h2>
          <button className="btn-reset dog-card-icon xs-static grow-0 shrink-0 ml-4"/>
        </div>
        <div className="dog-card-popup-body flex flex-col justify-start align-stretch px-9 xs-px-0">
          <div className="dog-card-popup-crop-wrap flex flex-col justify-start align-stretch mb-12 xs-mb-6">
            <Cropper
              ref={cropperRef}
              style={{height: 400, width: '100%', objectFit: 'contain'}}
              initialAspectRatio={1}
              aspectRatio={1}
              preview=".img-preview"
              viewMode={1}
              minCropBoxHeight={50}
              minCropBoxWidth={50}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
              src={imgSrc}
            />
          </div>
          <div
            className="dog-card-popup-btns-wrap flex xs-flex-col-reverse justify-between xs-justify-start align-center xs-align-stretch col-gap-4 row-gap-4 xs-px-4">
            <button
              onClick={upload}
              type="button"
              className="btn-reset dog-card-form-btn dog-card-form-btn--pink px-4 grow-1"
            >
              Change
            </button>
            <button
              type="button"
              onClick={onCrop}
              className="btn-reset dog-card-form-btn dog-card-form-btn--blue px-4 grow-1"
            >
              Crop
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
