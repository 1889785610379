import {useQuery} from "react-query";
import {getCourseConfigData, getCourseData, getCourseItemData} from "../../api/apiCourse";

export const useCourse = () => {
    return useQuery({ queryKey: "useCourseData", queryFn: getCourseData});
};

export const useCourseConfig = () => {
    return useQuery({ queryKey: "useCourseConfigData", queryFn: getCourseConfigData});
};

export const useCourseItem = (slug: number | string, config?: any) => {
    return useQuery({ queryKey: ["useCourseItemData", slug], queryFn: () => getCourseItemData(slug, config)});
};