import React, {Dispatch, SetStateAction} from 'react';
import {newModal,} from '../../utils/factoryUtils';
import {ModalData} from '../components/AuthModal';
import {ClientMe} from '../types/user';
import {AlbumsResponse} from '../types/albums';

interface IAppContext {
  breadcrumbTitle: string;
  setBreadcrumbTitle: Dispatch<SetStateAction<string>>;
  me: ClientMe | undefined | null;
  setMe: Dispatch<SetStateAction<ClientMe | undefined | null>>;
  loginModal: IAuthModal;
  setLoginModal: Dispatch<SetStateAction<IAuthModal>>;
  sidebarState: boolean;
  setSidebarState: Dispatch<SetStateAction<boolean>>;
  showAuthPopup: ModalData | null;
  setShowAuthPopup: Dispatch<SetStateAction<ModalData | null>>;
  isGameMode: boolean;
  setIsGameMode: Dispatch<boolean>;
  albums: AlbumsResponse | undefined;
  setAlbums: Dispatch<AlbumsResponse>;
  isFirstStepPassed: boolean;
  setIsFirstStepPassed: Dispatch<SetStateAction<boolean>>;
  subTopicsId: number | string | undefined;
  setSubTopicsId: Dispatch<SetStateAction<number | string | undefined>>;
}

const AppContext = React.createContext<IAppContext>({
  me: undefined,
  setMe: () => {
  },
  albums: undefined,
  setAlbums: () => {
  },
  loginModal: newModal<IAuthModal>(),
  setLoginModal: () => {
  },
  sidebarState: false,
  setSidebarState: () => {
  },
  showAuthPopup: null,
  setShowAuthPopup: () => {
  },
  breadcrumbTitle: '',
  setBreadcrumbTitle: () => {
  },
  isGameMode: false,
  setIsGameMode: () => {
  },
  isFirstStepPassed: false,
  setIsFirstStepPassed: () => {},
  subTopicsId: "",
  setSubTopicsId: () => {}
});

export default AppContext;
