import {getRequest, postRequest, putRequest} from './request';
import {UserDraftedSubmission} from '../common/types/user-submissions';
import {AxiosRequestConfig} from "axios";

export const getUserDraftedSubmission = async (
  userId: string | number | null | undefined,
  config?: AxiosRequestConfig
): Promise<UserDraftedSubmission> => {
  return await getRequest<UserDraftedSubmission>(
    `users/${userId}/submission`,
    undefined,
    config,
  ).then((res) => res.data);
};

export const userSubmissionCreateUpdateApi = async ({
  payload,
  type,
  config,
  id,
}: {
  payload: any;
  type: "create" | "update";
  config?: AxiosRequestConfig,
  id: number;
}): Promise<any> => {
  switch (type) {
    case "create":
      return await postRequest<any>(`users/submissions`, payload, config);
    case "update":
      return await putRequest<any>(`users/submissions/${id}`, payload, config);
  }
};
