import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import { signedUrlApi } from "../../api/apiAuth";
import axios from "axios";
import {Link} from "react-router-dom";
interface SocialShareProp {
  type: "article" | "game";
  gameId?: number;
  articleSlug?: string;
  userId?: number;
  imageData?: string;
  score?: number;
}

const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

const TWITTER_URL = "https://twitter.com/intent/tweet?url=";
const FACEBOOK_URL = "https://www.facebook.com/sharer/sharer.php?u=";

//https://fod-server-development-6xrvs.ondigitalocean.app/api/client/social/game-result/:gameId/users/:userId
//https://fod-server-development-6xrvs.ondigitalocean.app/api/client/social/articles/:id
export const SocialShare: React.FC<SocialShareProp> = ({
  type,
  gameId,
  articleSlug,
  userId,
  imageData,
  score,
}) => {
  const [facebookUrl, setFacebookUrl] = useState<string>("");
  const [twitterUrl, setTwitterUrl] = useState<string>("");
  const [imageS3Url, setImageS3Url] = useState<string>("");
  let articleShare = `${process.env.REACT_APP_SERVER_API}/social/articles/${articleSlug}`;
  let gameShare = `${process.env.REACT_APP_SERVER_API}/social/game-result/${gameId}/users/${userId}`;

  const isMobile: boolean = isMobileBrowser();

  useEffect(() => {
    switch (type) {
      case "article": {
        setFacebookUrl(`${FACEBOOK_URL}${articleShare}`);
        setTwitterUrl(`${TWITTER_URL}${articleShare}`);
        break;
      }
      case "game": {
        setFacebookUrl(`${FACEBOOK_URL}${gameShare}`);
        setTwitterUrl(`${TWITTER_URL}${gameShare}`);
        break;
      }
      default: {
        throw Error(`${type} not implemented`);
      }
    }
  }, [type, articleSlug, gameId, userId]);

  function base64ToFile(base64Data: string, filename: string): File {
    const [mime, data] = base64Data.split(";base64,");
    const contentType = mime.split(":")[1];
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return new File([blob], filename, { type: contentType });
  }

  const uploadToS3 = async (): Promise<string> => {
    if (!imageData) {
      return "";
    }
    const imageFile = base64ToFile(imageData, `image_${Date.now()}`);
    const s3Url = await signedUrlApi({
      contentType: imageFile.type,
      fileName: imageFile.name.slice(0, imageFile.name.lastIndexOf(".")),
    });
    const image_url = s3Url.split("?")[0];
    await axios.put(s3Url, imageFile, {
      headers: {
        "x-amz-acl": "public-read",
        contentType: imageFile.type,
      },
    });
    return image_url;
  };

  async function getImageS3Url(): Promise<string> {
    let imageUrl: string = "";
    if (!imageS3Url) {
      imageUrl = await uploadToS3();
      setImageS3Url(imageUrl);
    } else {
      imageUrl = imageS3Url;
    }
    return imageUrl;
  }

  const nativeShare = async (): Promise<void> => {
    const imageUrl = await getImageS3Url();
    navigator.share &&
      navigator
        .share({
          url:
            type === "game"
              ? `${gameShare}?imageUrl=${imageUrl}&score=${score}`
              : articleShare,
          title: "",
          text: "",
          files: []
        })
        .catch((err) => console.error(err));
  };

  const handleCopyLinkClick = async (): Promise<void> => {
    const imageUrl = await getImageS3Url();
    copy(type === "game" ? `${gameShare}?imageUrl=${imageUrl}&score=${score}` : articleShare);
    toast.success("Copied to clipboard");
  };

  const handleTwitterShareClick = async (): Promise<void> => {
    const imageUrl = await getImageS3Url();
    window.open(`${twitterUrl}?imageUrl=${imageUrl}&score=${score}`, "_blank");
    toast.success("Share To Twitter");
  };

  const handleFacebookShareClick = async (): Promise<void> => {
    const imageUrl = await getImageS3Url();
    window.open(`${facebookUrl}?imageUrl=${imageUrl}&score=${score}`, "_blank");
    toast.success("Share To Facebook");
  };

  return (
    <div className="flex flex-col justify-start align-stretch xs-w-100 mt-6">
      {isMobile ? (
        <>
          <div className="flex justify-center  align-center">
            <button
              className="btn btn-reset btn--sm btn--sm-share btn--primary"
              onClick={() => {
                nativeShare();
              }}
            >
              share
            </button>
          </div>
        </>
      ) : (
        <>

          <span className="block text-center fs-lg lh-lg fw-700 c-text-1 mb-2 mt-4">
            Share To:
          </span>
          <div className="flex justify-center  col-gap-2 align-center">
            <button className="btn-reset c-primary"  onClick={handleFacebookShareClick}>
              <span className="flipicon flipicon-facebook flipicon--sm xs-flipicon--md"></span>
            </button>
            <button
              className="btn-reset c-primary"
              onClick={handleTwitterShareClick}
            >
              <span className="flipicon flipicon-x flipicon--sm xs-flipicon--md"></span>
            </button>
            <button
              type="button"
              className="btn-reset c-primary col-gap-2"
              onClick={handleCopyLinkClick}
            >
              <span className="flipicon flipicon-link flipicon--sm xs-flipicon--md"></span>
              <span className="block fs-md lh-md fw-700 xs-fs-sm xs-lh-sm">
                Copy Link
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
