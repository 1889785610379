import React, {useContext, useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import appContext from '../context/app-context';
import {Popup} from './AuthModal';
import {useTrackGoogleAnalyticsEvent} from '../hooks/useTrackGoogleAnalyticsEvent';

const AuthGuard: React.FC = () => {
  const {me, setShowAuthPopup} = useContext(appContext);
  const location = useLocation();
  const logGoogleEvent = useTrackGoogleAnalyticsEvent();

  useEffect(() => {
    return () => {
      !location.pathname.includes('submit-dog-final') &&
        logGoogleEvent("Dog Submission", `Submission Forms Left Before Completion, path: ${location.pathname}`);
    }
  }, [location, logGoogleEvent])

  if (me === undefined) return <></>;

  if (!me) {
    if (location.state?.showAuthPopup) {
      setTimeout(() => {
        setShowAuthPopup({authStep: Popup.Login, options: {redirectUrl: location.pathname}});
      }, 500);
    }

    return <Navigate to="/"/>;
  }
  return <Outlet />;
};

export default AuthGuard;
