import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, Params, useParams} from "react-router-dom";
import {
  useArticleById,
  useLatestArticles,
} from "../../../common/hooks/useArticles";
import {
  useLikeComment,
  useSubmitComment,
} from "../../../common/hooks/useComments";
import {
  Article as IArticle,
  ArticleComment,
} from "../../../common/types/articles";
import moment from "moment";
import useQueryParams from "../../../common/hooks/useQueryParams";
import { SocialShare } from "../../../common/components/SocialShare";
import AppContext from "../../../common/context/app-context";
import { Popup } from "../../../common/components/AuthModal";
import UserAvatar from "../../../common/components/header/UserAvatar";
import {
  useTrackGoogleAnalyticsEvent,
  useTrackGoogleAnalyticsEventPages
} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";
import { articleView } from "../../../api/apiArticles";
import {Helmet} from "react-helmet";

const Article = () => {
  const param:Readonly<Params<any>> = useParams();
  const {
    data: articleReq,
    isFetched,
    refetch,
  } = useArticleById(param?.id || "");
  const { me: user } = useContext(AppContext);

  const { submitComment } = useSubmitComment();
  const { likeComment } = useLikeComment();
  const { data: latestArticles } = useLatestArticles();
  const [commentInputState, setCommentInputState] = useState<string>("");
  const [showAllComments, setShowAllComments] = useState<boolean>(false);
  const [commentsState, setCommentsState] = useState<ArticleComment[]>([]);
  const { showMode } = useQueryParams();
  const [articleToShow, setArticleToShow] = useState<any>();
  const [article, setArticle] = useState<IArticle>();
  const { me, setBreadcrumbTitle,setSubTopicsId, subTopicsId } = useContext(AppContext);
  const { setShowAuthPopup } = useContext(AppContext);
  const logGoogleEvent = useTrackGoogleAnalyticsEvent();

  const articleShare = `${process.env.REACT_APP_SERVER_API}/social/articles/${article?.slug}`;

  useEffect(() => {
    commentsViewHandler();
  }, [isFetched, articleReq, showAllComments]);

  useEffect(() => {
    window.parent.postMessage({ loaded: true }, "*");
    window.addEventListener("message", handlePostMessage);
    logGoogleEvent("Article", `View-title-${article?.title}`);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);
  useEffect(() => {
    if (param.id){
      useTrackGoogleAnalyticsEventPages('/article'+param.id, ' Article page for'+param.id)
    }

  }, []);
  useEffect(() => {
    if (article) {
      if(!subTopicsId){
        setBreadcrumbTitle(article?.subTopicSelections[0].subTopic.name || "");
        setSubTopicsId(article?.subTopicSelections[0].subTopic.slug);
        return;
      }
      const currentSubTopic = article?.subTopicSelections.find((item) => item.subTopic.slug == subTopicsId);
      setBreadcrumbTitle(currentSubTopic?.subTopic.name || "");
      setSubTopicsId(currentSubTopic?.subTopic.slug);
    }
    return () => {
      setBreadcrumbTitle("");
    };
  }, [article, setBreadcrumbTitle]);

  useMemo(() => {
    !showMode && param.slug && articleView(param.slug);
  }, [param.slug]);

  useEffect(() => {
    if (!!showMode) {
      setArticle(() => articleToShow);
    } else if (articleReq) {
      setArticle(() => articleReq);
    }
  }, [articleReq, articleToShow]);
  function handlePostMessage({ data }: any): void {
    if (data.type === "article") {
      setArticleToShow(data.data);
    }
  }

  const handleSubmitClick = () => {
    if (!me) {
      setShowAuthPopup(() => ({ authStep: Popup.Login }));
      return;
    }
    const payload = {
      body: commentInputState,
      parentId: param?.id,
      parentType: "articles",
      postedById: user?.id,
    };
    submitComment(
      {
        payload,
      },
      {
        onSuccess: () => handleSubmitCommentSuccess(),
        onError: (error) => console.error(error),
      },
    );
  };

  const likeClickHandler = (commentId: number) => {
    if (!me) {
      setShowAuthPopup(() => ({ authStep: Popup.Login }));
      return;
    }
    const payload = {
      parentId: commentId,
      parentType: "comment",
      likedById: user?.id,
      likeValue: 1,
    };
    likeComment(
      {
        payload,
        likedById: user?.id,
      },
      {
        onSuccess: () => handleLikeFlagCommentSuccess(),
        onError: (error) => console.error(error),
      },
    );
  };

  const flagClickHandler = (commentId: number) => {
    if (!me) {
      setShowAuthPopup(() => ({ authStep: Popup.Login }));
      return;
    }
    setShowAuthPopup(() => ({
      authStep: Popup.FlagComment,
      options: {
        id: commentId,
        closeHandler: () => handleLikeFlagCommentSuccess(),
      },
    }));
  };

  const handleSubmitCommentSuccess = () => {
    setCommentInputState("");
    refetch().then((res) => commentsViewHandler(res));
  };

  const handleLikeFlagCommentSuccess = () => {
    refetch().then((res) => commentsViewHandler(res));
  };

  const commentsViewHandler = (refetchData?: any) => {
    const c = refetchData?.data.comments || articleReq?.comments;
    if (showAllComments) {
      setCommentsState(c || []);
      return;
    }
    setCommentsState(c?.slice(0, 2) || []);
  };

  const showAllCommentsSwitcher = () => {
    setShowAllComments((prev: boolean) => !prev);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={articleShare}/>
        <meta property="og:url" content={articleShare}/>
      </Helmet>
      <div className="container container--sm xs-container--sm sm-px-6 xs-px-6">
        <div className="flex flex-col w-100 align-start">
        <div className="flex flex-col align-start justify-start xs-mt-2 mb-6">
            <span className="fs-h-4 lh-h-4 sm-fs-xl sm-lh-h-7 fw-700 c-text-black mb-2 sm-mb-1 xs-mb-1">
              {article?.title}
            </span>
            <span className="fs-lg lh-xl fw-400 c-text-4">
              {article?.excerpt}
            </span>
          </div>
          <div className="flex topics-media--xl mb-8">
            <img
              className="img img--full img--contain br"
              src={article?.thumbnailUrl}
              alt=""
            />
          </div>
          <div className="flex flex-col mb-6">
            <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-700 c-text-black ">
              By{" "}
              <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-700 c-text-black">
                {article?.articleAuthor?.displayName}
              </span>
            </span>
          </div>
          <div
            className="article-wrap container container--sm xs-container--sm mb-5 xs-mb-10"
            dangerouslySetInnerHTML={{
              __html: article?.articleContent || "",
            }}
          ></div>
          <div className="flex w-100 justify-center align-center text-center mb-12 xs-mb-6">
            <SocialShare type={"article"} articleSlug={article?.slug}></SocialShare>
          </div>
        </div>
      </div>
      {!showMode && (
        <>
          {!user?.isBanned && (
            <div className="flex flex-col w-100 justify-center align-center bg-primary-light-2">
              <div className="container container--sm ">
                <div className="flex flex-col w-100 justify-center align-center py-12 xs-py-6 xs-px-6 sm-px-6">
                  <h2 className="fs-h-4 lh-h-4 sm-fs-h-5 sm-lh-h-5 xs-fs-h-7 xs-lh-h-7 fw-700 c-text-2 mb-8 xs-mb-6">
                    Join the Conversation
                  </h2>
                  <div className="input-wrap input-wrap--dark input-wrap--lg sm-input-wrap xs-input-wrap w-100">
                    <input
                      type="text"
                      className="input"
                      placeholder="Add a comment"
                      value={commentInputState}
                      onChange={(e) => setCommentInputState(e.target.value)}
                    />
                  </div>
                  <button
                    className="btn btn--primary xs-btn--lg btn--full"
                    onClick={handleSubmitClick}
                    disabled={commentInputState.trim() === ""}
                  >
                    submit
                  </button>
                  <span className="fs-lg lh-lg sm-fs-md sm-lh-md xs-fs-sm xs-lh-sm fw-500 c-text-2 text-center mt-6 xs-mt-6 mb-4 xs-mb-4">
                    Have fun. Don’t be rude. Discuss ideas, not people. Flag bad
                    behavior.{" "}
                    <Link
                      to="/community-guidelines"
                      className="c-text-2 underline"
                      target="_blank"
                    >
                      Our Community Guidelines
                    </Link>
                  </span>
                  {commentsState.map(
                    (comment: ArticleComment, index: number) => (
                      <div
                        className={`flex w-100 justify-start bg-primary-light-4 border-dark br px-4 py-4 xs-px-2 xs-py-2 align-start ${
                          commentsState.length - 1 !== index
                            ? "mb-6 xs-mb-4"
                            : ""
                        }`}
                        key={comment.id}
                      >
                        <div className="avatar-media avatar-media--lg br-50 mr-4 xs-mr-2">
                          <UserAvatar
                            firstName={comment.postedBy.firstName}
                            lastName={comment.postedBy.lastName}
                            imageUrl={comment.postedBy.imageUrl}
                            email={comment.postedBy.email}
                          ></UserAvatar>
                        </div>
                        <div className="flex grow-1 flex-col justify-start">
                          <span className="fs-md lh-md fw-700 c-text-1 mb-2 xs-mb-1">
                            {comment.postedBy.firstName}{" "}
                            {comment.postedBy.lastName}{" "}
                            <span className="fs-sm lh-sm fw-500 c-text-1">
                              {moment(comment.createdAt).format("MMM D, YYYY")}
                            </span>
                            <p className="fs-sm lh-sm fw-500 c-text-1 mt-2 sm-mt-1 xs-mt-1">
                              {comment.body}
                            </p>
                          </span>
                          <div className="flex w-100 justify-between">
                            <span
                              className={`flipicon flipicon-like${
                                comment.contentLikes?.some(
                                  (likes) => likes.likedById === user?.id,
                                )
                                  ? "-fill"
                                  : ""
                              } c-primary cursor-pointer`}
                              onClick={() => likeClickHandler(comment?.id)}
                            >
                              {comment.contentLikes?.length || ""}
                            </span>
                            <span
                              className={`flipicon flipicon-flag${
                                comment.flagged ? "-fill" : ""
                              } c-primary-light cursor-pointer`}
                              onClick={() => flagClickHandler(comment?.id)}
                            ></span>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                  {(article?.comments?.length || 0) > 2 && (
                    <a
                      className="fs-lg lh-lg sm-fs-md sm-lh-md xs-fs-sm xs-lh-sm fw-500 c-text-2 underline mt-6 xs-mt-4"
                      onClick={showAllCommentsSwitcher}
                    >
                      {showAllComments
                        ? "Show less comments"
                        : "Load more comments"}
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="container container--sm xs-container--sm xs-px-6 sm-px-6">
            <div className="flex flex-col w-100 justify-center  pt-6 xs-pt-0 mt-12 xs-mt-6 sm-mt-6 mb-18 sm-mb-13 xs-mb-12">
              <h2 className="fs-h-3 lh-h-3 xs-fs-h-7 xs-lh-h-7 sm-fs-h-5 sm-lh-h-5 c-primary-dark mb-12 xs-mb-6 sm-mb-7">
                You Might Also Like...
              </h2>
              {latestArticles?.map((article, index) => { return(
                <Link to={`/article/${article.slug}`} key={article.id}>
                  <div
                    className={`flex sm-flex-col xs-flex-col sm-flex-row justify-start w-100 ${
                      index !== latestArticles?.length - 1
                        ? "border-b--primary"
                        : ""
                    } py-6 xs-pb-4 ${index === 0 ? "xs-pt-0" : ""}`}
                  >
                    <div className="flex topics-media--lg mr-12 sm-mr-8 xs-mr-0 br--sm overflow">
                      <img
                        className="img img--fill img--contain"
                        src={article.thumbnailUrl}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col align-start justify-start xs-mt-2 ">
                      <Link to={`/topics/${article.articleAuthorId}`}>
                      <span className="fs-xl lh-xl sm-fs-md sm-lh-md fw-700 c-primary mb-2 sm-mb-1 xs-mb-1">
                        {article.subTopics[0].name}
                      </span>
                      </Link>
                      <span className="fs-h-4 lh-h-4 sm-fs-xl sm-lh-h-7 fw-700 c-text-black mb-2 sm-mb-1 xs-mb-1">
                        {article.title}
                      </span>
                    </div>
                  </div>
                </Link>
              )})}
            </div>
            <div className="flex justify-center align-center xs-w-100 mb-18 sm-mb-14 xs-mb-12">
              <Link
                to="/submit-dog-step-1"
                state={{ showAuthPopup: true }}
                className="btn btn--primary xs-btn--lg xs-w-100"
              >
                submit my dog
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Article;
