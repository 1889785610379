import React, {FC, ReactNode} from "react";
import {useFirstRound} from "../hooks/useFirstRound";
import {Answer} from "../types/game";

const tiles: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export enum RoundType {
  TileGame = 1,
  FourPhoto,
  SinglePhoto,
  SingleVideo,
  FindTheLie,
  Matching
}

interface GamePreviewProps {
  showAnswers?: boolean;
}

export const GamePreview: FC<GamePreviewProps> = ({showAnswers = false}) => {
  const {data: firstRound} = useFirstRound();

  function renderRoundQuestion(): ReactNode {
    return (
      <div
        className=" question  px-2 py-2 xs-px-2 xs-py-1 sm-px-3 sm-py-2 c-text-white text-center justify-center align-center flex  ">
        <span className="fs-h-8 lh-md  sm-fs-md sm-lh-sm  xs-fs-md xs-lh-sm fw-400 font-londrina">
          {firstRound?.data.question}
        </span>
      </div>
    );
  }

  function renderMatchingRoundPreview(side: string): ReactNode {
    return <div className="matching-flex-column">
      {firstRound?.data.answers.map((item, i: number) => {
        return (
          <React.Fragment key={i}>
            {item.side === side &&
                <div className="matching-answer matching-answer--sm" key={i}>
                  {item.url ?
                    <img
                      src={item.url}
                      className="answer-item" style={{padding: "0"}}
                      alt="answer_image"
                    /> :
                    <span>{item.name}</span>}
                </div>
            }
          </React.Fragment>
        )
      })}
    </div>
  }

  function renderRoundAnswers(): ReactNode {
    if (!showAnswers) return <></>;
    return (
      <div className="answer-backLayer xs-px-1 xs-py-1">
        <div className=" answer-frontLayer bg-yellow xs-px-1 xs-py-1">
          {firstRound?.data.answers.map((a: Answer) => (
            <div
              key={a.uid}
              className="answer-item px-4 py-4 flex justify-center align-center text-center"
            >
              <span className="fs-lg fw-400 c-text-white">{a.name ?? ""} </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderRoundImageAnswers(): ReactNode {
    return (
      <div className="game-container four-photo">
        {firstRound?.data.answers.map((a: Answer) => (
          <div className="four-photo--item" key={a.uid}>
            <img key={a.uid} className="game-img" src={a.url} alt={a.name}/>
          </div>
        ))}
      </div>
    );
  }

  function renderRound(): ReactNode {
    if (!firstRound) return <></>;
    switch (firstRound.roundTypeId) {
      case RoundType.TileGame:
        return (
          <>
            <div className="game-container game-container--sm">
              <div className="guessBreed">
                <div className="guessBreed-media  w-100 h-100 flex justify-center align-center">
                  <img
                    className="guessBreed--img game-img game-container--background--image img img--full img--cover score--media-img"
                    src={firstRound.data.assetUrl}
                    alt=""
                  />
                </div>

                <div className="guessBreed--tiles">
                  {tiles.map((i: number) => (
                    <div
                      className={`tile--item ${
                        i === firstRound.data.tileHint
                          ? "hint"
                          : firstRound.data.tiles.find(
                            (t: any) => t.order === i,
                          )
                            ? "tile"
                            : ""
                      }`}
                      key={i}
                    ></div>
                  ))}
                </div>
              </div>
            </div>

            {renderRoundQuestion()}

            {renderRoundAnswers()}
          </>
        );
      case RoundType.FourPhoto:
        return (
          <>
            {renderRoundImageAnswers()}

            {renderRoundQuestion()}
          </>
        );
      case RoundType.SinglePhoto:
      case RoundType.FindTheLie:
        return (
          <>
            <div className="game-container single-photo ">
              <div className="single-photo--media">
                <img
                  className="game-img"
                  alt=""
                  src={firstRound.data.assetUrl}
                />
              </div>
            </div>

            {renderRoundQuestion()}

            {renderRoundAnswers()}
          </>
        );
      case RoundType.SingleVideo:
        return (
          <>
            <div className="game-container relative">
              <div className="single-video h-100">
                <button className="btn-reset play-btn z-1 pointer-none">
                  <span className="flipicon flipicon--lg flipicon-play c-text-white"/>
                </button>
                <video
                  id="videoEl"
                  controls={false}
                  preload="metadata"
                  className="img img--fill img--contain img--contain-center pointer-none"
                >
                  <source
                    src={firstRound.data.assetUrl + "#t=0.5"}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>

            {renderRoundQuestion()}

            {renderRoundAnswers()}
          </>
        );
      case RoundType.Matching:
        return (
          <>
            {renderRoundQuestion()}

            <div className="game-container">
              <div className="matching-flex">
                {renderMatchingRoundPreview("left")}
                {renderMatchingRoundPreview("right")}
              </div>
            </div>

            {renderRoundAnswers()}
          </>
        );
      default:
        return <></>;
    }
  }

  return (
    <div className="flex game-preview-holder align-center flex-col justify-center row-gap-4 px-0 px-5 sm-px-7 w-100">
      {renderRound()}
    </div>
  );
};
