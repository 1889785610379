import React from "react";
import Button from "@mui/material/Button";

interface Props {
  text?: string | undefined;
  isCancel?: boolean;
  hidden?: boolean;
  // All other prop s
  [x: string]: any;
}

const FTBButton: React.FC<Props> = ({ text, isCancel, hidden, ...rest }) => {
  if (hidden) {
    return <></>;
  }

  return (
    <Button variant={isCancel ? "outlined" : "contained"} {...rest}>
      {text}
    </Button>
  );
};

export default FTBButton;
