import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import {
  useTrackGoogleAnalyticsEvent,
  useTrackGoogleAnalyticsEventPages
} from '../../../common/hooks/useTrackGoogleAnalyticsEvent';

const SubmitDogFinalPage = () => {
  const logGoogleEvent = useTrackGoogleAnalyticsEvent();

  useEffect(()=> {
    logGoogleEvent("Dog Submission", "Dog Submission Forms Completed");
  }, []);

  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/submit-dog-final', 'submit dog final page')

  }, []);
  return (
    <>
      <div className="container xs-container--sm xs-px-6 xs-py-6 sm-px-6 sm-py-6">
        <div className="flex flex-col justify-center align-center text-center py-20 xs-py-18">
          <span className="fs-h-3 lh-h-3 sm-fs-h-4 sm-lh-h-4 xs-fs-h-7 xs-lh-h-7 fw-700 c-text-black mb-12 xs-mb-6 ">
            Thanks for Sharing Your <br /> Awesome Dog!
          </span>
          <div className="flex submit-media submit-media--sm mb-12 xs-mb-6">
            <img
              className="img img--full img--contain"
              src="/images/dog.png"
              alt=""
            />
          </div>
          <p className="fs-h-7 lh-h-7 sm-fs-xl sm-lh-xl xs-fs-sm xs-lh-sm fw-500 c-primary mb-12 xs-mb-6">
            If your entry is selected for our game, we'll get in touch so you
            can tell your pup.
            <br />
            Seriously, he won’t care, but you can brag to all your friends.
            <br /> Wags, Your Team at Flipped on Dogs
          </p>
          <div className="flex flex-col justify-center align-center xs-w-100">
            <Link to="/game" className="btn btn--primary xs-btn w-100 mb-6">
              play today's game
            </Link>
            <Link to="/topics" className="btn btn--primary xs-btn w-100">
              go to topics
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitDogFinalPage;
