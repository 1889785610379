import mixpanel from 'mixpanel-browser';
import {MixpanelEvent} from "../common/enums/mixpanel-event";

export const mixpanelSetPeople = (name:MixpanelEvent,data:{name:string;id:string;email:string}) => {
    try {
        mixpanel.identify(data.id);
        mixpanel.people.set({"$email":data.email,"$name":data.name})
    } catch {
    }
};
