import React, {useEffect} from "react";
import ImageVideoUpload from "./image-video-upload";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const SubmitDogStepFive = () => {
    useEffect(() => {

        useTrackGoogleAnalyticsEventPages('/submit-dog-step-5', 'submit dog step 5 page')

    }, []);
  return (
    <>
      <ImageVideoUpload type="Video" currentStep={5} />
    </>
  );
};

export default SubmitDogStepFive;
