import React from 'react';
import FlexBox from './FlexBox';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  headingText?: string;
  children: any;
  breadcrumbs?: IBreadcrumbLinks[];
  isAdmin?: boolean;
  maxContentWidth?: number | undefined;
}

const PageContent: React.FC<Props> = ({
                                        headingText,
                                        children,
                                        maxContentWidth,
                                        isAdmin = false,
                                      }) => {
  return (
    <FlexBox id={'page-content'} flexDirection="column" my={isAdmin ? 0 : 3}>
      <FlexBox flexDirection="column" alignItems={'start'}>
        <Typography variant="h1" sx={{fontSize: 22}} mb={2}>
          {headingText}
        </Typography>
        <Box width={'100%'} maxWidth={maxContentWidth}>
          {children}
        </Box>
      </FlexBox>
    </FlexBox>
  );
};

export default PageContent;
