import React, {FC, useContext} from 'react';
import {getAuth} from 'firebase/auth';
import AppContext from '../../context/app-context';
import {useLocation, useNavigate} from 'react-router-dom';
import {defaultRouteOfSubmission, submissionRouteNames} from "../../hooks/useCreateBrowserRouter";
import {ISubmissionRoute} from "../../types/user-submissions";

export const Logout: FC<{ handleClose: any }> = ({ handleClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isGameMode, setMe } = useContext(AppContext);

  function handleSignOut(): void {
    getAuth()
      .signOut()
      .then(() => {
        setMe(null);
        handleClose();
        if (isGameMode) {
          navigate("/");
        }
        submissionRouteNames.map((route: ISubmissionRoute) => {
          if(route.path === location.pathname){
            navigate(defaultRouteOfSubmission);
          }
        })
      })
      .catch((err) => console.error(err));
  }

  return (
    <div className="popup popup--sm xs-popup ">
      <div className="popup-card popup-card--primary justify-start xs-justify-start">
        <div className="flex w-100">
          <h2 className="fs-h-7 lh-h-7 fw-700 c-primary teext-left">Log out</h2>
          <button className="btn-reset c-text-1 ml-auto" onClick={handleClose}>
            <i className="flipicon flipicon-close"></i>
          </button>
        </div>
        <div className="flex flex-col justify-center align-center xs-mb-auto xs-mt-auto xs-mb-7">
          <div className="flex flipicon flipicon--popup xs-flipicon--popup justify-center align-center text-center">
            <i className="flipicon flipicon--xxl flipicon-exit c-primary"></i>
          </div>
          <div className="flex flex-col justify-center text-center align-centers">
            <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
              Are you sure you would like to log out?
            </span>
          </div>
        </div>
        <div className="flex justify-center col-gap-4 xs-flex-col-reverse mt-auto xs-row-gap-4">
          <button
            color="transparent"
            className="btn btn--sm xs-btn btn--secondary btn--auto grow-1 xs-grow-0"
            onClick={() => handleClose()}
          >
            cancel
          </button>
          <button
            onClick={handleSignOut}
            className="btn btn--sm xs-btn btn--primary btn--auto grow-1 xs-grow-0"
          >
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};
