import {useQuery} from 'react-query';
import {getArticleById, getLatestArticles,} from '../../api/apiArticles';

export const useLatestArticles = () => {
  return useQuery('latestArticles', () =>
    getLatestArticles({
      recordsPerPage: 3,
      pageNumber: 1,
    }),
  );
};

export const useArticleById = (slug: string) => {
  return useQuery({
    queryKey: ["articleById", slug],
    queryFn: () => getArticleById(slug),
  });
};
