import React, {FC, ReactNode, useCallback, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import Dropdown from '../../common/components/Dropdown';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import {UserPersonalCard} from '../../common/types/user';
import {useDogDestructivePhrase} from '../../common/hooks/useDogDestructivePhrase';
import {useBreed} from '../../common/hooks/useBreed';
import {useCreateUpdateUserPersonalCard, usePersonalPickCard} from '../../common/hooks/useUserPersonalCard';
import {Breed} from '../../common/types/breed';
import {useTrackGoogleAnalyticsEvent} from '../../common/hooks/useTrackGoogleAnalyticsEvent';
import 'cropperjs/dist/cropper.css';
import {CropperModal} from './CropperModal';
import {signedUrlApi} from '../../api/apiAuth';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {dataURLtoFile} from '../../utils/helperUtils';
import {QueryClient} from 'react-query';
import {MixpanelEvent} from '../../common/enums/mixpanel-event';
import {mixpanelTrack} from '../../utils/mixpanel-track';
import imageCompression from 'browser-image-compression';


const initialValues: UserPersonalCard = {
    name: '',
    imgUrl: '',
    city: '',
    state: '',
    dogBreedId: 0,
    dogBreedOther: '',
    descriptivePhrase1: '',
    descriptivePhrase2: '',
    description: '',
    createdByType: 'client',
};

export const upload = (event: any): void => {
    event.preventDefault();
    document.getElementById('dog-card-form-photo-input')?.click();
};

function renderAsterisk(): ReactNode {
    return (
        <span className="c-text-3" style={{fontFamily: 'flipicons', lineHeight: 1}}>*</span>
    );
}

const getData = (data: UserPersonalCard) => {
    if (data) {
        return {
            id: data.id,
            name: data.name,
            imgUrl: data.imgUrl,
            city: data.city,
            state: data.state,
            dogBreedId: data.dogBreedId || 0,
            dogBreedOther: data.dogBreedOther,
            descriptivePhrase1: data.descriptivePhrase1,
            descriptivePhrase2: data.descriptivePhrase2,
            description: data.description,
            createdByType: data.createdByType,
            checkBox: false
        };
    }
    return initialValues;
};

const uploadToS3 = async (file: File): Promise<string> => {
    const s3Url = await signedUrlApi({
        contentType: file.type,
        fileName: file.name.slice(0, file.name.lastIndexOf('.')),
    });
    const image_url = s3Url.split('?')[0];
    await axios.put(s3Url, file, {
        headers: {
            'x-amz-acl': 'public-read',
            contentType: file.type,
        },
    });
    return image_url;
};

const reqMsg = (fieldName = 'This field'): string => `${fieldName} is required`;

const validationSchema: any = Yup.object({
    name: Yup.string().required(reqMsg()),
    city: Yup.string().required(reqMsg()),
    imgUrl: Yup.string().required(),
    state: Yup.string().required(reqMsg()),
    dogBreedId: Yup.number().required(reqMsg()),
    descriptivePhrase1: Yup.number().required(reqMsg()),
    descriptivePhrase2: Yup.number().required(reqMsg()),
    dogBreedOther: Yup.string().required(reqMsg()),
    description: Yup.string().max(75, '75 characters max'),
    checkBox: Yup.boolean().notRequired()
});

interface DogCardProps {
    id?: number;
    onClose: (data: any) => void;
}

const DogCard: FC<DogCardProps> = ({id, onClose}) => {
    const queryClient = new QueryClient();
    const formRef = useRef(null);

    const [imgToCropSrc, setImgToCropSrc] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [imageChanged, setImageChanged] = useState<boolean>(false);

    const logGoogleEvent = useTrackGoogleAnalyticsEvent();
    const {data: dogBreedData} = useBreed();
    const {data: dogDestructivePhraseData} = useDogDestructivePhrase();
    const {data: personalPickCardData} = usePersonalPickCard(id);
    const {userPersonalCardCreateUpdate} = useCreateUpdateUserPersonalCard();

    const descriptionOptions = dogDestructivePhraseData?.map((da) => ({
        value: da.id,
        label: da.name,
    })) || [];

    const values = useMemo(() => {
        if (personalPickCardData) {
            validationSchema.fields.checkBox = Yup.boolean().notRequired();
            const data = getData(personalPickCardData);
            if (!data) return;
            if (!data.dogBreedId) {
                validationSchema.fields.dogBreedOther = Yup.string().required(reqMsg());
            } else {
                validationSchema.fields.dogBreedOther = Yup.string().notRequired();
            }
            return data;
        }
        validationSchema.fields.checkBox = Yup.boolean().isTrue().required();
        return initialValues;
    }, [personalPickCardData]);


    const handleUploadToS3 = async (dataUrl: string, maxSizeMB: number = 0.5, maxWidthOrHeight: number = 800): Promise<string> => {
        const filename: string = uuidv4();
        const item: string = dataUrl.split(",")[0];
        const type: string = item!.match(/:(.*?);/)?.[1] || "";
        const file: File = await dataURLtoFile(dataUrl, filename, type);
        const compressedFile: File = await imageCompression(file, {
            maxSizeMB,
            maxWidthOrHeight,
        });
        return await uploadToS3(compressedFile);
    };

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>): void {
        if (e.target.files && e.target?.files?.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgToCropSrc(reader.result?.toString() || '');
                setImageChanged(true);
                setOpenModal(true);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const breedOptions = useMemo(() => {
        const breedOpt =
            dogBreedData?.map((b: Breed) => ({
                value: b.id,
                label: b.name,
            })) || [];
        breedOpt.unshift({
            label: 'Other',
            value: 0,
        });
        return breedOpt;
    }, [dogBreedData]);


    const onSubmit = async (values: UserPersonalCard): Promise<void> => {
        return handleUploadToS3(values.imgUrl).then((imgUrl: string) => {

            if (values.dogBreedId == 0) {
                if (id) {
                    values.dogBreedId = null;
                } else {
                    delete values.dogBreedId;
                }
            } else if (values.hasOwnProperty('dogBreedOther')) {
                if (id) {
                    values.dogBreedOther = null;
                } else {
                    delete values.dogBreedOther;
                }

            }

            userPersonalCardCreateUpdate(
                {
                    payload: {...values, imgUrl},
                    type: !!values.id ? 'update' : 'create',
                    id: values.id || 0,
                },
                {
                    onSuccess: (data: any) => {
                        mixpanelTrack(MixpanelEvent.card_created);
                        queryClient.invalidateQueries('personal-pick');
                        !values.id && logGoogleEvent('Personal Pick Card', `New Personal Pick Card generated`);
                        onClose(data?.data);
                    },
                    onError: (error) => {
                        console.error(error);
                    },
                },
            );
        }).catch((err) => console.error(err));
    };

    function renderDogCardForm(): ReactNode {
        if (!values) return null;
        return (
            <Formik
                innerRef={formRef}
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount={true}
                validateOnChange={true}
                enableReinitialize={true}
            >
                {({handleSubmit, isValid, setFieldValue, values, isSubmitting}) => (
                    <form
                        onSubmit={handleSubmit}
                        className="dog-card-form-wrap flex flex-col justify-start align-stretch"
                    >
                        <div className="dog-card-form-grid mb-20 xs-mb-6 row-gap-6 sm-col-gap-4">
                            <div className="dog-card-form-photo-wrap flex flex-col justify-start align-stretch xs-pb-6">
                                <input
                                    accept="image/*"
                                    type="file"
                                    hidden
                                    id="dog-card-form-photo-input"
                                    onChange={onSelectFile}
                                />
                                <div
                                    id="image-wrapper"
                                    className="dog-card-form-photo flex justify-center align-center mb-6 xs-mx-auto no-scroll"
                                >
                                    {!!values.imgUrl && (
                                        <img
                                            src={values.imgUrl}
                                            alt=""
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="dog-card-form-col flex flex-row justify-start align-stretch row-gap-6">
                                    {imageChanged && (
                                        <label
                                            htmlFor="dog-card-form-photo-input"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpenModal(true);
                                            }}
                                            className="btn-reset dog-card-form-btn dog-card-form-btn--pink mx-auto"
                                        >
                                            Change
                                        </label>
                                    )}
                                    <label
                                        htmlFor="dog-card-form-photo-input"
                                        onClick={upload}
                                        className="btn-reset dog-card-form-btn dog-card-form-btn--pink mx-auto"
                                    >
                                        Select Photo
                                    </label>
                                </div>
                            </div>
                            <div className="dog-card-form-col flex flex-col justify-start align-stretch row-gap-6">
                                <div className="input-wrap">
                                    <Field
                                        type="text"
                                        className="input"
                                        id="name"
                                        placeholder="1"
                                        name="name"
                                    />
                                    <label
                                        className="input-label bg-none"
                                        htmlFor="dog-name-inp"
                                    >
                                        Your Dogs Name{renderAsterisk()}
                                    </label>
                                </div>
                                <div className="input-wrap">
                                    <Field
                                        type="text"
                                        className="input"
                                        id="city"
                                        placeholder="1"
                                        name="city"
                                    />
                                    <label
                                        className="input-label bg-none"
                                        htmlFor="dog-city-inp"
                                    >
                                        City{renderAsterisk()}
                                    </label>
                                </div>
                                <div className="input-wrap">
                                    <Field
                                        type="text"
                                        className="input"
                                        id="state"
                                        placeholder="1"
                                        name="state"
                                    />
                                    <label
                                        className="input-label bg-none"
                                        htmlFor="dog-state-inp"
                                    >
                                        State{renderAsterisk()}
                                    </label>
                                </div>

                                <div className="select-z"
                                     style={{display: !values.dogBreedOther ? 'block' : 'none'}}>
                                    <Dropdown
                                        options={breedOptions}
                                        size=""
                                        label="Dog Breed"
                                        handleSelect={(d) => {
                                            if (d != 0) {
                                                validationSchema.fields.dogBreedOther = Yup.string().notRequired();
                                            } else {
                                                validationSchema.fields.dogBreedOther = Yup.string().required(reqMsg());
                                            }
                                            setFieldValue('dogBreedId', d);

                                        }}
                                        name="dogBreedId"
                                        id="dogBreedId"
                                        val={values.dogBreedId}
                                    />
                                </div>
                                <div className="input-wrap"
                                     style={{display: values.dogBreedId == 0 ? 'block' : 'none'}}>
                                    <Field
                                        type="text"
                                        className="input"
                                        id="breed-other"
                                        placeholder="1"
                                        name="dogBreedOther"
                                    />
                                    <label
                                        className="input-label bg-none"
                                        htmlFor="dog-other-breed-inp"
                                    >
                                        Other Breeds and Mixed Breeds
                                    </label>
                                </div>

                            </div>


                            <div className="dog-card-form-col flex flex-col justify-start align-stretch row-gap-6">
                                <div className="select-z">
                                    <Dropdown
                                        options={descriptionOptions}
                                        size=""
                                        label="Select Description 1"
                                        handleSelect={(d) =>
                                            setFieldValue('descriptivePhrase1', d)
                                        }
                                        name="descriptivePhrase1"
                                        id="descriptivePhrase1"
                                        val={values.descriptivePhrase1}
                                    />
                                </div>
                                <div className="select-z">
                                    <Dropdown
                                        options={descriptionOptions}
                                        size=""
                                        label="Select Description 2"
                                        handleSelect={(d) =>
                                            setFieldValue('descriptivePhrase2', d)
                                        }
                                        name="descriptivePhrase2"
                                        id="descriptivePhrase2"
                                        val={values.descriptivePhrase2}
                                    />
                                </div>
                                <div className="input-wrap">
                                    <div className="input-error-text">
                          <span className="fs-md lh-md">
                            (75 characters max)
                          </span>
                                    </div>
                                    <Field name="description">
                                        {({form}: any) => (
                                            <textarea
                                                {...form.getFieldProps('description')}
                                                className="input"
                                                id="dog-desc-text-inp"
                                                placeholder="1"
                                                maxLength={75}
                                                rows={4}
                                            ></textarea>
                                        )}
                                    </Field>
                                    <label
                                        className="input-label"
                                        htmlFor="dog-desc-text-inp"
                                    >
                                        Optional: Share something fun! Like what your dog
                                        loves or how they get into trouble.
                                    </label>
                                </div>
                            </div>
                        </div>
                        {!id && (
                            <label className="checkbox-wrap cursor-pointer mb-12 xs-mb-6">
                                <Field
                                    type="checkbox"
                                    className="checkbox-input"
                                    id="breed-other"
                                    hidden
                                    placeholder="1"
                                    name="checkBox"
                                />
                                <span
                                    className="checkbox-icon-holder flipicon flipicon--xs sm-flipicon--md xs-flipicon"></span>
                                <span className="checkbox-text fs-xl lh-xl xs-fs-md xs-lh-sm ml-4 xs-ml-2">
                        I consent to my submitted data being collected and
                        stored. I understand that my dog’s photos or videos may
                        be selected to be featured as a Pup Star or on the
                        platform. View our{' '}
                                    <Link
                                        target="_blank"
                                        to="/privacy-policy"
                                        className="underline"
                                    >
                          Privacy Policy here
                        </Link>
                      </span>
                            </label>
                        )}
                        <div className="flex justify-end xs-justify-center align-center">
                            <button
                                type="submit"
                                disabled={isSubmitting || !isValid}
                                className={`btn-reset dog-card-form-btn dog-card-form-btn--blue px-14 xs-w-100 ${
                                    (isSubmitting || !isValid) ? 'btn--disabled' : ''
                                }`}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }

    const onCropComplete = useCallback(
        (url: string): void => {
            if (!formRef?.current) return;
            (formRef.current as any).setFieldValue('imgUrl', url);
            setOpenModal(false);
        },
        [formRef?.current, setOpenModal],
    );


    return (
        <div
            className="dog-card-wrapper flex flex-col justify-start align-stretch fixed left-0 top-0 w-100 h-100 py-20 px-20 xs-py-6 xs-px-6">
            {openModal && (
                <CropperModal imgSrc={imgToCropSrc} setOpenModal={setOpenModal} onCropComplete={onCropComplete}/>)}
            <div className="container flex flex-col justify-start align-stretch grow-1">
                <div className="dog-card-header flex justify-between align-start mb-20 xs-mb-6">
                    <button
                        onClick={() => onClose({})}
                        className="btn-reset dog-card-icon grow-0 shrink-0 fixed left-0 top-0 mt-6 ml-6 mr-4 z-1 xs-static xs-mt-1 xs-ml-0"
                    >
                        <img
                            className="img img--square"
                            src="images/dog-card-back.webp"
                            alt=""
                        />
                    </button>
                    <h2
                        className="dog-card-title text-uppercase text-center mx-auto"
                        data-text={`${id ? 'Edit' : 'Create'} my dog’s card`}
                    >{`${id ? 'Edit' : 'Create'} my dog’s card`}</h2>
                    <button className="btn-reset dog-card-icon absolute xs-static grow-0 shrink-0 ml-4 xs-mt-1"/>
                </div>
                <div
                    className="dog-card-body flex flex-col justify-start align-stretch py-12 px-12 sm-py-6 sm-px-6 xs-py-4 xs-px-4 mt-auto mb-auto">
                    <h3 className="dog-card-form-title fs-h-7 lh-h-7 mb-6">
                        Your Dog’s Photo{renderAsterisk()}
                    </h3>
                    {renderDogCardForm()}
                </div>
            </div>
        </div>
    );
};

export default DogCard;
