import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import Modal from 'react-modal';
import Signup from './modalContent/Signup';
import Login from './modalContent/Login';
import CheckEmail from './modalContent/CheckEmail';
import Success from './modalContent/Success';
import AddPhoto from './modalContent/AddPhoto';
import {ClientMe} from '../types/user';
import {Logout} from './modalContent/Logout';
import {FlagReason} from './modalContent/FlagReason';
import AppContext from '../context/app-context';

export interface ModalData {
  authStep: Popup;
  options?: {
    email?: string;
    id?: number;
    closeHandler?: Function;
    redirectUrl?: string;
    postLoggedInEventToGame?: boolean;
  }
}

export enum Popup {
  Signup = 1,
  Login,
  CheckEmail,
  Success,
  AddPhoto,
  Logout,
  FlagComment
}

interface AuthModalProps {
  isOpen: boolean;
  handleClose: () => void;
  onAfterImageUpload: () => void;
  step?: Popup;
  me?: ClientMe;
}

const AuthModal: FC<AuthModalProps> = ({
  isOpen,
  handleClose,
  step,
}) => {
  const [currentStep, setCurrentStep] = useState<Popup>();
  const {me} = useContext(AppContext);
  useEffect(() => {
    step && setCurrentStep(step);
  }, [setCurrentStep, step]);

  const getOtherTemplates = useCallback(() => {
    switch (currentStep) {
      case Popup.Logout: {
        return <Logout handleClose={handleClose}/>
      }
      case Popup.FlagComment: {
        return <FlagReason handleClose={handleClose}/>
      }
      default: {
        break;
      }
    }
  }, [currentStep, handleClose]);

  const getCurrentStepTemplate = useCallback(() => {
    switch (currentStep) {
      case Popup.Signup: {
        return (
          <Signup
            handleClose={handleClose}
            loginButtonClick={() => {
              setCurrentStep(Popup.Login);
            }}
            onAfterSubmit={() => setCurrentStep(Popup.CheckEmail)}
          />
        );
      }
      case Popup.Login: {
        return (
          <Login
            handleClose={handleClose}
            signupButtonClick={() => {
              setCurrentStep(Popup.Signup);
            }}
          />
        );
      }
      case Popup.CheckEmail: {
        return <CheckEmail handleClose={handleClose} />;
      }
      case Popup.Success: {
        return (
          <Success handleContinue={() => setCurrentStep(Popup.AddPhoto)} />
        );
      }
      case Popup.AddPhoto: {
        if (!me) return <></>
        return (
          <AddPhoto
            me={me}
            handleClose={handleClose}
          />
        );
      }
    }
  }, [currentStep, handleClose]);

  return (
    <Modal
      shouldReturnFocusAfterClose={false}
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Example Modal"
      className="popup xs-popup xs-popup--full"
    >
      {getOtherTemplates() ?? (<div className="popup-card popup-card--primary justify-center xs-justify-start">
        {getCurrentStepTemplate()}
      </div>)}
    </Modal>
  );
};

export default AuthModal;
