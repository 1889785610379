import React, {useContext, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import appContext from '../../../common/context/app-context';
import AppContext from '../../../common/context/app-context';
import {Popup} from '../../../common/components/AuthModal';
import {MixpanelEvent} from '../../../common/enums/mixpanel-event';
import {mixpanelTrack} from '../../../utils/mixpanel-track';
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const HowToPlay = () => {
  const {setShowAuthPopup} = useContext(appContext);
  const {me} = useContext(AppContext);
  const navigate = useNavigate();

  function handleSignUpClick(): void {
    if (!me) {
      setShowAuthPopup({authStep: Popup.Signup});
    } else {
      navigate('/');
    }
  }

  function scrapbookHandler(): void {
    if (!me) {
      setShowAuthPopup({authStep: Popup.Signup});
    } else {
      mixpanelTrack(MixpanelEvent.scrapbook_viewed);
      navigate('/scrap-book?tab=personal-picks');
    }
  }

  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/how-to-play', 'how to play page')

  }, []);

  return (
    <div className="container xs-container--sm">
      <div className="flex flex-col w-100 justify-start align-start xs-px-6 sm-px-6 mb-7">
        <h2 className="fs-h-3 lh-h-3 fw-700 c-text-black xs-fs-h-6 xs-lh-h-6 mb-6 xs-mb-4">
          How to Play
        </h2>
        <div className="flex flex-col w-100 justify-start align-start">
            <span className="fs-lg lh-xl fw-700 c-text-4 xs-fs-sm xs-lh-sm mb-6">
              Answer questions correctly to score big points!
            </span>
          <ul className="flex flex-col list fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-6 pl-6">
            <li className="list-item">
              Your points will earn Pup Star cards featuring awesome dogs from
              all the players in our community. ( {' '}
              <Link
                to="/submit-dog-step-1"
                state={{showAuthPopup: true}}
                className="fs-lg lh-xl fw-500 c-primary cursor-pointer xs-fs-sm xs-lh-sm underline"
              >
                Submit your dog!
              </Link> )
            </li>
            <li className="list-item">
              Earn Pup Star cards and fill up the albums in your scrapbook.
            </li>
            <li className="list-item">
              Play daily to find the rare 3-star cards and unlock new albums.
            </li>
            <li className="list-item">
              A new game is available every day at midnight.
            </li>
          </ul>

          <span className="fs-lg lh-xl fw-700 c-text-4 xs-fs-sm xs-lh-sm mb-6">
              Your total points convert to Pup Star cards at the end of the
              game.
            </span>

          <span className="fs-lg lh-xl fw-700 c-text-4 xs-fs-sm xs-lh-sm mb-6">
              Grab Your Personal Pick cards, too!
            </span>
          <ul className="flex flex-col list fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-6 pl-6">
            <li className="list-item">
              The game features different dogs submitted by our community
              every day.
            </li>
            <li className="list-item">
              At the end of each game you can collect cards of your favorites,
              we call these Personal Picks, no points needed.
            </li>
            <li className="list-item">
              Be sure to {' '}
              <a onClick={scrapbookHandler}
                 className="fs-lg lh-xl fw-500 c-primary xs-fs-sm xs-lh-sm underline">
                make a shareable Personal Pick card</a> {' '}
              of your own dog!
              They could become our next Pup Star.
            </li>
          </ul>

          <span className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-6">
              A new game is available every day at midnight.
            </span>
          <span className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4">
              <a
                onClick={handleSignUpClick}
                className="fs-lg lh-xl fw-500 cursor-pointer c-primary hover-link xs-fs-sm xs-lh-sm underline"
              >
                Sign up
              </a>{" "}
            to make sure your stats are saved.
            </span>
          <span className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4">
              <Link
                to="/submit-dog-step-1"
                state={{showAuthPopup: true}}
                className="fs-lg lh-xl fw-500 c-primary cursor-pointer xs-fs-sm xs-lh-sm underline"
              >
                Submit your dog here.
              </Link>{' '}
            The best dog photos are chosen to appear in the game.
            </span>
          <span className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4">
              Our team is made up of dog lovers like you. {' '}
            <a
              href="mailto:flipthebreed@talkdoginc.com"
              className="fs-lg lh-xl fw-500 c-primary xs-fs-sm xs-lh-sm underline"
            >
                Email us with feedback anytime.
              </a>
            </span>
        </div>
      </div>
    </div>
  );
};

export default HowToPlay;
