import { useQuery } from "react-query";
import { getSubTopics, getTopics } from "../../api/apiTopics";

export const useTopics = () => {
  return useQuery("topics", () =>
    getTopics({
      recordsPerPage: 10,
      pageNumber: 1,
    }),
  );
};

export const useSubTopics = (slug: string) => {
  return useQuery("subTopics", () => getSubTopics(slug));
};
