import React from "react";
import Box from "@mui/material/Box";

interface Props {
  children: any;
  flexDirection?: "row" | "column";
  alignItems?: string;
  justifyContent?: string;
  flexGrow?: number;
  [x: string]: any;
}

const FlexBox: React.FC<Props> = ({
  children,
  flexDirection = "row",
  alignItems = "center",
  justifyContent = "space-evenly",
  flexGrow = 0,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: alignItems,
        justifyContent: justifyContent,
        flexDirection,
        width: "100%",
        flexGrow: flexGrow,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default FlexBox;
