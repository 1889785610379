import React, {useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {GUEST_ID_API, GUEST_TOKEN_KEY, guestLoginApi} from "../../api/apiAuth";
import {getAuth} from "firebase/auth";
import AppContext from "../context/app-context";

interface Props {
  currentStep: number;
  stepsCount: number;
}

const steps: number[] = [1, 2, 3, 4, 5];

const SubmitDogProgressBar: React.FC<Props> = ({ currentStep, stepsCount }) => {
  const {me: user} = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const initGuest = async () => {
      if ((!localStorage.getItem(GUEST_TOKEN_KEY) || !localStorage.getItem(GUEST_ID_API)) && !user) {
        const guestToken = await guestLoginApi();
        localStorage.setItem(GUEST_ID_API, guestToken.id);
        localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);
      }
    };

    getAuth()
      .authStateReady()
      .then(() => initGuest());

  }, [user]);

  const clickHandler = (prevStep: number) => {
    navigate(`/submit-dog-step-${prevStep}`);
  };

  return (
    <div className="flex grow-1 shrink-1 justify-end ml-auto sm-w-100 sm-mb-4">
      <div className="form-nav w-100">
        {steps.map((step: number) => (
          <button
            key={step}
            className={`btn-reset form-nav-btn ${
              currentStep === step && "form-nav-btn--active"
            }`}
            disabled={stepsCount + 1 < step}
            onClick={() => clickHandler(step)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default SubmitDogProgressBar;
