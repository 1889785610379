import {createBrowserRouter} from 'react-router-dom';
import BlogRoot from '../../pages/BlogRoot';
import ForgotPasswordPage from '../../pages/auth/ForgotPasswordPage';
import ResetPasswordPage from '../../pages/auth/ResetPasswordPage';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import TermsAndConditions from '../../pages/TermsAndConditions';
import NotFound from '../../pages/NotFound';
import React from 'react';
import HomePage from '../../pages/story-center/home/HomePage';
import AboutUs from '../../pages/story-center/about-us/AboutUs';
import HowToPlay from '../../pages/story-center/how-to-play/HowToPlay';
import Topics from '../../pages/story-center/topics/Topics';
import Feedback from '../../pages/story-center/feedback/Feedback';
import SubTopics from '../../pages/story-center/topics/SubTopics';
import Article from '../../pages/story-center/articles/Article';
import AuthGuard from '../components/AuthGuard';
import SubmitDogStepOne from '../../pages/story-center/submit-my-dog/submit-dog-step-one';
import SubmitDogStepTwo from '../../pages/story-center/submit-my-dog/submit-dog-step-two';
import SubmitDogStepThree from '../../pages/story-center/submit-my-dog/submit-dog-step-three';
import SubmitDogStepFour from '../../pages/story-center/submit-my-dog/submit-dog-step-four';
import SubmitDogStepFive from '../../pages/story-center/submit-my-dog/submit-dog-step-five';
import SubmitDogFinalPage from '../../pages/story-center/submit-my-dog/submit-dog-final-page';
import CommunityGuidelines from '../../pages/story-center/community-guidelines/CommunityGuidelines';
import {ScrapBook} from '../../pages/puzzle/ScrapBook';
import Game from '../../pages/story-center/game/Game';
import QuickCourses from "../../pages/story-center/quick-courses/QuickCourses";
import DailyReminderEmails from "../../pages/story-center/daily-reminder-emails/DailyReminderEmails";
import FacebookDataDeletion from "../../pages/story-center/facebook-data-deletion/FacebookDataDeletion";
import {ISubmissionRoute} from "../types/user-submissions";

export const defaultRouteOfSubmission: string = "submit-dog-step-1";
export const submissionRouteNames: ISubmissionRoute[] = [
  {
    path: "/submit-dog-step-2",
    element: <SubmitDogStepTwo />
  },
  {
    path: "/submit-dog-step-3",
    element: <SubmitDogStepThree />
  },
  {
    path: "/submit-dog-step-4",
    element: <SubmitDogStepFour />
  },
  {
    path: "/submit-dog-step-5",
    element: <SubmitDogStepFive />
  }
];

const useCreateBrowserRouter = () => {
  return createBrowserRouter([
    {
      path: '/',
      element: <BlogRoot/>,
      children: [
        {
          path: '/',
          element: <HomePage/>,
        },
        {
          path: "/forgot-password",
          element: <ForgotPasswordPage />,
        },
        {
          path: "/reset-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "/facebook-data-deletion-instructions",
          element: <FacebookDataDeletion />,
        },
        {
          path: "/topics",
          element: <Topics />,
        },
        {
          path: "/topics/:slug",
          element: <SubTopics />,
        },
        {
          path: "/article/:id",
          element: <Article />,
        },
        {
          path: "/course/:slug",
          element: <QuickCourses />,
        },
        {
          path: "/daily-reminders",
          element: <DailyReminderEmails />,
        },
        {
          path: `/${defaultRouteOfSubmission}`,
          element: <SubmitDogStepOne />,
        },
        ...submissionRouteNames,
        {
          path: "/",
          element: <AuthGuard />,
          children: [
            {
              path: "/scrap-book",
              element: <ScrapBook />,
            }
          ],
        },
        {
          path: "/game",
          element: <Game />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/community-guidelines",
          element: <CommunityGuidelines />,
        },
        {
          path: "/how-to-play",
          element: <HowToPlay />,
        },
        {
          path: "/feedback",
          element: <Feedback />,
        },
        {
          path: "submit-dog-final",
          element: <SubmitDogFinalPage />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
};

export default useCreateBrowserRouter;
