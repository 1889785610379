import { ErrorMessage, Field, Formik } from "formik";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthMutation } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { SocialLogin } from "./SocialLogin";
import AppContext from "../../context/app-context";
import { guestUserTransfer } from "../../../utils/helperUtils";
import { useTrackGoogleAnalyticsEvent } from "../../hooks/useTrackGoogleAnalyticsEvent";
import { getErrorMessageByCode } from "../../../utils/errorResponses";
import {GUEST_ID_API, GUEST_TOKEN_KEY} from "../../../api/apiAuth";
import {mixpanelTrack} from "../../../utils/mixpanel-track";
import {MixpanelEvent} from "../../enums/mixpanel-event";

interface LoginProps {
  handleClose: () => void;
  signupButtonClick: () => void;
}

const initialValues = {
  email: "",
  password: "",
};

const reqMsg = (fieldName = "This field"): string => `${fieldName} is required`;

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required(reqMsg()),
  password: Yup.string().required(reqMsg()),
});
const Login: FC<LoginProps> = ({ handleClose, signupButtonClick }) => {
  const formRef = useRef(null);
  const { login, isLoadingMutation } = useAuthMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const { showAuthPopup } = useContext(AppContext);
  const navigate = useNavigate();
  const logGoogleEvent = useTrackGoogleAnalyticsEvent();

  useEffect(() => {
    mixpanelTrack(MixpanelEvent.login_opened);
  }, []);

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // if (!getAuth().currentUser?.emailVerified) {
        //   getAuth().signOut();
        //   return Promise.reject();
        // }
        return guestUserTransfer();
      })
      .then(() => {
        login(
          {
            payload: { isAdmin: false },
          },
          {
            onSuccess: () => {
              mixpanelTrack(MixpanelEvent.account_logged_in);
              const redirectUrl = showAuthPopup?.options?.redirectUrl;
              const postLoggedInEventToGame = showAuthPopup?.options?.postLoggedInEventToGame;
              localStorage.removeItem(GUEST_TOKEN_KEY);
              localStorage.removeItem(GUEST_ID_API);
              if (postLoggedInEventToGame) {
                window?.postMessage(
                  {data: { LoggedIn: true }, type: 'loggedInEventToGame'},
                  '*');
              }
              if (redirectUrl) {
                setTimeout(() => {
                  navigate(-1);
                }, 600);
              }
              handleClose();
            },
            onError: (error) => {
              logGoogleEvent(
                "login-fail",
                getErrorMessageByCode((error as any)?.code),
              );
              setErrorMessage(
                "We couldn’t find that account. Please try again or Sign Up for a new account now",
              );
            },
          },
        );
      })
      .catch((error) => {
        logGoogleEvent(
          "login-fail",
          getErrorMessageByCode((error as any)?.code),
        );
        setErrorMessage(
          "We couldn’t find that account. Please try again or Sign Up for a new account now",
        );
      });
  };

  useEffect(() => {
    if (!formRef?.current) return;
    (formRef.current as any).setFieldValue(
      "email",
      showAuthPopup?.options?.email ?? "",
    );
  }, [showAuthPopup, formRef]);

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <form
            className="flex flex-col w-100 justify-center"
            onSubmit={handleSubmit}
          >
            <div className="flex justify-between mb-12 xs-mb-6">
              <h2 className="fs-h-7 lh-h-7 fw-700 c-primary ">Log In</h2>
              <button
                className="btn-reset c-text-1"
                type="button"
                onClick={handleClose}
              >
                <i className="flipicon flipicon-close"></i>
              </button>
            </div>
            <div className="input-wrap input-wrap--sm w-100 mb-3">
              <div className="input-error-text">
                <ErrorMessage name="email" component="span" />
              </div>
              <Field
                type="text"
                className="input"
                id="email"
                placeholder="1"
                name="email"
              />
              <label className="input-label" htmlFor="email">
                Email<span className="c-text-3">*</span>
              </label>
            </div>
            <div className="input-wrap input-wrap--sm w-100 mb-3">
              <div className="input-error-text">
                <ErrorMessage name="email" component="span" />
              </div>
              <Field
                type="password"
                className="input"
                id="password"
                placeholder="1"
                name="password"
              />
              <label className="input-label" htmlFor="password">
                Password<span className="c-text-3">*</span>
              </label>
            </div>
            <button
              className="btn btn--sm xs-btn--full btn--primary ml-auto mb-3 xs-w-100"
              type="submit"
              disabled={isLoadingMutation}
            >
              Log In
            </button>
            <div className="input-error-text text-center mb-3">
              <span>{errorMessage}</span>
            </div>
            <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
              Forgot your password?{" "}
              <Link
                className="fs-md xs-fs-sm lh-md xs-lh-sm fw-700 c-primary"
                onClick={() => handleClose()}
                to="/forgot-password"
              >
                Reset
              </Link>{" "}
            </span>
          </form>
        )}
      </Formik>
      <div className="flex flex-col w-100 justify-center mt-auto xs-mt-6">
        <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4 text-center mb-6">
          Or Log In with..
        </span>
        <SocialLogin onSuccess={handleClose}></SocialLogin>
        <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
          Don't have an account?{" "}
          <a
            className="fs-md xs-fs-sm lh-md xs-lh-sm fw-700 c-primary cursor-pointer"
            onClick={signupButtonClick}
          >
            Sign up
          </a>
        </span>
      </div>
    </>
  );
};

export default Login;
