import {getRequest, postRequest, putRequest, toQueryString} from './request';
import {Article} from '../common/types/articles';

export const getLatestArticles = async (params?: any): Promise<Article[]> => {
  return await getRequest<Article[]>(
    `articles/trending${
      params
        ? '?' +
        toQueryString(params.search ? {search: params.search} : params)
        : ''
    }`,
  ).then((res) => res.data);
};

export const getArticleById = async (slug?: string): Promise<Article> => {
  return await getRequest<Article>(`articles/${slug}`).then((res) => res.data);
};

export const flagComment = async (id: number, reason: string): Promise<any> => {
  return await postRequest<any>(`comments/${id}/flag`, {reason}).then((res) => res.data);
};

export const articleView = async (slug: string): Promise<any> => {
  return await putRequest<any>(`articles/${slug}/views`, {});
}
