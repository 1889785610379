import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

interface Props {}

const NotFound: React.FC<Props> = () => {
  return (
    <Box my={4}>
      <Typography variant={"h1"}>Page Not Found</Typography>
      <Typography variant={"body1"}>
        The page you're looking for does not exist
      </Typography>
    </Box>
  );
};

export default NotFound;
