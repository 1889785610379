import ReactGA4 from "react-ga4";

export const useTrackGoogleAnalyticsEvent = () => {
    return (category: string, action: string) => {
        ReactGA4.event({
            category: category,
            action: action,
        });
    };
};


export const useTrackGoogleAnalyticsEventPages = (pageValue:string='/',pageTitle:string='home'):void => {
    ReactGA4.send({ hitType: "pageview", page: pageValue, title: pageTitle });

};
