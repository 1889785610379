import React, {useContext} from 'react';
import {getAuth, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider} from 'firebase/auth';
import {useAuthMutation} from '../../hooks/useAuth';
import AppContext from '../../context/app-context';
import {useNavigate} from 'react-router-dom';
import {GUEST_ID_API, GUEST_TOKEN_KEY} from '../../../api/apiAuth';
import {guestUserTransfer} from '../../../utils/helperUtils';
import {mixpanelTrack} from "../../../utils/mixpanel-track";
import {MixpanelEvent} from "../../enums/mixpanel-event";

const providerGoogle = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();

interface Props {
    onSuccess: () => void;
}
export const SocialLogin: React.FC<Props> = (props) => {
    const { login } = useAuthMutation();
    const { showAuthPopup} = useContext(AppContext);
    const navigate = useNavigate();
    const socialLogin = async (provider: any  ) => {
        const auth = getAuth();
        const result = await signInWithPopup(auth, provider);
        if (result) {
            // This is the signed-in user
            // const user = result.user;
            await guestUserTransfer();
            login(
                {
                    payload: { isAdmin: false },
                },
                {
                    onSuccess: async () => {
                        mixpanelTrack(MixpanelEvent.account_logged_in);
                        const redirectUrl = showAuthPopup?.options?.redirectUrl;
                        const postLoggedInEventToGame = showAuthPopup?.options?.postLoggedInEventToGame;
                        localStorage.removeItem(GUEST_TOKEN_KEY);
                        localStorage.removeItem(GUEST_ID_API);
                        if (postLoggedInEventToGame) {
                            window?.postMessage(
                              {data: { LoggedIn: true }, type: 'loggedInEventToGame'},
                              '*');
                        }
                        if (redirectUrl) {
                            setTimeout(() => {
                                navigate(-1);
                            }, 600)
                        }
                        props.onSuccess();
                    },
                },
            );
        }
    }
    return <>
        <div className="flex flex-row justify-between col-gap-6 xs-col-gap-4 mb-6">
            <button className="btn btn--sm xs-btn btn--outline justify-between w-100" onClick={() => socialLogin(providerGoogle)} type="button">
                <i className="flipicon flipicon-google-color">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                </i>
                <span className="block mx-2">Google</span>
                <i className="flipicon"></i>
            </button>
            <button className="btn btn--sm xs-btn btn--outline justify-between w-100" type="button" onClick={() => socialLogin(providerFacebook)}>
                <i className="flipicon flipicon-facebook-color">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                </i>
                <span className="block mx-2">Facebook</span>
                <i className="flipicon"></i>
            </button>
        </div>
    </>
}

