import React, {useEffect} from 'react';
import {useTrackGoogleAnalyticsEventPages} from "../common/hooks/useTrackGoogleAnalyticsEvent";

interface Props {}

const TermsAndConditions: React.FC<Props> = () => {

  useEffect(() => {
    useTrackGoogleAnalyticsEventPages('/terms-and-conditions', 'terms and conditions page')

  }, []);
  return (
    <>
      <div className="container xs-container--sm">
        <div className="flex flex-col w-100 mt-6 sm-mt-5 xs-mt-4 xs-px-6 sm-px-5 xs-mb-7 sm-mb-7 align-start fs-lg lh-lg sm-fs-md sm-lh-md xs-fs-sm xs-lh-sm fw-500 c-text-4">
          <h2 className="fs-h-3 lh-h-3 c-text-2 sm-fs-h-5 sm-lh-h-5 xs-fs-h-6 xs-lh-h-6 fw-700 mb-6 sm-mb-5 xs-mb-4">
            Talk Dog, Inc.™ Mobile Application End-User License Agreement and
            Terms and Conditions
          </h2>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            Thank you for accessing the Talk Dog, Inc.™ online and mobile
            platforms (the “<span className="underline">Venue</span>”), as made
            available on the internet and/or the Apps (as defined below). The
            Venue and Apps are provided by Talk Dog, Inc. (collectively, “
            <span className="underline">Talk Dog™,</span>” "
            <span className="underline">we,</span>” “
            <span className="underline">our</span>” or “
            <span className="underline">us</span>”). The following Talk Dog™
            Mobile Application End-User License Agreement and Terms and
            Conditions (“<span className="underline">Terms</span>”) are
            inclusive of the Talk Dog™ Privacy Policy ( “
            <span className="underline">Privacy Policy</span>”) and any and all
            other applicable operating rules, policies and other supplemental
            terms and conditions or documents that may be published from time to
            time, which are expressly incorporated herein by reference
            (collectively, the “<span className="underline">Agreement</span>”).
            Each end-user visitor who downloads the Apps and/or accesses the
            Venue (“<span className="underline">user,</span>” “
            <span className="underline">you</span>” or “
            <span className="underline">your</span>”) agrees to the terms of the
            Agreement, in their entirety, when she/he: (a) accesses or uses the
            Venue; (b) downloads, attempts to download and/or uses the Talk Dog,
            Inc.™ mobile applications (the “
            <span className="underline">Apps</span>”) and associated Software
            (as defined below), as made available on the iPhone®, iPad® and
            iPod Touch®, as well as Android® and other mobile devices
            (collectively, “<span className="underline">Mobile Devices</span>”),
            by and through the Apple® Apps Store, the Google Play® store, and
            other applicable venues (collectively, “
            <span className="underline">Download Venues</span>”); (c) accesses
            and/or downloads any of the: (i) academic material, text, audio,
            video, photographs, graphics, artwork and/or other content featured
            by and through the Apps and Venue (collectively, “
            <span className="underline">Information Content</span>”); and/or
            (ii) links to third party websites, products and/or services
            featured by and through the Apps and Venue (“
            <span className="underline">Third-Party Links,</span>” and together
            with the Informational Content, the “Content”); and/or (d) utilizes
            the comment functionality and other interactive features of the Apps
            and/or Venue (collectively, “
            <span className="underline">Interactive Services</span>” and
            together with the Venue, Apps and Content, the “
            <span className="underline">Talk Dog, Inc.™</span>” “
            <span className="underline">Offerings</span>” ).
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 uppercase fw-700">
            please review the terms of the agreement carefully. if a user does
            not agree with the terms of the agreement in their entirety, that
            user is not authorized to use the talk dog™ offerings in any manner
            or form.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 uppercase fw-700">
            the agreement contains disclaimers of warranties, limitations of
            liability, releases, a class-action waiver, and the requirement to
            arbitrate any and all claims that may arise hereunder against talk
            dog, inc.™, as well as its parent, subsidiaries, related parties,
            third-party instructors, third-party service providers and marketing
            partners (collectively, “covered parties”), who are express
            third-party beneficiaries of the mandatory arbitration provision.
            the aforementioned provisions are an essential basis of the
            agreement.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 uppercase fw-700">
            new jersey state residents are encouraged to review their rights
            under the agreement, as provided under the new jersey
            truth-in-consumer contract warranty and notice act (“tccwna”).
          </p>
          <i className="mb-6 sm-mb-6 xs-mb-4 uppercase">
            Apple®, iTunes®, iPhone®, iPad® and iPod Touch® are registered
            trademarks of Apple, Inc. (“Apple”). Android®, Google® and Google
            Play® are registered trademarks of Google, Inc. (“
            <span className="underline">Google</span>”). Please be advised that
            Talk Dog, Inc.™ is not in any way affiliated with Apple, BuddyBoss
            or Google, nor are the Talk Dog, Inc.™ Offerings endorsed,
            administered or sponsored by any of the aforementioned entities.
          </i>
          <p className="mb-6 sm-mb-6 xs-mb-4 fw-700">Disclaimers</p>
          <p className="mb-6 sm-mb-6 xs-mb-4 fw-700">
            The Content is offered for informational purposes only. Talk Dog,
            Inc.™ does not represent or warrant that the Content and other
            information posted by and through the Talk Dog, Inc.™ Offerings is
            accurate, complete, up-to-date or appropriate. You may find certain
            Content to be outdated, harmful, inaccurate and/or deceptive. Please
            use caution, common sense and safety when using the Content.
            Reliance on any information made available to you by and through the
            Talk Dog, Inc.™ Offerings is solely at your own risk. Talk Dog,
            Inc.™ disclaims any and all liability for any damage or injury
            based on the Content and/or other information directly or indirectly
            obtained through the Talk Dog, Inc.™ Offerings.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">1. Scope; Modification of Agreement.</span>{" "}
            The Agreement constitutes the entire and only agreement between
            users and Talk Dog, Inc.™ with respect to users’ use of the Talk
            Dog, Inc.™ Offerings, and supersedes all prior or contemporaneous
            agreements, representations, warranties and/or understandings with
            respect to same. We may amend the Agreement from time to time in our
            sole discretion, without specific notice to you; provided, however,
            that any amendment or modification to the arbitration provisions,
            prohibition on class action provisions or any other provisions
            applicable to dispute resolution (collectively, “Dispute Resolution
            Provisions”) shall not apply to any disputes incurred prior to the
            applicable amendment or modification. The Agreement will be
            available in the Apps and included in the email message sent to you
            inviting you to join the Venue. Any updates to the Agreement will be
            sent to you via email. You should review any updates to the
            Agreement prior to using the Talk Dog, Inc.™ Offerings. By your
            continued use of the Talk Dog, Inc.™ Offerings, you hereby agree to
            comply with, and be bound by, all of the terms and conditions
            contained within the Agreement effective at that time (other than
            with respect to disputes arising prior to the amendment or
            modification of the Dispute Resolution Provisions which shall be
            governed by the Dispute Resolution Provisions then in effect at the
            time of the subject dispute).
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">
              2. Requirements; Termination of Access to the Talk Dog, Inc.™
              Offerings; Necessary Equipment.
            </span>{" "}
            The Talk Dog, Inc.™ Offerings are available only to individuals
            who: (a) are over eighteen (18) years of age (or the applicable age
            of majority, if greater than eighteen (18) years of age in their
            respective jurisdictions of residence); and (b) can enter into
            legally binding contracts under applicable law (collectively, “
            <span className="underlines">Usage</span>”). The Talk Dog, Inc.™
            Offerings are not intended for individuals who do not satisfy the
            Usage Requirements, including individuals under eighteen (18) years
            of age (or the applicable age of majority, if greater than eighteen
            (18) years of age in their respective jurisdictions of residence)
            and/or individuals who cannot enter into legally binding contracts
            under applicable law. If a user does not satisfy the Usage
            Requirements in their entirety, that user does not have permission
            to access or use the Talk Dog, Inc.™ Offerings.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            To the extent permitted by applicable law, Talk Dog, Inc.™ may
            terminate your right to access the Talk Dog, Inc.™ Offerings at any
            time where you: (i) are in any way in breach of the Agreement; (ii)
            are engaged in any improper conduct in connection with the Talk Dog,
            Inc.™ Offerings; and/or (iii) are, at any time, conducting any
            unauthorized commercial activity by or through your use of the Talk
            Dog, Inc.™ Offerings.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            You are responsible, at all times, for ensuring that you have an
            Internet connection, computer/Mobile Device, up-to-date Internet
            browser versions, a functioning e-mail account, applicable software,
            applicable hardware and/or other equipment necessary to access the
            Talk Dog, Inc.™ Offerings. Talk Dog, Inc.™ does not guarantee the
            quality, speed or availability of the Internet connection associated
            with your Mobile Device and/or computer. Talk Dog, Inc.™ does not
            guarantee that the Talk Dog, Inc.™ Offerings can be accessed: (A)
            on all Mobile Devices; (B) through all wireless service plans; (C)
            in connection with all Internet browsers; or (D) in all geographical
            areas. Standard messaging, data and wireless access fees may apply
            to your use of the Talk Dog, Inc.™ Offerings through your wireless
            device. You are fully responsible for all such charges and Talk Dog,
            Inc.™ has no liability or responsibility to you, whatsoever, for
            any such charges billed by your wireless carrier.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            In addition to the foregoing, individuals are not permitted to
            access the Talk Dog, Inc.™ Offerings with “
            <span className="underline">Jail-Broken Mobile Devices.</span>” For
            purposes of the Agreement, a Jail-Broken Mobile Device is a Mobile
            Device that runs: (I) Apple’s® iOS operating system (including the
            iPhone®, iPod touch®, iPad® and second-generation Apple TV®)
            that has had any of its iOS-based software restrictions removed via
            any procedure that is not fully endorsed and authorized by Apple®;
            and/or (II) the Android® operating system that has had any of its
            Android®-based software restrictions removed via any procedure that
            is not fully endorsed and authorized by the applicable Mobile Device
            manufacturer.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">3. Registration Forms.</span> In order to
            utilize certain Talk Dog, Inc.™ Offerings, including the Contact
            Services, you may be required to submit one or more registration
            forms (each, a “<span className="underlines">Form</span>”). The
            information that you must supply on a Form may include, without
            limitation: (a) your email address; (b) your first and last name;
            (c) account information; and/or (d) any other information requested
            by us on the applicable registration Form (collectively, “
            <span className="underline">Registration Data</span>”). You agree to
            provide true, accurate, current and complete Registration Data, as
            necessary, in order to maintain it in up to date and accurate
            fashion. Talk Dog, Inc.’s™ use of Registration Data shall be
            governed by the Privacy Policy. For a copy of the Privacy Policy,
            please{" "}
            <a href="#" className="c-primary underline">
              Click Here
            </a>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">4. Content.</span> The Apps and Venue
            contain Content which includes, but is not limited to, text, audio,
            video, photographs, graphics, artwork and other information about
            Talk Dog, Inc.™ and/or the Talk Dog, Inc.™ Offerings. The Content
            is compiled, distributed and displayed by Talk Dog, Inc.™, as well
            as third-party content providers, such as third-party sources and
            other Apps/Venue users (collectively, “
            <span className="underlines">Third-Party Providers</span>”). Talk
            Dog, Inc.™ does not control the Content provided by Third-Party
            Providers that is made available by and through the Talk Dog, Inc.™
            Offerings. Such Third-Party Providers are solely responsible for the
            accuracy, completeness, appropriateness and/or usefulness of such
            Content.
            <span className="fw-700">
              The Content should not necessarily be relied upon. Reliance on any
              Content or other information made available to you by and through
              the Talk Dog, Inc.™ Offerings is solely at your own risk. Talk
              Dog, Inc.™ does not represent or warrant that the Content and
              other information posted by and through the Talk Dog, Inc.™
              Offerings is accurate, complete, up-to-date or appropriate. You
              understand and agree that Talk Dog, Inc.™ will not be responsible
              for, and Talk Dog, Inc.™ undertakes no responsibility to monitor
              or otherwise police, Content provided by Third-Party Providers.
              You agree that Talk Dog, Inc.™ shall have no obligation and incur
              no liability to you in connection with any Content. You may find
              certain Content to be outdated, harmful, inaccurate and/or
              deceptive. Please use caution, common sense and safety when using
              the Content. The Content is offered for informational purposes
              only and is at all times subject to the disclaimers contained
              herein, in the Apps and on the Venue.
            </span>
          </p>
          <span className="mb-6 sm-mb-6 xs-mb-4 fw-700">5. The Apps.</span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (a) <i className="underline">Installation.</i> Talk Dog, Inc.™
            believes in providing Users with clear, concise and complete
            disclosure before Users download and install the Apps, including a
            description of the primary functions of the Apps. The Apps require
            User consent prior to installation. Talk Dog, Inc.™ does not
            believe that Users should be deceived into downloading or installing
            the Apps. In order to download the Apps, as made available on the
            iOS® and Android® mobile platforms, you must either utilize the
            options made available: (i) on the Venue; or (ii) via participating
            Download Venues.
            <span className="fw-700">
              You understand and agree that Talk Dog, Inc.™ shall not be liable
              to you, any other User or any third-party for any claim in
              connection with your use of, or inability to use, the Apps.
            </span>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (b) <i className="underline">Uninstall.</i> The Apps can be
            completely uninstalled in a straightforward manner and without
            requiring undue effort or skill, in most cases by using the
            traditional “add/remove” programs function contained within your
            Mobile Device. Please be advised that in some instances, you may be
            required to restart your Mobile Device before all remnants of the
            Apps are completely uninstalled and removed from your Mobile Device.
            If you experience any problems installing and/or uninstalling the
            Apps, please contact us via e-mail at: tech@talkdoginc.com
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 fw-700 uppercase">
            the app is not spyware or adware. the app will not deliver
            advertisements to your mobile device, provided that ads may appear
            in the app itself.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (c) <i className="underline">Functionality.</i> The Apps enable
            Users to utilize Interactive Services, post User Content and view
            all Content, including other User Content.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (d) <i className="underline">Download Venues.</i> The Agreement is
            entered into by and between you and Talk Dog, Inc.™, and not with
            the applicable Download Venue that you use to access the Apps. As
            between Talk Dog, Inc.™ and any participating Download Venue, Talk
            Dog, Inc.™ is solely responsible for the Apps. No Download Venue
            has any obligation to furnish any maintenance and/or support
            services with respect to the Apps. The Download Venues are
            third-party owned and operated websites/applications. Use of those
            stores shall be governed by the applicable venue’s agreements or
            terms and conditions. Talk Dog, Inc.™ does not control the Download
            Venues or any of the actions, policies or decisions made by the
            operators of those venues. If you accessed or downloaded the Apps
            from the Apple® Store, then you agree to use the Apps only: (i) on
            an Apple®-branded product or device that runs iOS (Apple’s®
            proprietary operating system software); and (ii) as permitted by the
            “Usage Rules” set forth in the Apple® Store Terms of Service, as
            applicable.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (e){" "}
            <i className="underline">Remote Access, Updates and Bug Fixes.</i>{" "}
            Talk Dog, Inc.™ reserves the right (but is not obligated) to add
            additional features or functions to the existing Apps, and to
            provide technical support including bug fixes, error corrections,
            patches, new releases or any other component not specified within
            these Terms, from time-to-time. When installed on a User’s Mobile
            Device, the Apps periodically communicates with Talk Dog, Inc.™
            servers. Talk Dog, Inc.™ may require the updating of the Apps
            residing on a User’s Mobile Device when Talk Dog, Inc.™ releases a
            new version of the Apps, or when Talk Dog, Inc.™ makes new features
            available. The aforementioned technical support and/or updates may
            occur automatically without prior notice, or upon prior notice to
            you, and may occur all at once or over multiple sessions, in Talk
            Dog, Inc.’s™ sole and absolute discretion. By downloading the Apps,
            you hereby consent to these update/technical support services. Our
            access to your Mobile Device will be limited to providing support
            and/or updating the Apps. Where we are denied access for these
            purposes, your ability to utilize Apps-based Talk Dog, Inc.™
            Offerings may be limited. Each User understands that we may require
            that User’s review and acceptance of our then-current Agreement
            before that User will be permitted to use any subsequent versions of
            the Apps. Each User acknowledges and agrees that Talk Dog, Inc.™
            has no obligation to make any subsequent versions of the Apps
            available to that User, or to provide bug fixes, error corrections,
            patches, new releases or any other component not specified within
            the Agreement.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (f) <i className="underline">Third-Party Software.</i>{" "}
            <span className="uppercase">
              any third-party software, as well as any third-party provided
              plug-ins, that may be provided with the app are made available for
              use at each user’s sole option and risk. if a user chooses to use
              such third-party software, then such use shall be governed by such
              third-party's licensing agreement(s), terms and conditions, and
              privacy practices. company is not responsible for any third-party
              software and shall have no liability whatsoever for any user’s use
              of, or inability to use, third-party software.
            </span>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (g) Apps License Grant/Termination. Subject to the restrictions set
            forth in the Agreement, we grant to you a personal, non-exclusive,
            limited, non-transferable, non-assignable, non-sublicensable,
            limited license to install and run the Apps and related Software, in
            object code format only, on Mobile Devices owned or controlled by
            you, solely for the purpose of accessing and using the Talk Dog,
            Inc.™ Offerings in accordance with the Agreement, and solely for so
            long as your Account is in good standing. You acknowledge that you
            are receiving licensed rights only. The licenses set forth in this
            Section 5 shall be in effect unless and until the licenses are
            terminated by Talk Dog, Inc.™. Talk Dog, Inc.™ may terminate the
            licenses set forth in this Section 5 and/or disable, remove or
            change the Apps and/or any portion thereof in its sole discretion at
            any time, with or without notice, by remote updates or otherwise. In
            addition, the license(s) will terminate immediately with respect to
            a User if that User fails to comply with any term or condition of
            the Agreement. Each User agrees upon expiration or termination of
            the license(s) to immediately un-install the Apps. You may not
            network the Apps and/or Software among multiple Mobile Devices. You
            may not directly or indirectly, or authorize any person or entity
            to: (i) reverse engineer, decompile, disassemble, re-engineer or
            otherwise create or attempt to create or permit, allow, or assist
            others to create the source code of the Apps, Software and/or their
            structural framework; (ii) create derivative works of the Apps
            and/or Software; (iii) use the Apps and/or Software, in whole or in
            part, for any purpose except as expressly provided herein; or (iv)
            disable or circumvent any access control or related device, process
            or procedure established with respect to the Apps and/or Software.
            You acknowledge that you have access to sufficient information such
            that you do not need to reverse engineer the Apps and/or Software in
            any way to permit other products or information to interoperate with
            the Apps and/or Software. You are responsible for all use of the
            Apps and/or Software that is under your possession or control.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (h) <i className="underline"> Export Restrictions.</i> Each User
            agrees that the Apps may not be transferred or exported into any
            other country or used in any manner prohibited by U.S. or other
            applicable export laws and/or regulations.
          </p>
          <span className="mb-6 sm-mb-6 xs-mb-4 fw-700">
            6. Interactive Services; User Content.
          </span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (a) <i className="underline">User Content.</i> In connection with
            the Interactive Services, users may be able to upload and/or post
            certain comments, content, material, communications, feedback and/or
            other information (collectively, the “User Content”). Each user
            represents and warrants that: (i) she/he owns and/or has any and all
            rights to publish, display, perform and permit the use of, and grant
            the license associated with, the User Content as contemplated by the
            Agreement; (ii) the User Content shall not infringe upon any third
            party’s intellectual property and/or proprietary rights; and (iii)
            the User Content shall not violate any Venue Policies and/or the
            policies of any Download Venues. Talk Dog, Inc.™ may reject and/or
            remove any User Content at any time and for any reason, in Talk Dog,
            Inc.’s™ sole discretion.
            <span className="fw-700">
              {" "}
              Notwithstanding the foregoing, Talk Dog, Inc.™ undertakes no
              responsibility to monitor or otherwise police the User Content
              made available by and through the Talk Dog, Inc.™ Offerings. Each
              user and third-party agrees that Talk Dog, Inc.™ shall: (A) have
              no obligations and incur no liabilities to such party in
              connection with any such User Content; and (B) not be liable to
              any party for any claim in connection with the User Content.
            </span>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (b){" "}
            <i className="underline">
              {" "}
              Grant of User Content License to Talk Dog, Inc.
            </i>{" "}
            By making the User Content available by and through the Interactive
            Services or otherwise through the Talk Dog, Inc.™ Offerings, you
            grant Talk Dog, Inc.™ a perpetual, irrevocable, worldwide right and
            license to reproduce, display, perform, distribute and otherwise use
            your User Content in connection with promoting Talk Dog, Inc.™, the
            Apps, the Venue and/or other products and/or services in any and all
            forms of marketing and promotional material including, without
            limitation, email marketing, print advertisements (“ads”), online
            ads, social media ads, television ads, radio ads, interactive media,
            as well as printed extracts and reproductions of any portion
            thereof. You understand and agree that the User Content may be
            edited and/or dramatized by Talk Dog, Inc.™. You agree that no ad
            or other material incorporating or making reference to the User
            Content need be submitted to you for approval and Talk Dog, Inc.™
            shall be without liability to you for any distortion or illusionary
            effect resulting from its publication of the User Content. Our
            license to your User Content is fully-paid and royalty free and, as
            such, we do not owe you any compensation in connection with our use
            of your User Content. You expressly release Talk Dog, Inc.™ from
            any and all claims that you have or may have for breach of right of
            publicity, invasion of privacy, defamation, copyright infringement
            or any other claim or cause of action arising out of or in
            connection with any production, distribution, duplication,
            broadcast, exhibition, publication, ad or promotion utilizing or
            incorporating your User Content, or any other use of such User
            Content whatsoever. You acknowledge and agree that Talk Dog, Inc.™
            shall not be liable for any causes of action or claims related to
            your decision to provide the User Content to Talk Dog, Inc.™.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            (c) <i className="underline">User Content Restrictions.</i> In
            connection with the User Content, each user agrees not to: (i)
            display any telephone numbers, street addresses, last names, URLs,
            e-mail addresses or any confidential information of any third-party;
            (ii) display any audio files, text, photographs, videos or other
            images that: (A) may be deemed indecent or obscene in the applicable
            user’s community, as defined under applicable law; and/or (B)
            contain hate speech and/or any other material that we reasonably
            believe degrades, intimidates, incites violence against or
            encourages prejudicial action against anyone based on age, gender,
            race, ethnicity, national origin, religion, sexual orientation,
            disability, geographic location or other protected category; (iii)
            impersonate any person or entity; (iv) “stalk,” “bully,” threaten,
            badger, sexually harass or otherwise harass any person; (v) engage
            in advertising to, or commercial solicitation of, end-users or other
            third parties; (vi) transmit any chain letters, spam or junk e-mail
            to any end-users or other third parties; (vii) express or imply that
            any statements she/he makes are endorsed by Talk Dog, Inc.™; (viii)
            harvest or collect personal information of end-users or other third
            parties whether or not for commercial purposes, without their
            express consent; (ix) use any robot, spider, site search/retrieval
            application, or other manual or automatic device or process to
            retrieve, index, “data mine,” or in any way reproduce or circumvent
            the navigational structure or presentation of the Talk Dog, Inc.™
            Offerings or related content; (x) post, distribute or reproduce in
            any way any copyrighted material, trademarks or other proprietary
            information without obtaining the prior consent of the owner of such
            proprietary rights; (xi) remove any copyright, trademark or other
            proprietary rights notices contained within the Talk Dog, Inc.™
            Offerings; (xii) interfere with or disrupt any of the Talk Dog,
            Inc.™ Offerings and/or the servers or networks connected to same;
            (xiii) post, offer for download, e-mail or otherwise transmit any
            material that contains software viruses or any other computer code,
            files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment; (xiv) post, offer for download,
            transmit, promote or otherwise make available any software, product
            or service that is illegal or that violates the rights of a
            third-party including, but not limited to, spyware, adware, programs
            designed to send unsolicited advertisements (i.e. “spamware”),
            services that send unsolicited advertisements, programs designed to
            initiate “denial of service” attacks, mail bomb programs and
            programs designed to gain unauthorized access to networks on the
            Internet; (xv) “frame” or “mirror” any part of the Apps and/or
            Venue; (xvi) use metatags or code or other devices containing any
            reference to the Talk Dog, Inc.™ Offerings in order to direct any
            person to any other website for any purpose; and/or (xvii) modify,
            adapt, sublicense, translate, sell, reverse engineer, decipher,
            decompile or otherwise disassemble any portion of the Talk Dog,
            Inc.™ Offerings or any software used on or in connection with same.
            Engaging in any of the aforementioned prohibited practices shall be
            deemed a breach of the Agreement and may result in the immediate
            termination of such user’s account and/or access to some or all of
            the Talk Dog, Inc.™ Offerings without notice, in the sole
            discretion of Talk Dog, Inc.™. Talk Dog, Inc.™ reserves the right
            to pursue any and all legal remedies against users that engage in
            the aforementioned prohibited conduct.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">7. Interaction.</span> Users are solely
            responsible for their interactions with other users and other
            third-parties featured by and through the Talk Dog, Inc.™
            Offerings. Because Talk Dog, Inc.™ is not involved in interactions
            between users and third-parties, in the event that you have a
            dispute with one or more users and/or other third-parties, you
            hereby release Talk Dog, Inc.™ from any and all claims, demands
            and/or damages (actual and consequential) of every kind and nature,
            known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of, or in any way connected with, such
            disputes.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">8. Representations and Warranties.</span>{" "}
            Each user hereby represents and warrants to Talk Dog, Inc.™ as
            follows: (a) the Agreement constitutes the legal, valid and binding
            obligation of user, which is fully enforceable against such user in
            accordance with its terms; (b) user understands and agrees that user
            has independently evaluated the desirability of utilizing the Talk
            Dog, Inc.™ Offerings and that user has not relied on any
            representation and/or warranty other than those set forth in the
            Agreement; and (c) the execution, delivery and performance of the
            Agreement by user will not conflict with or violate: (i) any
            applicable law; (ii) any order, judgment or decree applicable to
            user; and/or (iii) any agreement or other instrument applicable to
            user.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">9. Indemnification.</span> Each user agrees
            to indemnify, defend and hold Talk Dog, Inc.™, its officers,
            directors, employees, agents and attorneys, as well as the Covered
            Parties, harmless from and against any and all liabilities, claims,
            actions, suits, proceedings, judgments, fines, damages, costs,
            losses and/or expenses (including reasonable attorneys’ fees, court
            costs and/or settlement costs) arising from and/or related to: (a)
            any dispute between that user and any third party(ies); (b) that
            user’s breach of the Agreement and/or any representation or warranty
            contained herein; (c) that user’s User Content and/or (d) that
            user’s unauthorized and/or improper use of the Talk Dog, Inc.™
            Offerings. The provisions of this Section 9 are for the benefit of
            Talk Dog, Inc.™, its parent, subsidiaries and/or affiliates, and
            each of their respective officers, directors, members, employees,
            agents, shareholders, licensors, suppliers and/or attorneys. Each of
            these individuals and entities shall have the right to assert and
            enforce these provisions directly against users on its own behalf.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">10. License Grant.</span> Each user is
            granted a non-exclusive, non-transferable, revocable and limited
            license to access and use the Talk Dog, Inc.™ Offerings. Talk Dog,
            Inc.™ may terminate this license at any time for any reason. Unless
            otherwise expressly authorized by Talk Dog, Inc.™, users may only
            use the Talk Dog, Inc.™ Offerings for their own personal,
            non-commercial use. No part of the Talk Dog, Inc.™ Offerings may be
            reproduced in any form or incorporated into any information
            retrieval system, electronic or mechanical. No user or other third
            party may use any automated means or form of scraping or data
            extraction to access, query or otherwise collect material from the
            Talk Dog, Inc.™ Offerings except as expressly permitted by Talk
            Dog, Inc.™. No user or other third party may use, copy, emulate,
            clone, rent, lease, sell, modify, decompile, disassemble, reverse
            engineer or transfer the Talk Dog, Inc.™ Offerings, or any portion
            thereof. No user or other third party may create any “derivative
            works” by altering any aspect of the Talk Dog, Inc.™ Offerings. No
            user or other third party may use the Talk Dog, Inc.™ Offerings in
            conjunction with any other third-party content. No user or other
            third party may exploit any aspect of the Talk Dog, Inc.™ Offerings
            for any commercial purposes not expressly permitted by Talk Dog,
            Inc.™. Each user further agrees to indemnify and hold Talk Dog,
            Inc.™ harmless for that user’s failure to comply with this Section
            10. Talk Dog, Inc.™ reserves any rights not explicitly granted in
            the Agreement.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">11. Proprietary Rights.</span> The Talk
            Dog, Inc.™ Offerings, as well as the organization, graphics,
            design, compilation, magnetic translation, digital conversion,
            software, services and other matters related to same, are protected
            under applicable copyrights, trademarks and other proprietary
            (including, but not limited to, intellectual property) rights. The
            copying, redistribution or publication by any user or other third
            party of any part of the Talk Dog, Inc.™ Offerings is strictly
            prohibited. No user or other third party acquires ownership rights
            in or to any content, document, software, services or other
            materials viewed by or through the Talk Dog, Inc.™ Offerings. The
            posting of information or material by and through the Talk Dog,
            Inc.™ Offerings does not constitute a waiver of any right in or to
            such information and/or materials. The “Talk Dog, Inc.” and “Talk
            Dog, Inc.” names and logos, and all associated graphics, icons and
            service names, are trademarks of Talk Dog, Inc. The use of any
            trademark without the applicable trademark owner's express written
            consent is strictly prohibited.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">12. Legal Warning.</span> Any attempt by
            any individual to damage, destroy, tamper with, vandalize and/or
            otherwise interfere with the operation of the Talk Dog, Inc.™
            Offerings is a violation of criminal and civil law and Talk Dog,
            Inc.™ will diligently pursue any and all remedies against any
            offending individual or entity to the fullest extent permissible by
            law and in equity.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">13. Disclaimer of Warranties.</span>
            <span className="uppercase">
              {" "}
              the talk dog, inc.™ offerings and the other products and services
              offered by and through same are provided to users on an “as is”
              and “as available” basis and all warranties, express and implied,
              are disclaimed to the fullest extent permissible pursuant to
              applicable law (including, but not limited to, the disclaimer of
              any warranties of merchantability, non-infringement of
              intellectual property and fitness for a particular purpose). in
              particular, but not as a limitation thereof, talk dog, inc.™
              makes no warranty that the talk dog, inc.™ offerings and the
              other products and services offered by and/or through same: (a)
              will, as applicable, meet any user’s requirements; (b) will be
              uninterrupted, timely, secure or error-free or that defects will
              be corrected; (c) will be free of harmful components; (d) will
              result in any specific dog training benefit or other pet-related
              outcome; or (e) will be accurate or reliable. the talk dog, inc.™
              offerings and the other products and services offered by and
              through same may contain bugs, errors, problems or other
              limitations. talk dog, inc.™ will not be liable for the
              availability of the underlying internet connection associated with
              the talk dog, inc.™ offerings. no advice or information, whether
              oral or written, obtained by any user from talk dog, inc.™ or
              otherwise through or from the talk dog, inc.™ offerings shall
              create any warranty not expressly stated in the agreement.
            </span>
            <span className="fw-700">
              Without limiting the foregoing, we are not responsible for delays,
              disruptions, malfunctions or other technical interruptions that
              affect the Talk Dog, Inc.™ Offerings including, without
              limitation, problems with Mobile Devices, the Apps, the Venue,
              computer systems, telephone carriers, or Internet service
              providers, or the quality of coverage, strength of signal, delays
              or outages in service.
            </span>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">14. Limitation of Liability.</span>
            <span className="uppercase">
              {" "}
              each user expressly understands and agrees that talk dog, inc.™
              shall not be liable to that user or any third party for any
              direct, indirect, incidental, special, consequential and/or
              exemplary damages including, but not limited to, damages for loss
              of profits, goodwill, use, data or other intangible losses (even
              if talk dog, inc.™ has been advised of the possibility of such
              damages), to the fullest extent permissible by law for: (a) the
              use or inability to use the talk dog, inc.™ offerings and/or the
              other products and services offered by and/or through same; (b)
              the cost of procurement of substitute goods and services resulting
              from any goods, data, information, content and/or other products
              or services purchased or obtained from or through the talk dog,
              inc.™ offerings; (c) any dispute between any users and third
              parties; (d) the unauthorized access to, or alteration of, any
              registration data; (e) the failure to realize any specific dog
              training benefit or other pet-related outcome; and/or (f) any
              other matter relating to the talk dog, inc.™ offerings and/or the
              other products and services offered by and through same. this
              limitation applies to all causes of action, in the aggregate
              including, but not limited to, breach of contract, breach of
              warranty, negligence, strict liability, misrepresentation and any
              and all other torts. each user hereby releases talk dog, inc.™
              from any and all obligations, liabilities and claims in excess of
              the limitations stated herein. if applicable law does not permit
              such limitations, the maximum liability of talk dog, inc.™ to any
              user under any and all circumstances will be five hundred dollars
              ($500.00).
            </span>{" "}
            <span className="fw-700 uppercase">
              no action, regardless of form, arising out of the talk dog, inc.™
              offerings and the other products and services offered by and
              through same, may be brought by any user or talk dog, inc.™ more
              than one (1) year following the event which gave rise to the cause
              of action.
            </span>{" "}
            <span className="uppercase">
              the negation of damages set forth above is a fundamental element
              of the basis of the bargain between each user and talk dog,
              inc.™. access to the talk dog, inc.™ offerings would not be
              provided to users without such limitations. some jurisdictions do
              not allow certain limitations on liability and in such
              jurisdictions the liability of talk dog, inc.™ shall be limited
              to the maximum extent permitted by law.
            </span>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">15. Third Party Websites. </span> The Talk
            Dog, Inc.™ Offerings contain links to other websites on the
            Internet that are owned and operated by third parties including,
            without limitation, the Third-Party Links. Talk Dog, Inc.™ does not
            control the information, products or services made available on, by
            or through these third-party websites. The inclusion of any link
            does not imply endorsement by Talk Dog, Inc.™ of the applicable
            website or any association with the website’s operators. Because
            Talk Dog, Inc.™ has no control over such websites and/or resources,
            each user agrees that Talk Dog, Inc.™ is not responsible or liable
            for the availability or the operation of such external websites, for
            any material located on or available from or through any such
            websites or for the protection of any user’s data privacy by third
            parties. Each user further agrees that Talk Dog, Inc.™ shall not be
            responsible or liable, directly or indirectly, for any loss or
            damage caused by the use of or reliance on any such material
            available on, by or through any such site. the Agreement.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">
              16. Copyright Policy/DMCA Compliance.{" "}
            </span>{" "}
            Talk Dog, Inc.™ reserves the right to terminate the account of any
            user who infringes upon third-party copyrights. If any user or other
            third-party believes that a copyrighted work has been copied and/or
            posted via the Talk Dog, Inc.™ Offerings in a way that constitutes
            copyright infringement, that party should provide Talk Dog, Inc.™
            with the following information: (a) an electronic or physical
            signature of the person authorized to act on behalf of the owner of
            the copyrighted work; (b) an identification and location of the
            copyrighted work that such party claims has been infringed upon; (c)
            a written statement by such party that it has a good faith belief
            that the disputed use is not authorized by the owner, its agent or
            the law; (d) such party’s name and contact information, such as
            telephone number or e-mail address; and (e) a statement by such
            party that the above information in such party’s notice is accurate
            and, under penalty of perjury, that such party is the copyright
            owner or authorized to act on the copyright owner’s behalf. Contact
            information for Talk Dog, Inc.’s™ two (2) Copyright Agents for
            notice of claims of copyright infringement is as follows:
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            Klein Moynihan Turco LLP <br />
            Attn: Copyright Attorney <br />
            450 Seventh Avenue, 40th Floor <br />
            New York, NY 10123 <br />
            info@kleinmoynihan.comFax: (212) 216-9559
          </p>
          <span className="mb-6 sm-mb-6 xs-mb-4 ">Or</span>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            Talk Dog, Inc. <br />
            Attn: David Muriello <br />
            24 Newark Pompton Turnpike, Suite 206 <br />
            Little Falls, NJ 07424 <br />
            tech@talkdoginc.com <br />
            Fax: 862-239-6147
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">
              17. Editing, Deleting and Modification.{" "}
            </span>{" "}
            Talk Dog, Inc.™ reserves the right, in its sole discretion, to edit
            and/or delete any documents, information or Content appearing in the
            Apps and on the Venue.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">18. Use of Registration Data. </span> All
            material submitted by users through or in association with the Talk
            Dog, Inc.™ Offerings including, without limitation, the
            Registration Data, shall be subject to the Privacy Policy. For a
            copy of the Privacy Policy, please{" "}
            <a href="#" className="c-primary underline">
              Click Here
            </a>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">18. Dispute Resolution Provisions. </span>{" "}
            The Agreement shall be treated as though it were executed and
            performed in New York, NY and shall be governed by and construed in
            accordance with the laws of the State of New York (without regard to
            conflict of law principles).{" "}
            <span className="fw-700">
              The parties hereby agree to arbitrate all claims that may arise
              under the Agreement. Without limiting the foregoing, should a
              dispute arise between the parties (including the Covered Parties)
              including, without limitation, any matter concerning the Talk Dog,
              Inc.™ Offerings, the terms and conditions of the Agreement or the
              breach of same by any party hereto: (a) the parties agree to
              submit their dispute for resolution by arbitration before the
              American Arbitration Association (“AAA”) in New York, NY, in
              accordance with the then current Commercial Arbitration rules of
              the AAA; and (b) you agree to first commence a formal dispute
              proceeding by completing and submitting an Initial Dispute Notice
              which can be found{" "}
              <a href="#" className="c-primary underline">
                here.
              </a>{" "}
              We may choose to provide you with a final written settlement offer
              after receiving your Initial Dispute Notice (“Final Settlement
              Offer”). If we provide you with a Final Settlement Offer and you
              do not accept it, or we cannot otherwise satisfactorily resolve
              your dispute and you wish to proceed, you must submit your dispute
              for resolution by arbitration before the AAA, in your county of
              residence, by filing a separate Demand for Arbitration, which is
              available{" "}
              <a href="#" className="c-primary underline">
                here.{" "}
              </a>{" "}
              For claims of Ten Thousand Dollars ($10,000.00) or less, you can
              choose whether the arbitration proceeds in person, by telephone or
              based only on submissions. If the arbitrator awards you relief
              that is greater than our Final Settlement Offer, then we will pay
              all filing, administration and arbitrator fees associated with the
              arbitration and, if you retained an attorney to represent you in
              connection with the arbitration, we will reimburse any reasonable
              attorneys' fees that your attorney accrued for investigating,
              preparing and pursuing the claim in arbitration. Any award
              rendered shall be final and conclusive to the parties and a
              judgment thereon may be entered in any court of competent
              jurisdiction. Although we may have a right to an award of
              attorneys' fees and expenses if we prevail in arbitration, we will
              not seek such an award from you unless the arbitrator determines
              that your claim was frivolous.
            </span>
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 fw-700">
            To the extent permitted by law, you agree that you will not bring,
            join or participate in any class action lawsuit as to any claim,
            dispute or controversy that you may have against the Covered Parties
            and/or their respective employees, officers, directors, members,
            representatives and/or assigns. You agree to the entry of injunctive
            relief to stop such a lawsuit or to remove you as a participant in
            the suit. You agree to pay the attorney's fees and court costs that
            any Covered Party incurs in seeking such relief. This provision
            preventing you from bringing, joining or participating in class
            action lawsuits: (i) does not constitute a waiver of any of your
            rights or remedies to pursue a claim individually and not as a class
            action in binding arbitration as provided above; and (ii) is an
            independent agreement. You may opt-out of these dispute resolution
            provisions by providing written notice of your decision within
            thirty (30) days of the date that you first download the Apps and/or
            access the Venue, whichever occurs first.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">20. Notice to U.S. Government Users.</span>{" "}
            The Apps is a “commercial item” as that term is defined at 48 C.F.R.
            2.101, consisting of “commercial computer software” and “commercial
            computer software documentation” as such terms are used in 48 C.F.R.
            12.212. Consistent with 48 C.F.R. 12.212 and 48 C.F.R. 227.7202-1
            through 227.7202-4, all U.S. Government Users acquire the Apps with
            only those rights set forth therein.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">21. Miscellaneous.</span> To the extent
            that anything in or associated with the Talk Dog, Inc.™ Offerings
            is in conflict or inconsistent with the Agreement, the Agreement
            shall take precedence. Talk Dog, Inc.’s™ failure to enforce any
            provision of the Agreement shall not be deemed a waiver of such
            provision nor of the right to enforce such provision. The parties do
            not intend that any agency or partnership relationship be created
            through operation of the Agreement. Should any part of the Agreement
            be held invalid or unenforceable, that portion shall be construed
            consistent with applicable law and the remaining portions shall
            remain in full force and effect. Talk Dog, Inc.™ may assign its
            rights and obligations under the Agreement, in whole or in part, to
            any party at any time without notice to you. The Agreement may not,
            however, be assigned by you, and you may not delegate your duties
            under it. Headings are for reference purposes only and in no way
            define, limit, construe or describe the scope or extent of such
            section.
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4 ">
            <span className="fw-700">22. Contact Us</span> If you have any
            questions about the Agreement, Talk Dog, Inc.™ Offerings or the
            practices of Talk Dog, Inc.™, you may utilize the contact method
            applicable to the subject matter of your inquiry, as set forth in
            the Apps and/or on the “Contact Us” page of the Venue, or you can
            e-mail us at: tech@talkdoginc.com; call us at: 973-925-4377; or send
            us U.S. Mail to:If you have any questions about the Agreement, Talk
            Dog, Inc.™ Offerings or the practices of Talk Dog, Inc.™, you may
            utilize the contact method applicable to the subject matter of your
            inquiry, as set forth in the Apps and/or on the “Contact Us” page of
            the Venue, or you can e-mail us at: tech@talkdoginc.com; call us at:
            973-925-4377; or send us U.S. Mail to:
          </p>
          <p className="mb-6 sm-mb-6 xs-mb-4">
            Talk Dog, Inc. <br />
            Attn: David Muriello <br />
            24 Newark Pompton Turnpike, Suite 206 <br />
            Little Falls, NJ 07424 <br />
            tech@talkdoginc.com <br />
            Fax: 862-239-6147
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
