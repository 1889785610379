import React, {FC, SetStateAction, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Popup} from './AuthModal';
import {ClientMe} from '../types/user';
import {MixpanelEvent} from '../enums/mixpanel-event';
import {mixpanelTrack} from '../../utils/mixpanel-track';

interface FooterProps {
  setShowAuthPopup: React.Dispatch<SetStateAction<any>>;
  currentUser: ClientMe | null | undefined;
}

const Footer: FC<FooterProps> = ({
                                   currentUser,
                                   setShowAuthPopup
                                 }) => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const handleSubmit = (): void => {
    if (currentUser) return;
    setShowAuthPopup({authStep: Popup.Login, options: {email: email ?? ''}});
  };

  const handleChange = (event: any) => {
    setEmail(() => event.target.value);
  };

  const scrapBookHandler = (e: any) => {
    e.preventDefault();
    if (currentUser) {
      mixpanelTrack(MixpanelEvent.scrapbook_viewed);
      navigate('/scrap-book');
    } else {
      setShowAuthPopup({authStep: Popup.Login});
    }
  }

  return (
    <footer className="footer py-16 sm-py-14 px-6 bg-primary">
      <div className="container">
        <div className="footer-in col-gap-8 row-gap-12">
          <div className="footer-logo xs-mx-auto">
            <Link to="/" className="logo">
              <img className="logo" src="/images/logo-invert.png" alt="" />
            </Link>
          </div>
          <div className="footer-menu w-100 flex justify-start align-start sm-flex-col sm-align-center sm-w-auto xs-align-start col-gap-8 row-gap-12">
            <div className="w-100 flex flex-col justify-start align-start row-gap-3 sm-align-center xs-align-start">
              <span className="block fs-h-7 lh-h-7 fw-700 text-uppercase c-text-black">
                Game
              </span>
              <Link
                to="/game"
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                Play Today’s Game
              </Link>
              <Link
                to="/daily-reminders"
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                Reminder Emails
              </Link>
              <Link
                to="/submit-dog-step-1"
                state={{ showAuthPopup: true }}
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                Submit My Dog
              </Link>
              <span onClick={scrapBookHandler} className="cursor-pointer block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white">
                Your Scrapbook
              </span>
            </div>
            <div className="w-100 flex flex-col justify-start align-start row-gap-3 sm-align-center xs-align-start">
              <span className="block fs-h-7 lh-h-7 fw-700 text-uppercase c-text-black">
                Topics
              </span>
              <Link
                to="/topics"
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                All Topics
              </Link>
              <Link
                to="/community-guidelines"
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                Community Guidelines
              </Link>
              <Link
                to="/how-to-play"
                state={{ showAuthPopup: true }}
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                How to Play
              </Link>
            </div>
            <div className="w-100 flex flex-col justify-start align-start row-gap-3 sm-align-center xs-align-start">
              <span className="block fs-h-7 lh-h-7 fw-700 text-uppercase c-text-black">
                About
              </span>
              <Link
                to="about-us"
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                About Us
              </Link>
              <Link to="/feedback" className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white">
                Give Feedback
              </Link>
              <Link
                to="/privacy-policy"
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-and-conditions"
                className="block fs-lg lh-lg xs-fs-sm xs-lh-sm fw-500 c-text-white"
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
          <div className="footer-form flex flex-col justify-start align-start sm-align-center xs-align-start grow-1">
            {!currentUser && <>
                <p className="fs-lg lh-lg xs-fs-sm xs-lh-sm c-text-white mb-4">
              <span className="fs-h-7 lh-h-7 xs-fs-h-3 xs-lh-h-3 c-text-black fw-700 text-uppercase">
                Sign up
              </span>{' '}
                    <br className="hidden xs-block"/>
                    <span className="fw-700 xs-fw-400">and never miss out</span>
                    <br className="xs-hidden"/> on saving your points and cards!
                </p>
                <div className="flex justify-start align-start xs-flex-col w-100 mb-4">
                    <div className="input-wrap input-wrap--sm input-wrap--light grow-1 mr-2 xs-mr-0 xs-w-100 xs-mb-4">
                <input onChange={handleChange} type="text" className="input" placeholder="Email" />
              </div>
              <button className="btn btn--sm btn--dark xs-w-100" type='button' onClick={handleSubmit}>Submit</button>
            </div></>}
            <div className="flex justify-start align-center col-gap-4 mt-auto">
              <span className="block fs-md lh-md fw-700 c-text-1">
                Follow us:
              </span>
              <div className="flex justify-start align-center col-gap-4">
                <a
                  href="https://www.tiktok.com/@flippedondogs"
                  target="_blank"
                  className="btn-reset c-text-white"
                >
                  <span className="flipicon flipicon-tiktok"></span>
                </a>
                <a
                  href="https://www.instagram.com/flippedondogs"
                  target="_blank"
                  className="btn-reset c-text-white"
                >
                  <span className="flipicon flipicon-instagram"></span>
                </a>
                <a
                  href="https://www.facebook.com/FlippedOnDogs"
                  target="_blank"
                  className="btn-reset c-text-white"
                >
                  <span className="flipicon flipicon-facebook"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
