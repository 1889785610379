import React, {FC, SetStateAction, useContext, useEffect, useMemo} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import HeaderAuth from './HeaderAuth';
import AuthModal, {ModalData, Popup} from '../AuthModal';
import {ClientMe} from '../../types/user';
import {UserPupStarCards} from '../../types/albums';
import AppContext from '../../context/app-context';
import {MixpanelEvent} from '../../enums/mixpanel-event';
import {mixpanelTrack} from '../../../utils/mixpanel-track';

interface HeaderProps {
  setSidebarState: React.Dispatch<SetStateAction<any>>;
  showAuthPopup: ModalData | null;
  setShowAuthPopup: React.Dispatch<SetStateAction<any>>;
  currentUser: ClientMe | null | undefined;
  isGameMode: boolean;
}

const Header: FC<HeaderProps> = ({
  setSidebarState,
  showAuthPopup,
  setShowAuthPopup,
  currentUser,
  isGameMode,
}) => {
  const navigate = useNavigate();
  const {albums: albumsResponse} = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const badgeCount = useMemo(() => {
    if (!albumsResponse?.userPupStarCards?.length) return 0;
    const albums = albumsResponse.userPupStarCards.filter((c) => !c.seen);
    if (!albums.length) return 0;
    const items = new Map<number, UserPupStarCards>();
    albums.forEach((a) => items.set(a.pupStarCardId, a));
    return items.size;
  }, [albumsResponse]);

  useEffect(() => {
    if (searchParams.has("approved")) {
      setShowAuthPopup({ authStep: Popup.Success });
      searchParams.delete("approved");
      setSearchParams(() => searchParams);
    }
  }, [searchParams, setShowAuthPopup, setSearchParams]);

  const menuClickHandler = (): void => {
    setSidebarState((prevState: boolean) => !prevState);
  };

  function closeModal(): void {
    setShowAuthPopup(false);
  }

  const scrapbookHandler = (): void => {
    if (!currentUser) {
      setShowAuthPopup({ authStep: Popup.Login });
    } else {
      mixpanelTrack(MixpanelEvent.scrapbook_viewed);
      navigate("/scrap-book");
    }
  };

  return (
    <header className="header px-6">
      <div className="header-in header-container  col-gap-10 justify-center align-center sm-flex sm-col-gap-3 xs-col-gap-2">
        <Link to="/" className="logo sm-mr-auto">
          <img className="logo xs-hidden" src="/images/logo.png" alt="" />
          <img
            className="logo hidden xs-flex"
            src="/images/logo-mobile.png"
            alt=""
          />
        </Link>
        <div className="flex justify-center align-center col-gap-5 sm-col-gap-2">
          <div className="flex flex-col justify-start align-stretch">
            <button
              className="btn-reset c-primary text-left"
              onClick={() => window.location.pathname !== "/game" && navigate("/game")}
            >
              <span className="flipicon flipicon--xl c-primary flipicon-game sm-flipicon--md  xs-hidden mr-1"></span>
              <span className="block text-uppercase fs-md lh-md sm-fs-xs sm-lh-xs fw-700 xs-hidden ">
                Play
                <br />
                today's game
              </span>
            </button>
            {/* {!!currentUser && <ScrapBookProgress/>}*/}
          </div>
          <div className="flex flex-col justify-start align-stretch relative">
            <button
              className="btn-reset c-primary text-left"
              onClick={scrapbookHandler}
            >
              <span className="flipicon flipicon--xl flipicon-scrapbook sm-flipicon--md scrapbook-icon  mr-1"></span>
              <span className="block text-uppercase fs-md lh-md sm-fs-xs sm-lh-xs fw-700 xs-hidden ">
                Your
                <br />
                Scrapbook
              </span>
            </button>
            {/* {!!currentUser && <ScrapBookProgress/>}*/}
            {!!badgeCount && (
              <div className="scrapbook-badge flex justify-center align-center text-cneter">
                <span className="c-text-white fs-md lh-xs sm-fs-xs sm-lh-xs xs-fs-xs xs-lh-xs fw-700">
                  {badgeCount}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-start align-stretch">
            <Link className="btn-reset c-primary text-left" to="/topics">
              <span className="flipicon flipicon--xl c-primary flipicon-topics sm-flipicon--md xs-hidden mr-1"></span>
              <span className="block text-uppercase fs-md lh-md sm-fs-xs sm-lh-xs fw-700 xs-hidden ">
                explore
                <br />
                topics
              </span>
            </Link>
            {/* {!!currentUser && <ScrapBookProgress/>}*/}
          </div>
        </div>
        <div className="flex justify-end align-center col-gap-5 sm-col-gap-2 sm-ml-auto xs-col-gap-3 header-left">
          {currentUser ? (
            <HeaderAuth />
          ) : (
            !isGameMode && (
              <>
                <button
                  className="btn btn--text btn--text-lg btn--auto sm-btn--sm px-0 xs-fs-xxs"
                  onClick={() =>
                    setShowAuthPopup(() => ({ authStep: Popup.Login }))
                  }
                >
                  Log in
                </button>
                <button
                  className="btn btn--primary sm-btn--sm sm-btn--auto xs-btn--sm sm-px-2 xs-px-3"
                  onClick={() => {
                    setShowAuthPopup(() => ({authStep: Popup.Signup}));
                  }}
                >
                  Sign Up
                </button>
              </>
            )
          )}
          {(showAuthPopup?.authStep)?<AuthModal
            isOpen={!!showAuthPopup}
            handleClose={closeModal}
            step={showAuthPopup?.authStep as Popup}
            onAfterImageUpload={closeModal}
          />: <></>
          }

          <button
            className="btn-reset c-primary ml-4 sm-ml-2"
            onClick={menuClickHandler}
          >
            <i className="flipicon flipicon--lg sm-flipicon--md flipicon-menu"></i>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
