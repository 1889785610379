export enum MixpanelEvent {
  account_created = 'account_created',
  register_for_account = 'register_for_account',
  login_to_account = 'login_to_account',
  round_start = 'round_start',
  question_answered_correctly = 'question_answered_correctly',
  question_answered_incorrectly = 'question_answered_incorrectly',
  game_completed = 'game_completed',
  album_completed = 'album_completed',
  pup_stars_earned = 'pup_stars_earned',
  personal_pick_claimed = 'personal_pick_claimed',
  scrapbook_viewed = 'scrapbook_viewed',
  scrapbook_completed = 'scrapbook_completed',
  card_created = 'card_created',
  daily_reward_popup_shown = 'daily_reward_popup_shown',
  card_shared = 'card_shared',
  game_shared = 'game_shared',
  login_opened = 'login_opened',
  signup_opened = 'signup_opened',
  account_logged_in = 'account_logged_in',
}
