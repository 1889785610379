import moment from 'moment/moment';
import {isArray, isDate, isNumber, isObject} from 'lodash';
import states from '../api/states.json';
import * as Sentry from '@sentry/react';
import {GUEST_TOKEN_KEY, guestUserTransferApi} from '../api/apiAuth';

export const logException = (err: any) => {
  console.error(err);
  Sentry.captureException(err);
};

export const convertToFormData = (jsonData: any): FormData => {
  const fd = new FormData();

  Object.keys(jsonData).forEach((key) => {
    const currentValue = jsonData[key];

    if (currentValue instanceof Blob) {
      fd.append(key, currentValue, (<File>currentValue).name);
    } else if (isNumber(currentValue)) {
      fd.append(key, jsonData[key].toString());
    } else if (isDate(currentValue)) {
      fd.append(key, moment(currentValue).format("MM/DD/YYY"));
    } else if (isArray(currentValue) || isObject(currentValue)) {
      fd.append(key, JSON.stringify(currentValue));
    } else {
      fd.append(key, currentValue ?? "");
    }
  });

  return fd;
};

export const getAllStates = (): IState[] => states;

export const addQueryParams = (rootUrl: string, params: any[]) => {
  let queryParams = "";
  params.forEach((p, i) => {
    queryParams =
      i === 0
        ? `${queryParams}?${p.key}=${p.value}`
        : `${queryParams}&${p.key}=${p.value}`;
  });

  return `${rootUrl}${queryParams}`;
};

export const guestUserTransfer = async (firstName?: string, lastName?: string) => {
  if (localStorage.getItem(GUEST_TOKEN_KEY)) {
    const status = await guestUserTransferApi(firstName, lastName);
    if (status) {
      localStorage.getItem(GUEST_TOKEN_KEY);
    }
  }
  return Promise.resolve();
}


export const dataURLtoFile = (
  dataURL: string,
  filename: string,
  type: string,
) => {
  return fetch(dataURL)
    .then(
      (response) => response.blob()
    )
    .then(
      (blob) => new File([blob], filename || 'file_from_dataurl', {type})
    )
}
