import React, {useEffect, useState} from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import {useTrackGoogleAnalyticsEventPages} from "../../common/hooks/useTrackGoogleAnalyticsEvent";

interface Props {}

const ForgotPasswordPage: React.FC<Props> = () => {
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();

  const handleChange = (val: string): void => {
    setEmail(val);
  };

  const handleSubmit = (): void => {
    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => setIsOpen(true))
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    useTrackGoogleAnalyticsEventPages('/forgot-password','forgot password page')
  }, []);

  return (
    <>
      <div className="container xs-container--sm">
        <div className="flex flex-col w-100 xs-px-6 sm-px-5 text-start ">
          <h2 className="fs-h-3 lh-h-3 sm-fs-h-5 sm-lh-h-5 xs-fs-h-7 xs-lh-h-7 c-text-4 mb-12">
            Forgot Password
          </h2>
          <div className="auth-wrapper flex- flex-col py-6 px-6 xs-py-4 xs-px-4 br border bg-white ">
            <span className="fs-lg lh-lg sm-fs-md sm-lh-md xs-sm xs-sm c-text-4 fw-500 mb-6">
              Please enter the email address you signed up with in order to
              receive a password reset link.
            </span>
            <div className="input-wrap input-wrap--md xs-input-wrap mt-6 mb-6">
              <input
                type="text"
                className="input"
                id="email"
                placeholder="1"
                onChange={(ev) => handleChange(ev.target.value)}
              />
              <label className="input-label" htmlFor="email">
                Email
              </label>
            </div>
            <div className="flex justify-end col-gap-4 xs-flex-col-reverse xs-row-gap-4">
              <Link
                to="/"
                color="transparent"
                className="btn xs-btn btn--secondary xs-btn--auto"
              >
                cancel
              </Link>
              <button
                disabled={isLoading || !email}
                className="btn xs-btn btn--primary"
                onClick={handleSubmit}
              >
                submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        className="popup xs-popup xs-popup--full"
      >
        <div className="popup-card popup-card--primary justify-center xs-justify-start">
          <button className="btn-reset c-text-1 ml-auto" onClick={handleClose}>
            <i className="flipicon flipicon-close"></i>
          </button>
          <div className="flex flex-col justify-center align-center mt-auto mb-auto">
            <div className="flex flipicon flipicon--popup justify-center align-center text-center br-50 border mb-6">
              <i className="flipicon flipicon--xxl flipicon-check c-primary"></i>
            </div>
            <div className="flex flex-col justify-center text-center align-centers">
              <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
                If you have an existing account you will be emailed a reset
                link.
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ForgotPasswordPage;
