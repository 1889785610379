import { FC } from "react";

interface CheckEmailProps {
  handleClose: () => void;
}

const CheckEmail: FC<CheckEmailProps> = ({ handleClose }) => {
  return (
    <>
      <button className="btn-reset c-text-1 ml-auto" onClick={handleClose}>
        <i className="flipicon flipicon-close"></i>
      </button>
      <div className="flex flex-col justify-center align-center mt-auto mb-auto">
        <div className="flex flipicon flipicon--popup justify-center align-center text-center br-50 border mb-6">
          <i className="flipicon flipicon--xxl flipicon-check c-primary"></i>
        </div>
        <div className="flex flex-col justify-center text-center align-centers">
          <span className="fs-h-7 lh-h-7 fw-700 c-primary mb-2">
            You're Almost There!
          </span>
          <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
            Please check your email for a link to complete your sign up.
          </span>
        </div>
      </div>
    </>
  );
};

export default CheckEmail;
