import React, {useEffect} from "react";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const CommunityGuidelines = () => {
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/community-guidelines', 'community guidelines page')

  }, []);
  return (
    <div className="container xs-container--sm">
      <div className="flex flex-col w-100 justify-start align-start xs-px-6 sm-px-6 mb-7">
        <h2 className="fs-h-3 lh-h-3 fw-700 c-text-black xs-fs-h-6 xs-lh-h-6 mb-12 xs-mb-4">
          Community Guidelines
        </h2>
        <div className="flex flex-col w-100 justify-start align-start">
          <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-12 xs-mb-4">
            These rules are in place to create a great experience for all
            members of our community.
          </p>
          <ul>
            <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-6">
                <span className="fw-700">
                  Be Respectful and Kind to People.
                </span>{" "}
              It’s okay to discuss/debate, but be thoughtful and caring.
              Criticize ideas, not people. Do not use rude, offensive or
              hurtful language. Trolling, baiting, or the like will not be
              accepted. If you see bad behavior, flag the comment.
            </li>
            <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-6">
                <span className="fw-700">
                  Be Humane, Respectful, and Kind to Animals.
                </span>{" "}
              Don’t promote any method, product, or action that would cause
              pain or fear in animals.
            </li>
            <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-6">
                <span className="fw-700">
                  Don’t Market Your Business or Agenda.
                </span>{" "}
              We are enthusiastic about your success, but the comments section
              is not the place to promote your stuff. Do not post any kind of
              advertising, marketing, promotion, or solicitation referencing
              any other business or organization.
            </li>
            <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm">
              <span className="fw-700">Stay on Topic.</span> Comment about the
              topics being presented in the posts and build on them. Outside
              topics are not appropriate. We are focused on sharing and
              learning about the awesome world of dogs.
            </li>
          </ul>
          <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mt-12 xs-mt-4">
            We have enjoyed the fantastic culture and benefits of our
            dog-loving communities for years. Administrators are serious about
            all of the above. We reserve the right to delete content and
            remove a user from the platform without warning. Please be
            positive and enjoy our community.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommunityGuidelines;
