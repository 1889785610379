import React, {useContext, useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Header from '../common/components/header/Header';
import Sidebar from '../common/components/Sidebar';
import Footer from '../common/components/Footer';
import Breadcrumbs from '../common/components/Breadcrumbs';
import useQueryParams from '../common/hooks/useQueryParams';
import AppContext from '../common/context/app-context';
import {Popup} from '../common/components/AuthModal';
import {useFirebaseLogin} from '../common/hooks/useFirebaseLogin';

const BlogRoot: React.FC = () => {

  useFirebaseLogin();

  const {pathname} = useLocation();
  const {
    setShowAuthPopup,
    isGameMode,
    showAuthPopup,
    setSidebarState,
    sidebarState,
    me: currentUser,
    breadcrumbTitle
  } = useContext(AppContext);

  const navigate = useNavigate();
  const {showMode, redirecturl, login} = useQueryParams();

  useEffect(() => {
    if (!pathname) return;
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    document.documentElement.style.setProperty('--screen-h', `${(window.innerHeight ?? 0)}px`);
  }, []);


  useEffect(() => {
    const timeout = setTimeout(() => {
      if (login && !currentUser) {
        navigate('/');
        setShowAuthPopup({authStep: Popup.Login, options: {redirectUrl: redirecturl}});
      } else if (currentUser && login) {
        navigate('/');
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [login, currentUser, navigate, setShowAuthPopup]);


  return (
    <>
      {showMode ? (
        <div className="wrap">
          <Outlet/>
        </div>
      ) : (
        <>
          <Header
            setSidebarState={setSidebarState}
            showAuthPopup={showAuthPopup}
            setShowAuthPopup={setShowAuthPopup}
            currentUser={currentUser}
            isGameMode={isGameMode}
          />
          <Sidebar
            sidebarState={sidebarState}
            currentUser={currentUser}
            setSidebarState={setSidebarState}
            showAuthPopup={showAuthPopup}
            setShowAuthPopup={setShowAuthPopup}
            isGameMode={isGameMode}
          />
          <div className="wrap">
            <Breadcrumbs
              breadcrumbTitle={breadcrumbTitle}
            />
            <Outlet/>
          </div>
          <Footer
            currentUser={currentUser}
            setShowAuthPopup={setShowAuthPopup}
          />
        </>
      )}
    </>
  );
};

export default BlogRoot;
