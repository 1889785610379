import {useMutation, useQuery} from 'react-query';
import {getUserPersonalCard, userPersonalCardCreateUpdateApi} from '../../api/userPersonalCard';

export const useCreateUpdateUserPersonalCard = () => {
    const {
        mutate: userPersonalCardCreateUpdate,
        isLoading: userPersonalCardCreateUpdateLoading,
    } = useMutation('userPersonalCardCreateUpdate', userPersonalCardCreateUpdateApi);

    return {
        userPersonalCardCreateUpdate,
        userPersonalCardCreateUpdateLoading,
    };
};

export const usePersonalPickCard = (id: number | undefined) => {
    return useQuery('personal-pick', () => getUserPersonalCard(id as number), {enabled: !!id});
};
