import React, {FC, useContext, useEffect, useState} from 'react';
import {Link, useLocation, useMatches, useNavigate, useParams} from 'react-router-dom';
import {routeNames} from '../constants/route-names';
import AppContext from "../context/app-context";

interface Breadcrumbs {
  name: string;
  link: string;
  hide: boolean;
  chaneRoute: string;
}

interface BreadcrumbsProps {
  breadcrumbTitle: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({breadcrumbTitle}) => {
  const location = useLocation();
  const matches = useMatches();
  const navigate = useNavigate();
  const params = useParams();
  const [hide, setHide] = useState<boolean>(false);
  const [breadcrumbsState, setBreadcrumbsState] = useState<Breadcrumbs[]>([]);
  const {subTopicsId} = useContext(AppContext);

  const generateBreadcrumbs = (matches: any, title: string): Breadcrumbs[] => {
    const uniquePathNames = new Set();

    const deduplicatedArray = matches.filter((obj: any) => {
      if (!uniquePathNames.has(obj.pathname)) {
        uniquePathNames.add(obj.pathname);
        return true;
      }
      return false;
    });

    let paramId = null;
    let paramSlug = null;
    const res = deduplicatedArray.map((m: any) => {
      const route = routeNames.find((rn) => m.pathname.includes(rn.route));
      paramId =  m.params?.id;
      paramSlug = m.params?.slug;
      return {
        name: route?.name || "Page",
        link: (m.pathname as string).replace(`/${paramId ?? paramSlug}`, ""),
        hide: !!route?.hide,
        chaneRoute: route?.chaneRoute
      };
    });

    if ((paramSlug || paramId) && title) {
      res.push({
        name: title,
        link: location.pathname === `/article/${params.id}` ? `/topics/${subTopicsId}` : '#' ,
        hide: false
      });
    }
    return res;
  };

  useEffect(() => {
    const breadcrumbs = generateBreadcrumbs(matches, breadcrumbTitle);
    setBreadcrumbsState(breadcrumbs);
    setHide(!!breadcrumbs.find(r => r.hide));
  }, [matches, breadcrumbTitle, setBreadcrumbsState, setHide]);

  const backButtonClickHandler = () => {
    navigate(-1);
  };

  return (
    <>
      {!hide ? (
        <>
      {location.pathname !== "/" && (
        <div className="container">
          <div className="breadcrumbs pt-6 pb-12 sm-px-6 xs-px-6 xs-py-4">

                <button
                    className="btn-reset breadcrumbs-btn breadcrumbs-btn--return px-0"
                    onClick={backButtonClickHandler}
                >
                  <span className="flipicon flipicon-left"></span>
                </button>
            {breadcrumbsState?.map((b: Breadcrumbs, i: number) => (
                <Link
                    to={b.chaneRoute ?? b.link}
                    className={`btn-reset breadcrumbs-btn 
                    ${(breadcrumbsState.length - 1 === i && location.pathname === `/article/${params.id}`) && "subtopics-last-name"}
                    ${(b.link === location.pathname || b.link === "#") && "breadcrumbs-btn--active"}`
                }
                    key={b.name + i}
                >
                  {b.name}
                </Link>
            ))}
          </div>
        </div>
      )}
        </>
      ) : (
          <div style={{ marginBottom: 0 }}></div>
      )}
    </>
  );
};

export default Breadcrumbs;
