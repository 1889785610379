const FacebookDataDeletion = () => {

  return (
    <div className="container xs-container--sm">
      <div className="pl-6 pr-6">
        <h2 className="fs-h-3 lh-h-3 fw-700 c-text-black xs-fs-h-6 xs-lh-h-6 mb-6 mt-8 xs-mb-4 facebook-title">
          Facebook Data Deletion Instructions URL
        </h2>
        <p className="fs-lg lh-xl fw-700 c-text-4 xs-fs-sm xs-lh-sm mb-4">Last Updated: April 10, 2024</p>
        <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4">
          Talk Dog uses Facebook login to authenticate our users. We do not store data you shared with Facebook on our servers.
        </p>
        <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4">
          According to the Facebook Platform rules, we have to provide a User Data Deletion Callback URL or a Data Deletion Instructions URL.
        </p>
        <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm">
          If you want to delete your activities for Talk Dog, you can remove your activities by the following instructions:
        </p>

        <ol className="ml-5 mb-6">
          <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm">
            Go to your Facebook Account's Settings & Privacy. Click "Settings".
          </li>
          <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm">
            Look for Apps and Websites and you will see all of the apps and websites you linked with your Facebook.
          </li>
          <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm">
            Scroll and click Remove button at right against Talk Dog App.
          </li>
          <li className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm">
            Congratulations, you have successfully removed your app activities on Facebook
          </li>
        </ol>

        <p className="fs-lg lh-xl fw-500 c-text-4 xs-fs-sm xs-lh-sm mb-4">
          To delete all of your data on the Talk Dog website, you may do so by e-mailing us at tech@talkdoginc.com.
        </p>
      </div>
    </div>
  )
}


export default FacebookDataDeletion;