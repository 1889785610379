import { MutableRefObject, useContext, useEffect } from "react";
import AppContext from "../context/app-context";

export const useSidebarOutsideClick = (
  ref: MutableRefObject<HTMLElement | null>,
) => {
  const { sidebarState, setSidebarState } = useContext(AppContext);
  useEffect(() => {
    if (!sidebarState) {
      return;
    }
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSidebarState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, sidebarState]);
};
